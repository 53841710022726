<template>
  <div v-if="!isReadonly" v-circular-tab class="asset-drawer__form">
    <UIInputDynamicList
      v-model="contactValue"
      data-refid="assetContactsFormName"
      :disabled="isLoading"
      :data="listValues"
      placeholder="Select or create contact to assign"
      size="sm"
      class="asset-drawer__form-input"
      @click:item="handleSelect"
      @click:new="handleCreate"
    >
      <template #item="{ item, onClick }">
        <div class="ui-dropdown-list-item" @click="onClick">
          Assign: {{ item.label }}
        </div>
      </template>
    </UIInputDynamicList>
  </div>
  <UIGridSkeleton
    v-if="isLoading"
    v-bind="{ columns, sm }"
    message="Loading contacts..."
  />
  <UIGrid
    v-else
    v-model:sort="sort"
    data-refid="assetContactsList"
    v-bind="{ columns, items, sm }"
  >
    <template v-if="!isReadonly" #actions="{ item, size }">
      <UIButton
        v-if="item"
        label="Edit"
        :leading="PencilIcon"
        variant="secondary"
        fill="light"
        :size
        @click="handleClickEdit(item)"
      />
      <UIButton
        v-if="item"
        label="Unlink"
        :leading="TrashIcon"
        variant="danger"
        fill="light"
        :size
        @click="handleClickUnlink(item)"
      />
    </template>
    <template #cellName="{ displayValue, item }">
      <div class="asset-drawer-contacts__item">
        <div class="asset-drawer-contacts__item-name">{{ displayValue }}</div>
        <div v-if="item.email || item.phone">
          {{ [item.email, item.phone].filter(Boolean).join(', ') }}
        </div>
      </div>
    </template>
  </UIGrid>
  <UIRemoveDialog
    v-model="removeDialog"
    v-bind="{
      title: removeDialogTitle,
      message: 'Are you sure you want to unlink this contact?',
      label: 'Unlink',
    }"
    @remove="handleRemove"
  />
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue'
import { orderBy } from 'lodash'

import { AssetClass } from '..'
import { ModalEvent, ReadonlyMode, Sort } from '@types'
import { Contact } from '@/entities/contacts/utils/types'
import { EntityEvent } from '@/entities/utils/enums'
import { ContactClass } from '@/entities/contacts'

import { CONTACT_FIELD } from '@/entities/contacts/utils/const'
import { ASSET_FIELD } from '../utils/const'
import { READONLY_MODE } from '@/const/common'

import useGridSort from '@/components/hooks/gridSort'

import { useContactsBunchStore } from '@/store/contacts/bunch'
import { useContactsStore } from '@/store/contacts'
import { useModalsStore } from '@/store/modals'

import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui/buttons'
import { UIGrid, UIGridSkeleton } from '@ui/grid'
import { UIRemoveDialog } from '@ui/modals'
import { UIInputDynamicList } from '@ui/fields'

type Props = {
  instance: AssetClass
}

const props = defineProps<Props>()

defineOptions({ inheritAttrs: false })

const contactsBunchStore = useContactsBunchStore()
const contactsStore = useContactsStore()
const modalsStore = useModalsStore()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const contactValue = ref<string>()

const sort = ref<Sort[]>()

const removeDialog = ref(false)
const loading = ref(false)

const removedContact = ref<Contact>()

const contactsField = props.instance.field<string[]>(ASSET_FIELD.CONTACTS)

const isLoading = computed(
  () => loading.value || !contactsStore.initFlag || contactsStore.loadingAction,
)

const contactsList = computed(() => contactsStore.getList)

const removeDialogTitle = computed(
  () => `Unlink asset contact '${removedContact.value?.name}'`,
)

const sm = '1fr'
const columns = computed(() => [
  {
    name: 'name',
    caption: 'Contact',
    default: '2fr',
    hideCaptionIfCollapsed: true,
  },
  {
    name: 'notes',
    caption: 'Notes',
    unsortable: true,
    hideCaptionIfCollapsed: true,
  },
])

const inputItems = computed(() =>
  contactsList.value.filter(contact =>
    contactsField.value.includes(contact.id),
  ),
)
const items = useGridSort(sort, columns, inputItems)

const listValues = computed(() => {
  const result = contactsList.value.filter(
    contact => !contactsField.value.includes(contact.id),
  )
  return orderBy(
    result.map(item => ({ value: item.id, label: item.name })),
    'label',
  )
})

const handleClickEdit = (value: Contact) => {
  const instance = contactsBunchStore.getElementById(value.id)
  if (!instance) return
  instance.isDialog = true
  const removeDialogFlag = () => {
    instance.isDialog = false
  }
  instance?.addEventListener(EntityEvent.STORED, removeDialogFlag)
  const modalInstance = modalsStore.init(instance.id, instance)
  modalInstance?.open(modalsStore.getZIndex())
  modalInstance?.addEventListener(ModalEvent.CLOSE, removeDialogFlag)
}

const handleClickUnlink = (item: Contact) => {
  removedContact.value = item
  removeDialog.value = true
}

const updateAssetContacts = async (contact: ContactClass, value: string[]) => {
  loading.value = true
  props.instance.field<string[]>(ASSET_FIELD.CONTACTS).value.push(contact.id)
  await props.instance.update()
  contact?.forceUpdate(CONTACT_FIELD.ASSETS, value)
  loading.value = false
}

const handleSelect = (value: string) => {
  const contact = contactsList.value?.find(contact => contact.id === value)
  if (contact) {
    handleAssign(contact)
  }
}

const handleCreate = (name?: string, callback?: () => void) => {
  callback?.()
  setTimeout(() => {
    const instance = contactsBunchStore.createElement()
    instance.set({
      name,
    })
    instance.isDialog = true
    instance.addEventListener(EntityEvent.STORED, () => {
      instance.isDialog = false
      handleAssign(instance.get())
    })
    const modalInstance = modalsStore.init(instance.id, instance)
    modalInstance?.open(modalsStore.getZIndex())
    modalInstance?.addEventListener(ModalEvent.CLOSE, () => {
      instance.remove()
    })
  }, 0)
}

const handleAssign = async (contact: Contact) => {
  const instance = contactsBunchStore.getElementById(contact.id)
  if (!instance) return
  const assets = instance?.field<string[]>(CONTACT_FIELD.ASSETS).value
  updateAssetContacts(instance, [...(assets || []), props.instance.id])
  contactValue.value = undefined
}

const handleRemove = async () => {
  const id = removedContact.value?.id
  removedContact.value = undefined
  if (!id) return
  const instance = contactsBunchStore.getElementById(id)
  if (!instance) return
  loading.value = true
  const contacts = props.instance.field<string[]>(ASSET_FIELD.CONTACTS).value
  props.instance.field<string[]>(ASSET_FIELD.CONTACTS).value = contacts.filter(
    contactId => contactId !== id,
  )
  await props.instance.update()
  const assets = instance
    ?.field<string[]>(CONTACT_FIELD.ASSETS)
    .value.filter(assetId => assetId !== props.instance.id)
  instance?.forceUpdate(CONTACT_FIELD.ASSETS, assets)
  loading.value = false
}
</script>

<style>
.asset-drawer-contacts {
  &__item {
    @apply flex flex-col;
    @apply gap-0.5;
    @apply font-normal;
  }

  &__item-name {
    @apply font-medium;
  }
}
</style>
