<template>
  <div
    class="mapping-rules-block"
    :class="mainClasses"
    @click="modelValue = true"
  >
    <div class="mapping-rules-block__caption">
      <span>{{ title }}</span>
      <UISwitch v-model="modelValue" />
    </div>
    <div v-if="modelValue" class="mapping-rules-block__content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { UISwitch } from '@ui/controllers'

type Props = {
  title: string
}

defineProps<Props>()

const modelValue = defineModel<boolean>()

const mainClasses = computed(() => ({
  'mapping-rules-block--active': modelValue.value,
}))

defineOptions({
  name: 'LinkedDataRulesBlock',
})
</script>

<style lang="postcss">
.mapping-rules-block {
  @apply bg-gray-100 dark:bg-gray-900;
  @apply border border-transparent;
  @apply rounded-md;

  &:not(.mapping-rules-block--active) {
    @apply cursor-pointer;
  }

  &--active {
    @apply border-indigo-500;
    @apply dark:border-indigo-400 dark:border-opacity-50;
  }

  &__caption,
  &__content {
    @apply p-4;
  }

  &__caption {
    @apply flex items-center justify-between;
  }

  &__content {
    @apply flex flex-col;
    @apply gap-2;
    @apply pt-0;
  }
}
</style>
