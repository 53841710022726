<template>
  <UIInputSelect v-model="criteriaComparison" :size :data />
  <UIInputText
    v-if="isFieldVisible"
    v-model="criteriaValue"
    :size
    @ready="el => el.focus()"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, watch } from 'vue'

import { FilterComparison, TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'

import { getComparisonData } from '@/components/UI/Filter/components/utils/helpers'

import { UIInputSelect, UIInputText } from '@ui/fields'

const modelValue = defineModel<TransactionRule['data']['criteria'][number]>({
  required: true,
})

const criteriaComparison = computed({
  get() {
    return modelValue.value.comparison
  },
  set(value) {
    modelValue.value.comparison = value
  },
})

const criteriaValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value
  },
})

const data = computed(() =>
  getComparisonData().map(value => ({
    value,
    label: value,
  })),
)

const isFieldVisible = computed(
  () =>
    criteriaComparison.value &&
    ![FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
      criteriaComparison.value,
    ),
)

watch(isFieldVisible, value => {
  criteriaValue.value = value ? '' : undefined
})

onBeforeMount(() => {
  if (criteriaComparison.value) return
  criteriaComparison.value = FilterComparison.CONTAINS
})
</script>
