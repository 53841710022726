import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'vue-router'

import { PaginatedRequest } from '@types'

import { ROUTE_NAME } from '@/const'

import { handleCatchedRequestError } from '@/helpers/common'
import { useNotifications } from '@/plugins/notification'

import { useUserStore } from '@/store/user'

import { SoftDeletedError } from './utils/errors'

const { VITE_APP_API_ID, VITE_APP_AWS_REGION, VITE_APP_API_URL } = import.meta
  .env

let backendUrl: string
let withCredentials: boolean | undefined

if (VITE_APP_API_URL !== undefined) {
  backendUrl = VITE_APP_API_URL
} else {
  if (__IS_LOCAL__) {
    backendUrl = 'http://localhost:5002/api/'
  } else {
    backendUrl = `https://${VITE_APP_API_ID}.execute-api.${
      VITE_APP_AWS_REGION || 'us-east-1'
    }.amazonaws.com/Prod/api/`
  }
}

if (/api\.dev\S*\.allposit\.com/.test(backendUrl)) {
  withCredentials = true
}

export { backendUrl }

const api = axios.create({
  baseURL: backendUrl,
  withCredentials,
})

api.interceptors.request.use(async config => {
  const correlationId = uuidv4()

  const userStore = useUserStore()

  if (config.headers) {
    config.headers['request-startTime'] = new Date().getTime()
  }

  if (userStore.getIdToken && userStore.getAccessToken) {
    if (!config.url?.includes('s3.amazonaws.com')) {
      config.headers.set('Authorization', userStore.getIdToken)
    }
    config.headers.set('access-token', userStore.getAccessToken)
    const allpositUuid = userStore.getAllpositUuid
    if (allpositUuid) {
      config.headers.set('x-allposit-user', allpositUuid)
    }
    config.headers['x-correlation-id'] = correlationId
  }
  return config
})

api.interceptors.response.use(
  response => {
    const currentTime = new Date().getTime()
    if (response.config.headers) {
      const startTime = Number(response.config.headers['request-startTime'])
      response.headers['request-duration'] = `${currentTime - startTime}`
    }
    return response
  },
  async e => {
    handleCatchedRequestError(e.config, e.response)

    const { error } = useNotifications()
    const userStore = useUserStore()
    const router = useRouter()

    if (e.message === 'canceled') return Promise.reject(e)

    const originalRequest = e.config
    if (!e.response?.status || e.response.status >= 500) {
      await error({
        message: 'Unexpected error',
        description:
          'An error occurred while handling your request. We have been notified of the error and will correct as soon as possible. Please try again. If the problem persists, please try again later.',
      })
      if (!e.response?.status) {
        await userStore.signOut(false)
      }
      return Promise.reject()
    }
    switch (e.response.status) {
      case 400: {
        let message = e.response?.data?.message || 'Something went wrong'
        if (typeof message === 'object') {
          message = Object.entries(e.response?.data?.message)
            .map(([title, errors]) => {
              return `${title}: ${
                Array.isArray(errors) ? errors.join(', ') : error
              }`
            })
            .join('; ')
        }
        await error({
          errors: e.response?.data?.errors || null,
          message,
        })
        throw Error(message)
      }
      case 402:
        await error({
          message:
            'You need to activate the subscription to access the resource',
        })
        router.push({
          name: ROUTE_NAME.SUBSCRIPTION,
        })
        break
      case 403:
        await error({
          message: 'You don`t have permissions for this action',
        })
        break
      case 409: {
        const message = e.response?.data?.message || 'Something went wrong'

        if (message.includes('soft deleted')) {
          const error = new SoftDeletedError(message)
          error.isSoftDeleted = true
          error.isSoftDeleted = true
          throw error
        }
        throw new Error(message)
      }
    }
    if (!originalRequest._retry && e.response.status === 401) {
      originalRequest._retry = true
      const tokens = await userStore.refreshToken()
      if (!tokens) return Promise.reject()
      if (!e.config.url?.includes('s3.amazonaws.com')) {
        originalRequest.headers.set('Authorization', tokens.idToken?.toString())
      }
      originalRequest.headers.set('access-token', tokens.accessToken.toString())
      return api(originalRequest)
    } else {
      return Promise.reject(e.response?.data)
    }
  },
)

export const getPaginatedRequest: PaginatedRequest = async (
  url,
  params,
  signal,
) => {
  let sort: any = params.sort
  if (sort && typeof sort !== 'string') {
    sort = JSON.stringify(sort)
  }
  let filter: any = params.filter
  if (filter && typeof filter !== 'string') {
    filter = JSON.stringify(filter)
  }

  const result = await api.get(url, {
    params: {
      ...params,
      sort,
      filter,
    },
    signal,
  })
  return result.data
}

export default api
