<template>
  <TransactionAmountField
    v-model="modelValue"
    v-bind="{
      ...$attrs,
      error,
      description,
      settings,
    }"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'
import { set } from 'lodash'

import { TransactionClass } from '..'

import { preSetAmount } from './utils/helpers'

import TransactionAmountField from './TransactionAmountField.vue'

type Props = {
  entryIndex: number
  instance: TransactionClass
}

const props = defineProps<Props>()

const modelValue = computed({
  get() {
    return props.instance.entries[props.entryIndex]?.amount ?? null
  },
  set(value) {
    set(
      props.instance.data,
      ['entries', props.entryIndex, 'amount'],
      value ?? null,
    )
    props.instance.validateData()
  },
})

const entrySettings = computed(
  () => props.instance.settings?.entries[props.entryIndex],
)
const settings = computed(() => entrySettings.value?.amount)

const error = computed(
  () => props.instance.errors[`entries[${props.entryIndex}].amount`],
)

const description = computed(() => entrySettings.value?.amountDescription)

onBeforeMount(() => {
  if (modelValue.value) return
  modelValue.value = preSetAmount(modelValue.value, settings.value)
})
</script>
