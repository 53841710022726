<template>
  <div class="asset__fields">
    <component
      :is="instance.getFormName()"
      v-bind="{
        dataRefid: 'accountFormName',
        focusOnLoad: focusNameOnLoad,
        instance,
        label: ASSET_FIELD_LABEL[ASSET_FIELD.NAME],
        readonly,
      }"
    />
    <component
      :is="instance.getFormCategory()"
      v-bind="{
        dataRefid: 'accountFormCategory',
        focusOnLoad: focusCategoryOnLoad,
        instance,
        label: ASSET_FIELD_LABEL[AssetAccountTag.CATEGORY],
        readonly,
      }"
    />
    <component
      :is="instance.getFormSubcategory()"
      v-bind="{
        dataRefid: 'accountFormSubcategory',
        focusOnLoad: focusSubcategoryOnLoad,
        instance,
        label: ASSET_FIELD_LABEL[AssetAccountTag.SUBCATEGORY],
        readonly,
      }"
    />
    <div class="asset__buttons">
      <UIButtonClose @click="handleClose" />
      <UIButtonSave
        v-if="!readonly"
        ref="buttonSaveRef"
        data-refid="accountFormSave"
        v-bind="{
          disabled,
          loading,
        }"
        @click="handleSave"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  inject,
  markRaw,
  nextTick,
  provide,
  ref,
  useTemplateRef,
} from 'vue'
import { upperFirst } from 'lodash'

import { ReadonlyMode } from '@types'
import { AssetClass } from '..'
import { EntityState } from '@/entities/utils/enums'
import { AssetAccountTag, AssetCategoryAccount } from '../utils/enums'

import { ASSET_FIELD, ASSET_FIELD_LABEL, ASSET_ID_PREFIX } from '../utils/const'
import { ROUTE_NAME } from '@/const'
import { NOTIFICATION_DELAY, READONLY_MODE } from '@/const/common'

import { handleCatchedError } from '@/helpers/common'
import { SoftDeletedError } from '@/store/utils/errors'

import { useModalsStore } from '@/store/modals'
import { NotificationAction, useNotifications } from '@/plugins/notification'

import { UIButtonClose, UIButtonSave } from '@ui/buttons'

import AccountsTrashPopup from '@/views/Data/Accounts/components/AccountsTrashPopup.vue'

type Props = {
  instance: AssetClass
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modalsStore = useModalsStore()
const { error, progress, remove, update } = useNotifications()

const loading = ref(false)

const buttonSaveRef = useTemplateRef('buttonSaveRef')
provide('asset-save-focus', async () => {
  await nextTick()
  buttonSaveRef.value?.focus()
})

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const readonly = computed(() => props.instance.isReadonly || isReadonly?.value)

const disabled = computed(
  () => ![EntityState.CHANGED, EntityState.NEW].includes(props.instance.state),
)

const isSavedAsset = computed(() =>
  props.instance.id.startsWith(ASSET_ID_PREFIX),
)
provide('isSavedAsset', isSavedAsset)

const focusNameOnLoad = computed(
  () => !isSavedAsset.value && !props.instance.field(ASSET_FIELD.NAME).value,
)
const focusCategoryOnLoad = computed(
  () => !focusNameOnLoad.value && !props.instance.categoryId,
)
const focusSubcategoryOnLoad = computed(
  () =>
    !focusNameOnLoad.value &&
    !focusCategoryOnLoad.value &&
    !props.instance.subcategoryId,
)

const openDeletedModal = () => {
  const modalInstance = modalsStore.init(
    'deleted-accounts',
    markRaw(AccountsTrashPopup),
  )
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClose = () => {
  emit('close')
}

const name = computed(
  () => props.instance.field<string>(ASSET_FIELD.TYPE).value,
)
const capitalizeName = computed(() => upperFirst(name.value))

const handleSave = async () => {
  loading.value = true
  const isNew = props.instance.isNew
  const nid = await progress({
    message: `${isNew ? 'Creating' : 'Updating'} ${name.value}`,
  })
  try {
    if (isNew) {
      await props.instance.store()
    } else {
      await props.instance.update()
    }
    await update(
      nid,
      {
        type: 'success',
        message: `${capitalizeName.value} ${
          props.instance.field(ASSET_FIELD.NAME).value
        } ${isNew ? 'created' : 'updated'}`,
        link: {
          text: 'Open',
          to: {
            name:
              name.value === AssetCategoryAccount.INCOME
                ? ROUTE_NAME.INCOME_ACCOUNTS_ITEM
                : ROUTE_NAME.EXPENSE_ACCOUNTS_ITEM,
            params: { id: props.instance.id },
          },
        },
      },
      NOTIFICATION_DELAY,
    )
    emit('close')
  } catch (e) {
    await remove(nid)
    if (e instanceof SoftDeletedError) {
      const actions: NotificationAction[] = [
        {
          label: 'Go to deleted accounts',
          buttonVariant: 'primary',
          onClick: remove => {
            remove && remove()
            openDeletedModal()
          },
        },
        {
          label: 'Close',
          buttonVariant: 'gray',
          buttonFill: 'outlined',
          onClick: remove => {
            remove && remove()
          },
        },
      ]

      await error({ message: e.message, actions })
      return
    }
    handleCatchedError(e as string, props.instance.get())
  } finally {
    loading.value = false
  }
}
</script>

<script lang="ts">
export default {
  name: 'AccountForm',
}
</script>

<style scoped>
@import url('./styles/asset.css');
</style>
