<template>
  <UIDraggableWrapper label="Price precedence">
    <draggable
      v-model="modelValue"
      :item-key="pricePrecedenceKey"
      :animation="200"
      :disabled
      handle=".handle"
      tag="div"
      @change="handleChange"
    >
      <template #item="{ element }">
        <UIDraggableItem :item="element" />
      </template>
    </draggable>
  </UIDraggableWrapper>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import draggable from 'vuedraggable'

import { Repository } from '@types'

import { UIDraggableItem, UIDraggableWrapper } from '@ui/core'
import { useDebounceFn } from '@vueuse/core'

type Props = {
  repository: Repository
  disabled?: boolean
}

type Emits = {
  change: [string[]]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = ref<string[]>(
  props.repository.settings?.price_source_precedence || [],
)

const pricePrecedenceKey = (item: string) => item

const handleChange = useDebounceFn(() => {
  emit('change', modelValue.value)
}, 500)
</script>
