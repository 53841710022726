import { computed, inject, nextTick, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { useTimeoutFn } from '@vueuse/core'

import {
  TaskActionEntryUpdate,
  TaskApiParams,
  TaskApiResponseEntry,
  TaskApiResponseList,
  TaskEntry,
  TaskList,
} from './utils/types'
import { TaskStatus, TaskType, WSInstance, WSResponse } from '@types'

import { TASK_TIMEOUT } from './utils/const'

import {
  prepareResponseError,
  removeStoreListItem,
  updateStoreListItem,
} from '../utils/helpers'

import api from '@/store/api'
import { useUserStore } from '@/store/user'

export const useTasksStore = defineStore('tasks', () => {
  // INIT
  const userStore = useUserStore()

  const ws = inject<WSInstance>('ws')

  // STORE
  const list = ref<TaskList>([])
  const observed = ref(new Map())

  // GETTERS
  const getList = computed<TaskList>(() => {
    return list.value
  })

  const getProcessing = computed(() => {
    return getList.value.filter(task =>
      [TaskStatus.WAITING, TaskStatus.RUNNING].includes(task.status),
    )
  })

  const getFinished = computed(() => {
    return getList.value.filter(
      task => ![TaskStatus.WAITING, TaskStatus.RUNNING].includes(task.status),
    )
  })

  // SETTERS
  const setList = (value: TaskList) => {
    stop()
    list.value = value
    nextTick(() => {
      if (!getProcessing.value.length) return
      start()
    })
  }

  const commit = (tasks: TaskList) => {
    list.value = [...list.value, ...tasks]
  }

  // ACTIONS
  const fetch = async (
    params: TaskApiParams = {
      excluded_statuses: [TaskStatus.SUCCEEDED],
      excluded_types: [TaskType.EOD_SEARCH, TaskType.ANALYTICS_COMPUTE],
    },
  ) => {
    try {
      const preparedParams =
        params &&
        Object.fromEntries(
          Object.keys(params).map(key => [
            key,
            JSON.stringify(params[key as keyof typeof params]),
          ]),
        )
      const response = await api.get<TaskApiResponseList>('tasks', {
        params: preparedParams,
      })
      setList(response.data.data)
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const { start, stop } = useTimeoutFn(fetch, TASK_TIMEOUT)
  stop()

  const observe = <T>(taskId: string) =>
    new Promise<TaskEntry<T>>((resolve, reject) => {
      observed.value.set(taskId, { resolve, reject })
    })

  const updateEntry: TaskActionEntryUpdate = async task => {
    const { id, ...body } = task
    try {
      const response = await api.patch<TaskApiResponseEntry<any>>(
        `tasks/${id}`,
        body,
      )
      const data = response.data
      updateStoreListItem(list.value, data)
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const deleteEntry = async (id: string) => {
    try {
      removeStoreListItem(list.value, id)
      await api.delete(`tasks/${id}`)
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  // CLEAR STORAGE

  const clear = () => {
    list.value = []
    observed.value = new Map()
  }

  watch(
    () => userStore.isAuthenticated,
    value => {
      if (!value) return
      ws?.subscribe('tasks', 'get', async (message: WSResponse<TaskList>) => {
        const data = message.data
        if (!data) return
        setList(data)
      })
    },
    { immediate: true },
  )

  watch(
    getFinished,
    tasks => {
      if (!tasks.length) return
      observed.value.forEach(({ resolve, reject }, taskId) => {
        const task = tasks.find(task => task.id === taskId)
        if (!task) return
        switch (task.status) {
          case TaskStatus.DONE:
          case TaskStatus.SUCCEEDED:
            resolve(task)
            break
          case TaskStatus.ABORTED:
          case TaskStatus.FAILED:
          case TaskStatus.TIME_OUT:
            reject(task.error)
            break
        }
        observed.value.delete(taskId)
      })
    },
    { immediate: true },
  )

  return {
    getList,
    getProcessing,
    getFinished,

    commit,

    fetch,
    observe,
    updateEntry,
    deleteEntry,

    clear,
  }
})
