<template>
  <div class="modal-repositories-actions">
    <UIButton
      data-refid="repositoryImport"
      label="Import"
      :disabled
      :size
      :leading="ArrowDownTrayIcon"
      variant="secondary"
      @click="handleImport"
    />
    <UIButton
      data-refid="repositoryCreate"
      :disabled
      :size
      :leading="PlusIcon"
      label="New"
      @click="handleCreate"
    />
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { markRaw } from 'vue'

import { ButtonSize } from '@types'

import { useModalsStore } from '@/store/modals'

import RepositoryCreate from '@/modules/modals/Repositories/Data/RepositoryCreate.vue'
import RepositoryImport from '@/modules/modals/Repositories/Data/RepositoryImport.vue'

import { ArrowDownTrayIcon, PlusIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui/buttons'

type Props = {
  disabled?: boolean
  size?: ButtonSize
}

type Emits = {
  click: []
  hide: []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const modalsStore = useModalsStore()

const handleImport = () => {
  emit('click')
  const modalInstance = modalsStore.init(
    'repository-import',
    markRaw(RepositoryImport),
  )
  modalInstance?.open(modalsStore.getZIndex())
}

const handleCreate = () => {
  emit('click')
  const modalInstance = modalsStore.init(
    'repository-create',
    markRaw(RepositoryCreate),
  )
  modalInstance?.open(modalsStore.getZIndex(), {
    onHide: () => {
      emit('hide')
    },
  })
}
</script>

<style scoped>
.modal-repositories-actions {
  @apply flex items-center justify-between;
  @apply gap-2;

  :deep() .ui-button {
    @apply whitespace-nowrap;
  }
}
</style>
