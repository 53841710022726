<template>
  <UISkeleton v-if="loading" rounded class="account-connector-info__skeleton" />
  <UIAccountInfo v-else-if="account" v-bind="{ account, connector }">
    <template #connector>
      <ConnectorActions
        v-if="connector"
        v-bind="{ connector }"
        :loading="isLoading"
        @import="handleImport"
        @reconnect="handleReconnect"
        @resync="handleResync"
      />
    </template>
  </UIAccountInfo>
  <UILayoutNoData v-else inline hide-question-icon />
  <Teleport to="body">
    <ImportAccount ref="importAccountRef" />
    <ReconnectAccount ref="reconnectAccountRef" />
  </Teleport>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef, watch } from 'vue'

import { LinkedDataAccount, LinkedDataConnector } from '@types'

import { useLinkedDataConnector } from '@/hooks/linkedDataConnector'

import { UIAccountInfo, UILayoutNoData } from '@ui/core'
import { UISkeleton } from '@ui/skeletons'
import ConnectorActions from '@/views/LinkedData/Connectors/components/ConnectorActions.vue'
import ImportAccount from '@/views/LinkedData/Connectors/components/ImportAccount.vue'
import ReconnectAccount from '@/views/LinkedData/Connectors/components/ReconnectAccount.vue'

type Props = {
  account?: LinkedDataAccount
  connector?: LinkedDataConnector
  loading?: boolean
}
const props = defineProps<Props>()

const { resync, syncingConnectors } = useLinkedDataConnector()

const importAccountRef = useTemplateRef('importAccountRef')
const reconnectAccountRef = useTemplateRef('reconnectAccountRef')

const isLoading = ref(false)

const isSyncing = computed(
  () =>
    !!props.connector?.source &&
    syncingConnectors.value.includes(props.connector.source.toLowerCase()),
)

const handleImport = (data: string) => {
  importAccountRef.value?.processing(data)
}

const handleReconnect = (connector: LinkedDataConnector) => {
  reconnectAccountRef.value?.processing(connector)
}

const handleResync = (connector: LinkedDataConnector) => {
  isLoading.value = true
  resync(connector.id)
}

watch(isSyncing, value => {
  isLoading.value = value
})
</script>

<script lang="ts">
export default {
  name: 'AccountConnectorInfo',
}
</script>

<style scoped lang="postcss">
.account-connector-info {
  &__skeleton {
    @apply h-28;
  }
}
</style>
