<template>
  <UIInputDynamicList
    v-model="modelValue"
    :data
    editable
    removable
    placeholder="Find or create new"
    locked-key="is_system"
    @click:item="handleSelectTag"
    @click:new="handleCreateTag"
    @update="handleUpdateTag"
    @delete="handleDeleteTag"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { orderBy } from 'lodash'

import { CommonTag } from '@types'

import { useNotifications } from '@/plugins/notification'
import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'

import { NOTIFICATION_DELAY } from '@/const/common'
import { handleCatchedError } from '@/helpers/common'
import { UIInputDynamicList } from '@ui/fields'

type props = {
  selected?: CommonTag[]
}

const { selected } = defineProps<props>()

type Emits = {
  select: [data: CommonTag]
}
const emit = defineEmits<Emits>()

const modelValue = defineModel<string>()

const { progress, remove, update } = useNotifications()
const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()

const data = computed(() => {
  const tags = linkedDataTransactionsTagsStore.getList.filter(
    tag => !selected?.find(selectedTag => selectedTag.id === tag.id),
  )
  return orderBy(
    tags.map(tag => ({
      value: `${tag.id}`,
      label: tag.name,
      meta: { is_system: tag.is_system },
    })),
    'label',
  )
})

const handleSelectTag = (value: string) => {
  const data = linkedDataTransactionsTagsStore.getList.find(
    tag => tag.id === value,
  )
  if (!data) return
  emit('select', data)
}

const handleCreateTag = async (tag: string, callback: () => void) => {
  const nid = await progress({
    message: 'Creating tag',
  })
  try {
    const result = await linkedDataTransactionsTagsStore.store(tag)
    if (!result?.id) throw Error('Unknown tag')
    modelValue.value = result.id
    await update(
      nid,
      {
        type: 'success',
        message: `Tag "${result.name}" created`,
      },
      NOTIFICATION_DELAY,
    )
    emit('select', result)
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string, { tag })
  } finally {
    callback()
  }
}

const handleUpdateTag = async (id: string, name: string) => {
  const nid = await progress({
    message: 'Updating tag',
  })
  try {
    const result = await linkedDataTransactionsTagsStore.update({
      id,
      name,
    })
    if (nid) {
      await update(
        nid,
        {
          type: 'success',
          message: `Tag ${result?.name} updated`,
        },
        NOTIFICATION_DELAY,
      )
    }
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string, { id, name })
  }
}

const handleDeleteTag = async (id: string, callback: () => void) => {
  const nid = await progress({
    message: 'Deleting tag',
  })
  try {
    await linkedDataTransactionsTagsStore.destroy(id)
    if (nid) {
      await update(
        nid,
        {
          type: 'success',
          message: 'Tag deleted',
        },
        NOTIFICATION_DELAY,
      )
    }
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string, { id })
  } finally {
    callback()
  }
}
</script>
