<template>
  <div class="repository-collaborators">
    <template v-if="!readonly && repository?.id">
      <div class="repository-collaborators__caption">Add collaborator:</div>
      <CollaboratorsInvite
        :repository-id="repository?.id"
        :list="items"
        :disabled
        title="Invite to collaborate"
      />
      <div class="repository-collaborators__caption">
        Repository collaborators:
      </div>
    </template>
    <div v-else class="repository-collaborators__title">
      Repository collaborators
    </div>
    <div class="repository-collaborators__container">
      <UIGridSkeleton
        v-if="isLoading"
        v-bind="{ columns, sm }"
        wrapped
        message="Loading collaborators..."
      />
      <UIGrid
        v-else
        v-bind="{ columns, editRowId, items, sm }"
        v-model:sort="sort"
        unitary-sort
        wrapped
        editable-rows
        id-field="email"
      >
        <template #cellAccessType="{ displayValue, editRow, item }">
          <template v-if="isPermissionsChangeAllow(item)">
            <div
              v-if="editRow"
              class="repository-collaborators__access-field"
              @mouseup.stop
              @mousedown.stop
            >
              <UIInputSelect
                v-model="currentPermission"
                :data
                size="sm"
                @blur="handleBlur"
                @ready="handleReady"
              />
            </div>
            <span v-else class="repository-collaborators__access">
              {{ displayValue }}
              <PencilIcon @click="handleClickEdit(item)" />
            </span>
          </template>
        </template>
        <template #actions="{ item, size }">
          <UIButton
            v-if="isUnlinkButtonVisible(item)"
            label="Unlink"
            :leading="UserMinusIcon"
            variant="danger"
            fill="light"
            :size
            @click="handleClickRemove(item, false)"
          />
          <UIButton
            v-if="isRemoveButtonVisible(item)"
            label="Remove user"
            :leading="UserMinusIcon"
            variant="danger"
            fill="light"
            :size
            @click="handleClickRemove(item)"
          />
        </template>
      </UIGrid>
    </div>
  </div>
  <UIModal
    :is-open="dialogDelete"
    :type="ModalType.ACTION_DIALOG"
    :icon="{
      name: ExclamationCircleIcon,
      color: 'red',
      position: 'left',
    }"
    :actions="deleteDialogActions"
    :title="dialogDeleteTitle"
    size="md"
    @hide="dialogDelete = false"
  >
    {{ dialogDeleteMessage }}
  </UIModal>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, onWatcherCleanup, ref, watch } from 'vue'

import {
  DialogActionButton,
  ModalType,
  Repository,
  RepositoryStatus,
  RepositoryCollaborator,
  Sort,
  RepositoryInvite,
  ReadonlyMode,
} from '@types'

import { ACCESS_TYPE_OWNER, ACCESS_TYPES } from '@/const/repositories'
import { EMPTY_VALUE_PLACEHOLDER, READONLY_MODE } from '@/const/common'

import useGridSort from '@/components/hooks/gridSort'
import { handleCatchedError } from '@/helpers/common'
import { useNotifications } from '@/plugins/notification'

import { useRepositoriesStore } from '@/store/repositories'

import { UIButton } from '@ui/buttons'
import { UIGrid, UIGridSkeleton } from '@ui/grid'
import { UIModal } from '@ui/modals'

import {
  ExclamationCircleIcon,
  PencilIcon,
  UserMinusIcon,
} from '@heroicons/vue/24/outline'
import CollaboratorsInvite from './CollaboratorsInvite.vue'
import { UIInputSelect } from '@ui/fields'

type Props = {
  repository: Repository
}

const props = defineProps<Props>()

const repositoriesStore = useRepositoriesStore()

const { success } = useNotifications()

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const permissionRef = ref<HTMLInputElement>()

const currentPermission = ref<string>()

const sort = ref<Sort[]>()

const dialogDelete = ref(false)
const isOwnereDelete = ref(true)

const removedItem = ref<RepositoryInvite | RepositoryCollaborator>()
const editedItem = ref<RepositoryCollaborator>()

const disabled = computed(() => repositoriesStore.loadingCollaboratorsAction)

const editRowId = computed(() => editedItem.value?.email)

const dialogDeleteTitle = computed(() =>
  isOwnereDelete.value ? 'Remove collaborator' : 'Unlink repository',
)
const dialogDeleteMessage = computed(() =>
  isOwnereDelete.value
    ? 'Are you sure you want to remove this collaborator?'
    : 'Are you sure you want to unlink this repository?',
)
const dialogDeleteButtonText = computed(() =>
  isOwnereDelete.value ? 'Remove' : 'Unlink',
)

const sm = '1fr'
const columns = computed(() => [
  {
    name: 'email',
    caption: 'Email',
  },
  {
    name: 'access_type',
    caption: 'Permissions',
    formatter: (item: RepositoryCollaborator) =>
      ACCESS_TYPES[Number(item) - 1] || EMPTY_VALUE_PLACEHOLDER,
    default: '120px',
    cellClasses: 'group',
  },
  {
    name: 'status',
    caption: 'Status',
    default: '110px',
  },
])

const inputItems = computed(() => repositoriesStore.getCollaboratorsList)

const items = useGridSort(sort, columns, inputItems, ['amount'])

const isLoading = computed<boolean>(
  () => repositoriesStore.loadingCollaborators,
)

const deleteDialogActions = computed<DialogActionButton[]>(() => [
  {
    label: dialogDeleteButtonText.value,
    onClick: handleRemoveAccess,
    buttonVariant: 'danger',
  },
])

const data = ACCESS_TYPES.filter(
  (_, index) => index !== ACCESS_TYPE_OWNER - 1,
).map((label, index) => ({
  value: `${index + 1}`,
  label,
}))

const handleBlur = () => {
  editedItem.value = undefined
  currentPermission.value = undefined
}

const handleClickEdit = async (item: RepositoryCollaborator) => {
  if (item.access_type === ACCESS_TYPE_OWNER) return
  editedItem.value = item
  currentPermission.value = `${item.access_type}`
  await nextTick()
  permissionRef.value?.focus()
}

const handleClickRemove = (
  item?: RepositoryInvite | RepositoryCollaborator,
  isOwner = true,
) => {
  isOwnereDelete.value = isOwner
  dialogDelete.value = true
  removedItem.value = item
}

const handleUpdateAccess = async (access_type?: number) => {
  const id = editedItem.value?.user?.id
  if (!id || !props.repository?.id || !access_type) return
  handleBlur()
  try {
    await repositoriesStore.updateRepositoryCollaborator(
      id,
      props.repository?.id,
      {
        access_type,
      },
    )
    success({ message: 'Access rights have been successfully changed' })
  } catch (e) {
    handleCatchedError(e as string, { id })
  }
}

const handleRemoveAccess = async () => {
  dialogDelete.value = false
  if (!removedItem.value || !props.repository?.id) return
  try {
    if (removedItem.value?.status === RepositoryStatus.ACCEPTED) {
      const data = removedItem.value as RepositoryCollaborator
      await repositoriesStore.removeRepositoryCollaborator(
        data.email,
        data.user.id,
        props.repository?.id,
        !isOwnereDelete.value,
      )
    } else {
      const data = removedItem.value as RepositoryInvite
      await repositoriesStore.removeRepositoryInvite(
        data.id,
        props.repository?.id,
      )
    }
  } catch (e) {
    handleCatchedError(e as string, {
      id: (removedItem.value as RepositoryInvite).id || removedItem.value.email,
    })
  }
}

const isUnlinkButtonVisible = (
  item?: RepositoryInvite | RepositoryCollaborator,
) => {
  if (!item) return
  const IS_OWNER = item.access_type === ACCESS_TYPE_OWNER
  return !!readonly?.value && !IS_OWNER
}

const isRemoveButtonVisible = (
  item?: RepositoryInvite | RepositoryCollaborator,
) => {
  if (!item) return
  const IS_OWNER = item.access_type === ACCESS_TYPE_OWNER
  return !isUnlinkButtonVisible(item) && !IS_OWNER && !readonly?.value
}

const isPermissionsChangeAllow = (
  item?: RepositoryInvite | RepositoryCollaborator,
) => {
  if (!item) return
  const IS_OWNER = item.access_type === ACCESS_TYPE_OWNER
  return (
    !IS_OWNER && !readonly?.value && item.status !== RepositoryStatus.PENDING
  )
}

const handleReady = (el: HTMLInputElement) => {
  el.focus()
}

watch(currentPermission, (value, oldValue) => {
  if (!oldValue || !value) return
  handleUpdateAccess(+value)
})
</script>

<style scoped>
.repository-collaborators {
  &__title {
    @apply mb-2;
  }

  &__caption {
    @apply mb-1;
    @apply text-xs;
    @apply text-gray-500 dark:text-gray-400;
  }

  &__container {
    @apply flex-auto;
    @apply -mx-4 sm:-mx-default px-4 sm:px-default;
  }

  &__access {
    @apply flex items-center;
    @apply gap-1;

    svg {
      @apply w-4 h-4;
      @apply hidden group-hover:block;
      @apply hover:text-indigo-500;
    }
  }

  &__access-field {
    @apply -mx-1.5;
  }
}
</style>
