<template>
  <div class="asset__fields">
    <AssetFormEODSearch
      v-if="isEODSearchShowed"
      v-bind="{
        instance,
        focusOnLoad: focusEODOnLoad,
        isToggled: toggledEOD,
      }"
    />
    <component
      :is="instance.getFormType()"
      v-bind="{
        dataRefid: 'assetFormType',
        focusOnLoad: focusTypeOnLoad,
        instance,
        label: ASSET_FIELD_LABEL[ASSET_FIELD.TYPE],
        readonly,
      }"
    />
    <component
      :is="instance.getFormName()"
      v-bind="{
        dataRefid: 'assetFormName',
        focusOnLoad: focusNameOnLoad,
        instance,
        label: ASSET_FIELD_LABEL[ASSET_FIELD.NAME],
        readonly,
      }"
    />
    <component
      :is="instance.getFormTicker()"
      v-bind="{
        dataRefid: 'assetFormTicker',
        focusOnLoad: focusTickerOnLoad,
        instance,
        label: ASSET_FIELD_LABEL[ASSET_FIELD.TICKER],
        readonly,
      }"
    />
    <component
      :is="instance.getFormCurrency()"
      v-bind="{
        dataRefid: 'assetFormCurrency',
        focusOnLoad: focusCurrencyOnLoad,
        instance,
        label: ASSET_FIELD_LABEL[ASSET_FIELD.CURRENCY],
        readonly,
      }"
    />
    <component
      :is="instance.getFormDescription()"
      v-bind="{
        dataRefid: 'assetFormDescription',
        instance,
        label: ASSET_FIELD_LABEL[ASSET_FIELD.DESCRIPTION],
        readonly,
      }"
    />
    <div class="asset__buttons">
      <UIButtonClose @click="handleClose" />
      <UIButtonSave
        v-if="!readonly"
        ref="buttonSaveRef"
        data-refid="assetFormSave"
        v-bind="{
          disabled,
          loading,
        }"
        @click="handleSave"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  inject,
  markRaw,
  nextTick,
  provide,
  ref,
  useTemplateRef,
} from 'vue'

import { ReadonlyMode } from '@types'
import { AssetClass } from '..'
import { EntityState } from '@/entities/utils/enums'

import { ASSET_FIELD, ASSET_FIELD_LABEL, ASSET_ID_PREFIX } from '../utils/const'
import { ROUTE_NAME } from '@/const'
import { NOTIFICATION_DELAY, READONLY_MODE } from '@/const/common'

import { handleCatchedError } from '@/helpers/common'
import { SoftDeletedError } from '@/store/utils/errors'

import { useModalsStore } from '@/store/modals'
import { NotificationAction, useNotifications } from '@/plugins/notification'

import AssetFormEODSearch from './AssetFormEODSearch.vue'
import { UIButtonClose, UIButtonSave } from '@ui/buttons'

import AssetsTrashPopup from '@/views/Data/Assets/components/AssetsTrashPopup.vue'

type Props = {
  instance: AssetClass
  toggledEOD?: boolean
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modalsStore = useModalsStore()
const { error, progress, remove, update } = useNotifications()

const loading = ref(false)

const buttonSaveRef = useTemplateRef('buttonSaveRef')
provide('asset-save-focus', async () => {
  await nextTick()
  buttonSaveRef.value?.focus()
})

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const disabled = computed(
  () => ![EntityState.CHANGED, EntityState.NEW].includes(props.instance.state),
)

const isSavedAsset = computed(() =>
  props.instance.id.startsWith(ASSET_ID_PREFIX),
)
provide('isSavedAsset', isSavedAsset)

const isEODSearchShowed = computed(
  () => !readonly?.value && !isSavedAsset.value,
)

const focusEODOnLoad = computed(
  () => !isSavedAsset.value && !props.instance.field(ASSET_FIELD.TYPE).value,
)
const focusTypeOnLoad = computed(
  () => props.toggledEOD && !props.instance.field(ASSET_FIELD.TYPE).value,
)
const focusNameOnLoad = computed(
  () => !focusEODOnLoad.value && !props.instance.field(ASSET_FIELD.NAME).value,
)
const focusTickerOnLoad = computed(
  () =>
    !focusEODOnLoad.value &&
    !focusNameOnLoad.value &&
    !props.instance.field(ASSET_FIELD.TICKER).value,
)
const focusCurrencyOnLoad = computed(
  () =>
    !focusEODOnLoad.value &&
    !focusNameOnLoad.value &&
    !focusTickerOnLoad.value &&
    !props.instance.field(ASSET_FIELD.CURRENCY).value,
)

const openDeletedModal = () => {
  const modalInstance = modalsStore.init(
    'deleted-assets',
    markRaw(AssetsTrashPopup),
  )
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClose = () => {
  emit('close')
}

const handleSave = async () => {
  loading.value = true
  const isNew = props.instance.isNew
  const nid = await progress({
    message: `${isNew ? 'Creating' : 'Updating'} asset`,
  })
  try {
    if (isNew) {
      await props.instance.store()
    } else {
      await props.instance.update()
    }
    await update(
      nid,
      {
        type: 'success',
        message: `Asset ${props.instance.field(ASSET_FIELD.NAME).value} ${
          isNew ? 'created' : 'updated'
        }`,
        link: {
          text: 'Open',
          to: {
            name: ROUTE_NAME.ASSETS_ITEM,
            params: { id: props.instance.id },
          },
        },
      },
      NOTIFICATION_DELAY,
    )
    emit('close')
  } catch (e) {
    await remove(nid)
    if (e instanceof SoftDeletedError) {
      const actions: NotificationAction[] = [
        {
          label: 'Go to deleted assets',
          buttonVariant: 'primary',
          onClick: remove => {
            remove && remove()
            openDeletedModal()
          },
        },
        {
          label: 'Close',
          buttonVariant: 'gray',
          buttonFill: 'outlined',
          onClick: remove => {
            remove && remove()
          },
        },
      ]

      await error({ message: e.message, actions })
      return
    }
    handleCatchedError(e as string, props.instance.get())
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>
@import url('./styles/asset.css');
</style>
