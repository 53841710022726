import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import {
  FilterActionListFetch,
  FilterApiResponseList,
  FilterList,
} from './utils/types'

import { getFilterOptionKey } from './utils/helpers'
import { prepareResponseError } from '../utils/helpers'

import api from '@/store/api'

export const useFilterStore = defineStore('filter', () => {
  // STORE
  const list = ref<FilterList>({})

  // ACTIONS

  const fetchList: FilterActionListFetch = async params => {
    try {
      const response = await api.get<FilterApiResponseList>('filter_options', {
        params,
      })
      const data = response.data
      setList(params.resource, params.field, data)
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  // GETTERS

  const getList = computed(() => list.value)

  // SETTERS

  const setList = (resource: string, field: string, value: string[]) => {
    const key = getFilterOptionKey(resource, field)
    list.value[key] = value.map(value => ({
      value,
      label: value,
    }))
  }

  // CLEAR STORAGE

  const clear = () => {
    list.value = {}
  }

  return {
    fetchList,

    getList,

    clear,
  }
})
