<template>
  <UIPricesChart
    v-model:range="range"
    v-bind="{ prices, nameFormatter, valueFormatter }"
  >
    <slot />
  </UIPricesChart>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { AssetClass } from '..'
import { AssetPrice, AssetPriceCloseField } from '../utils/types'
import { AssetPriceSource } from './utils/enums'
import { ChartPricesRange } from '@/components/UI/EChart/utils/enums'
import { numberFormat } from '@/helpers/numbers'
import { stringToLocalDateString } from '@/helpers/dates'

import { UIPricesChart } from '@ui/charts'

type Props = {
  instance: AssetClass
  items: AssetPrice[]
  priceSource: AssetPriceSource
}

const props = defineProps<Props>()

const range = ref<ChartPricesRange>()

const currency = computed(() => props.instance.currencySymbol)

const valueFormatter = computed(
  () => (data: number, fractionDigits?: number) =>
    numberFormat(data, { currency: currency?.value, fractionDigits }),
)

const closeField = computed<AssetPriceCloseField>(() =>
  props.priceSource === AssetPriceSource.SECURITY_MASTER_ADJ
    ? 'adjusted_close'
    : 'close',
)

const colors = {
  akoya: 'rgb(23, 138, 172)',
  manual: 'rgb(21,128,60)',
  'security master': 'rgb(28,78,216)',
  yodlee: 'rgb(9,74,140)',
  plaid: 'rgb(78,108,170)',
}

const prices = computed(() => {
  const data = props.items.reduce((acc, item) => {
    if (!acc.has(item.source)) {
      const name = item.source.charAt(0).toUpperCase() + item.source.slice(1)
      const color =
        colors[item.source as keyof typeof colors] || 'rgb(105,108,255)'
      acc.set(item.source, {
        name,
        itemStyle: { color },
        data: [],
      })
    }
    acc.get(item.source)?.data?.push(mapDataFn(item))
    return acc
  }, new Map<string, any>())
  return Array.from(data.values())
})

const mapDataFn = (item: AssetPrice) =>
  ({
    name: stringToLocalDateString(item.date),
    value: [item.date, item[closeField.value]],
  }) as any

const nameFormatter = (data: string) => {
  const postfix =
    props.priceSource === AssetPriceSource.SECURITY_MASTER_ADJ ? ' adj' : ''
  return `${data}${postfix} price`
}
</script>
