import {
  App,
  Plugin,
  ref,
  Ref,
  RendererElement,
  RendererNode,
  VNode,
} from 'vue'
import { RouteLocationRaw } from 'vue-router'

import { ButtonFill, ButtonVariant } from '@types'

import uuid from './uuid'

import AppNotification from './NotificationGroup.vue'

export type NotificationTypes =
  | 'warn'
  | 'error'
  | 'success'
  | 'timeout'
  | 'progress'

export type NotificationAction = {
  label: string
  buttonVariant?: ButtonVariant
  buttonFill?: ButtonFill
  attrs?: Record<string, any>
  tabIndex?: number
  onClick: (remove?: () => void) => void
}

export type NotificationError = {
  asset_ids: string[]
  date: string
}

export type Notification = {
  message:
    | string
    | VNode<
        RendererNode,
        RendererElement,
        {
          [key: string]: any
        }
      >
  link?: {
    text: string
    to: RouteLocationRaw
  }
  description?: string
  actions?: NotificationAction[]
  actionRequired?: boolean
  error_code?: string
  errors?: NotificationError[]
}

export type TypedNotification = {
  type?: NotificationTypes
} & Notification

export type NotificationInstance = {
  id: string
  created_at: number
}

export type MainNotification = {
  instance: NotificationInstance
  duplicates_count: number
} & TypedNotification

const notifications: Ref<MainNotification[]> = ref([])

const push = async (notify: TypedNotification): Promise<string> => {
  return new Promise(resolve => {
    notify = {
      ...notify,
      message: notify.message || notify?.error_code || 'Undefined code',
      link: notify.link,
      description: notify.description,
    }
    const notification: MainNotification = {
      instance: {
        id: uuid(),
        created_at: new Date().getTime(),
      },
      duplicates_count: 1,
      ...notify,
    }
    if (notify.type === 'timeout') {
      const item = notifications.value.find(item => item.type === 'timeout')
      if (item) {
        item.message = notify.message
        item.description = notify.description
        notification.instance.id = item.instance.id
      } else {
        notifications.value.unshift(notification)
      }
    } else {
      const item = notifications.value.find(
        item => item.message === notify.message,
      )
      if (item) {
        item.duplicates_count = item.duplicates_count + 1
        item.description = notify.description
        notification.instance.id = item.instance.id
      } else {
        notifications.value.push(notification)
      }
    }
    resolve(notification.instance.id)
  })
}

const notificationCreator = async (
  type: NotificationTypes,
  notification: Notification,
  timeout?: number,
) => {
  const nid: NotificationInstance = {
    id: '',
    created_at: new Date().getTime(),
  }
  nid.id = await push({
    ...notification,
    type,
  })
  if (timeout) {
    setTimeout(async () => await methods.remove(nid), timeout)
  }
  return nid
}

const MIN_DELAY = 500

const methods = {
  update: async (
    instance: NotificationInstance,
    notification: Notification & { type?: NotificationTypes },
    timeout?: number,
  ): Promise<void> => {
    return new Promise(resolve => {
      const notificationIndex = notifications.value.findIndex(
        item => item.instance.id === instance.id,
      )
      if (~notificationIndex) {
        notifications.value[notificationIndex] = {
          ...notifications.value[notificationIndex],
          ...notification,
        }
      }
      if (timeout) {
        setTimeout(async () => await methods.remove(instance), timeout)
      }
      resolve()
    })
  },
  remove: async (instance: NotificationInstance): Promise<void> => {
    const diff = new Date().getTime() - instance.created_at
    if (diff < MIN_DELAY) {
      await new Promise(resolve => setTimeout(resolve, MIN_DELAY - diff))
    }
    return new Promise(resolve => {
      const removeIndex = notifications.value.findIndex(
        item => item.instance.id === instance.id,
      )
      ~removeIndex && notifications.value.splice(removeIndex, 1)
      resolve()
    })
  },
  success: async (
    notification: Notification,
    timeout?: number,
  ): Promise<NotificationInstance> =>
    notificationCreator('success', notification, timeout),
  error: async (
    notification: Notification,
    timeout?: number,
  ): Promise<NotificationInstance> => {
    return notificationCreator('error', notification, timeout)
  },
  warn: async (
    notification: Notification,
    timeout?: number,
  ): Promise<NotificationInstance> =>
    notificationCreator('warn', notification, timeout),
  timeout: async (
    notification: Notification,
    timeout?: number,
  ): Promise<NotificationInstance> =>
    notificationCreator('timeout', notification, timeout),
  progress: async (
    notification: Notification,
    timeout?: number,
  ): Promise<NotificationInstance> =>
    notificationCreator('progress', notification, timeout),
  clearAll: async () => {
    notifications.value = []
  },
}

export type NotificationsInstance = {
  notifications: Ref<MainNotification[]>
} & typeof methods

export const useNotifications = (): NotificationsInstance => {
  return {
    notifications,
    ...methods,
  }
}

export default {
  install: function (app: App): void {
    app.component('AppAsyncNotification', AppNotification)
  },
} as Plugin
