<template>
  <UIInputAutocomplete v-model="actionValue" :size :data />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'
import { orderBy } from 'lodash'

import { TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'
import { ASSET_FIELD } from '@/entities/assets/utils/const'

import { useAssetsBunchStore } from '@/store/assets/bunch'

import { UIInputAutocomplete } from '@ui/fields'

const modelValue = defineModel<TransactionRule['data']['actions'][number]>({
  required: true,
})

const assetsBunchStore = useAssetsBunchStore()

const accountsInstancesList = computed(() =>
  Array.from(assetsBunchStore.getCommonList.values()),
)

const data = computed(() => {
  return orderBy(
    accountsInstancesList.value.map(item => ({
      value: item.id,
      label: item.field<string>(ASSET_FIELD.NAME).value,
    })),
    'label',
  )
})

const actionValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value || ''
  },
})

onBeforeMount(() => {
  if (actionValue.value !== undefined) return
  actionValue.value = ''
})

defineOptions({
  name: 'LinkedDataRulesActionAccount',
})
</script>
