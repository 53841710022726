<template>
  <UIInputSelect v-model="criteriaComparison" :size :data />
  <div v-if="isFieldVisible" class="mapping-rules-criteria-amount">
    <component
      :is="inputComponent"
      v-model="criteriaValue"
      :size
      @ready="handleReady"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue'

import { FilterComparison, FilterField, TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'
import { getComparisonData } from '@/components/UI/Filter/components/utils/helpers'

import { UIInputNumber, UIInputPercent, UIInputSelect } from '@ui/fields'

const modelValue = defineModel<TransactionRule['data']['criteria'][number]>({
  required: true,
})

const isPercent = ref(false)

const criteriaComparison = computed({
  get() {
    return modelValue.value.comparison
  },
  set(value) {
    modelValue.value.comparison = value
  },
})

const criteriaValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value
  },
})

const data = computed(() =>
  getComparisonData({ isAmount: true } as FilterField).map(value => ({
    value,
    label: value,
  })),
)

const inputComponent = computed(() =>
  isPercent.value ? UIInputPercent : UIInputNumber,
)

const isFieldVisible = computed(
  () =>
    criteriaComparison.value &&
    ![FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
      criteriaComparison.value,
    ),
)

const handleReady = (el: HTMLInputElement) => {
  el?.focus()
}

watch(isPercent, () => {
  criteriaValue.value = null
})

watch(isFieldVisible, value => {
  criteriaValue.value = value ? null : undefined
})

onBeforeMount(() => {
  if (criteriaComparison.value) return
  criteriaComparison.value = FilterComparison.GREATER
})
</script>

<style>
.mapping-rules-criteria-amount {
  @apply flex items-center;
  @apply gap-4;

  .ui-input-text {
    @apply flex-auto;
  }
}
</style>
