<template>
  <UIButton
    :label
    :disabled
    :loading="disabled"
    :trailing
    variant="secondary"
    fill="light"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { NOTIFICATION_DELAY } from '@/const'

import useAnalyticsStore from '@/store/analytics'
import { useRepositoriesStore } from '@/store/repositories'

import { handleCatchedError } from '@/helpers/common'
import { useNotifications } from '@/plugins/notification'
import { useResponsive } from '@/plugins/responsiveUI'

import { UIButton } from '@ui/buttons'
import { ArrowPathIcon } from '@heroicons/vue/24/outline'

type Props = {
  repositoryId: string
}
const props = defineProps<Props>()

const { success } = useNotifications()

const { isMobile } = useResponsive()

const analyticsStore = useAnalyticsStore()
const repositoriesStore = useRepositoriesStore()

const disabled = computed(() => repositoriesStore.deleteCache)

const label = computed(() =>
  isMobile.value ? undefined : 'Clear analytics cache',
)

const trailing = computed(() => (isMobile.value ? ArrowPathIcon : undefined))

const handleClick = async () => {
  try {
    await repositoriesStore.clearCache(props.repositoryId)
    await analyticsStore.markAsDeprecated()
    await success(
      {
        message: 'Analytics cache cleared',
      },
      NOTIFICATION_DELAY,
    )
  } catch (e) {
    handleCatchedError(e as string, { repositoryId: props.repositoryId })
  }
}
</script>
