import { InstatutionData } from './types'

export const ALLOWED_TYPES = [
  'bank account',
  'credit card',
  'investment account',
  'loan',
  'other',
]

export const CONNECTOR_SYNC_STATUS_TEXT = [
  '', // statuses start from 1
  'Success',
  'Warning',
  'Error',
  'Error',
]

export const INSTITUTION_LOGO_PLACEHOLDER =
  '/assets/img/connectors/placeholder.svg'

export const DEFAULT_INSTITUTIONS: InstatutionData[] = [
  {
    name: 'JPMorgan Chase',
    logo: '/assets/img/connectors/chase.svg',
    open_banking: true,
    institution_id: 'chase',
    source: 'akoya',
    routing_number: null,
    url: 'https://www.chase.com/',
  },
  {
    name: 'Bank Of America',
    logo: '/assets/img/connectors/bankofamerica.svg',
    open_banking: true,
    institution_id: 'bankofamerica',
    source: 'akoya',
    routing_number: null,
    url: 'https://www.bankofamerica.com/',
  },
  {
    name: 'Citibank',
    logo: '/assets/img/connectors/citi.svg',
    open_banking: true,
    institution_id: 'citi',
    source: 'akoya',
    routing_number: null,
    url: 'https://online.citi.com/',
  },
  {
    name: 'American Express',
    logo: '/assets/img/connectors/american-express.png',
    open_banking: true,
    institution_id: 'ins_10',
    source: 'plaid',
    routing_number: '011401533',
    url: 'https://www.americanexpress.com/us',
  },
  {
    name: 'Fidelity',
    logo: '/assets/img/connectors/fidelity.svg',
    open_banking: true,
    institution_id: 'fidelity',
    source: 'akoya',
    routing_number: null,
    url: 'https://www.fidelity.com/',
  },
  {
    name: 'Charles Schwab',
    logo: '/assets/img/connectors/charlesschwab.svg',
    open_banking: true,
    institution_id: 'ins_11',
    source: 'plaid',
    routing_number: '121202211',
    url: 'https://www.schwab.com',
  },
  {
    name: 'Interactive Brokers - US',
    logo: '/assets/img/connectors/interactivebrokers.svg',
    open_banking: true,
    institution_id: 'ins_116530',
    source: 'plaid',
    routing_number: '044000804',
    url: 'https://www.interactivebrokers.com/Universal/servlet/AccountAccess.Login',
  },
  {
    name: 'Coinbase',
    logo: '/assets/img/connectors/coinbase.svg',
    open_banking: true,
    institution_id: 'coinbase',
    source: 'coinbase',
    routing_number: null,
    url: 'https://www.coinbase.com/',
  },
]
