<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UISlidePanel
      v-bind="{ zIndex, isMinimized, title }"
      @minimize="handleMinimize"
      @hide="handleClose"
    >
      <AccountForm v-bind="{ instance: modal.entity }" @close="handleClose" />
    </UISlidePanel>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { AssetClass } from '..'
import { ModalClass, ModalState } from '@types'

import {
  INCOME_ACCOUNT_MODAL_TITLE,
  INCOME_ACCOUNT_MODAL_TITLE_NEW,
  EXPENSE_ACCOUNT_MODAL_TITLE,
  EXPENSE_ACCOUNT_MODAL_TITLE_NEW,
} from './utils/const'

import { useModalsStore } from '@/store/modals'

import { UISlidePanel } from '@ui/modals'
import AccountForm from './AccountForm.vue'

type Props = {
  modal: ModalClass<AssetClass>
}

const props = defineProps<Props>()

const modalsStore = useModalsStore()

const newTitle = computed(() =>
  props.modal.entity.isIncomeAccount
    ? INCOME_ACCOUNT_MODAL_TITLE_NEW
    : EXPENSE_ACCOUNT_MODAL_TITLE_NEW,
)
const commonTitle = computed(() =>
  props.modal.entity.isIncomeAccount
    ? INCOME_ACCOUNT_MODAL_TITLE
    : EXPENSE_ACCOUNT_MODAL_TITLE,
)
const title = computed(() =>
  props.modal.entity.isNew ? newTitle.value : commonTitle.value,
)

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const isMinimized = computed(() => props.modal.state === ModalState.HIDDEN)

const zIndex = computed(() => props.modal.zIndex)

const handleMinimize = (flag: boolean) => {
  if (flag) {
    props.modal.hide()
  } else {
    props.modal.show(modalsStore.getZIndex())
  }
}

const handleClose = () => {
  props.modal.conditionalClose()
}
</script>

<script lang="ts">
export default {
  name: 'AccountSlideover',
}
</script>

<style scoped>
@import url('./styles/asset.css');
</style>
