<template>
  <UIInputAutocomplete
    v-model="modelValue"
    :data-refid="dataRefid"
    :data
    :disabled
    :error
    :label
    :size
    :silent
    placeholder="Find an option"
    @ready="handleReady"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { orderBy } from 'lodash'

import { InputSize } from '@types'
import { AssetClass } from '..'

import { ASSET_FIELD } from '../utils/const'

import { useRepositoriesStore } from '@/store/repositories'

import { UIInputAutocomplete } from '@ui/fields'

type Props = {
  instance: AssetClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: InputSize
}

const props = defineProps<Props>()

const repositoriesStore = useRepositoriesStore()

const modelValue = props.instance.field<string>(ASSET_FIELD.CURRENCY)

const isSavedAsset = inject('isSavedAsset', ref(false))

const error = computed(() => props.instance.errors.currency)
const silent = computed(() => !isSavedAsset.value)

const data = computed(() =>
  orderBy(repositoriesStore.currenciesList, 'ticker').map(item => ({
    value: item.ticker,
    label: item.ticker,
  })),
)

const handleReady = (el: HTMLInputElement) => {
  if (!props.focusOnLoad) return
  el.focus()
}
</script>
