<template>
  <UIInputSelect v-model="criteriaComparison" :size :data />
  <template v-if="isFieldVisible">
    <UIInputAutocomplete
      v-if="mode === 'autocomplete'"
      v-model="criteriaValue"
      :data="typesList"
      :size
      @ready="el => el.focus()"
    />
    <UIInputText v-if="mode === 'input'" v-model="criteriaValue" :size />
  </template>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue'

import { FilterComparison, DropdownListData, TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'

import useFiltersService from '@/services/filters'
import { getComparisonData } from '@/components/UI/Filter/components/utils/helpers'

import { UIInputAutocomplete, UIInputSelect, UIInputText } from '@ui/fields'

const modelValue = defineModel<TransactionRule['data']['criteria'][number]>({
  required: true,
})

const { fetchOptions } = useFiltersService()

const typesList = ref<DropdownListData[]>([])

const criteriaComparison = computed({
  get() {
    return modelValue.value.comparison
  },
  set(value) {
    modelValue.value.comparison = value
  },
})

const criteriaValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value
  },
})

const data = computed(() =>
  getComparisonData().map(value => ({
    value,
    label: value,
  })),
)

const mode = computed(() => {
  if (
    criteriaComparison.value &&
    [FilterComparison.EQ, FilterComparison.NOTEQ].includes(
      criteriaComparison.value,
    )
  ) {
    return 'autocomplete'
  } else {
    return 'input'
  }
})

const isFieldVisible = computed(
  () =>
    criteriaComparison.value &&
    ![FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
      criteriaComparison.value,
    ),
)

watch(isFieldVisible, value => {
  criteriaValue.value = value ? '' : undefined
})

onBeforeMount(async () => {
  if (!criteriaComparison.value) {
    criteriaComparison.value = FilterComparison.EQ
  }
  typesList.value = await fetchOptions('LinkedDataTransactions', 'type')
})
</script>
