<template>
  <UIInputText
    v-model="modelValue"
    :data-refid="dataRefid"
    :label
    :disabled
    :error
    :silent
    :size
    autocomplete="off"
    @ready="onReady"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { TagClass } from '../'
import { InputSize } from '@types'

import { TAG_FIELD } from '../utils/const'

import { UIInputText } from '@ui/fields'

type Props = {
  instance: TagClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: InputSize
}

const props = defineProps<Props>()

const modelValue = props.instance.field<string>(TAG_FIELD.TAG_VALUE)

const isSavedTag = inject('isSavedTag', ref(false))

const error = computed(() => props.instance.errors.name)
const silent = computed(() => !isSavedTag.value)

const onReady = (el: HTMLInputElement) => {
  if (!props.focusOnLoad) return
  el.focus()
}
</script>
