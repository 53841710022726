import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { NativeBiometric } from 'capacitor-native-biometric'
import { Keyboard } from '@capacitor/keyboard'

import { injectCSS } from '@/helpers/common'

import config from '../../../capacitor.config'

export const useCapacitor = () => {
  if (!Capacitor.isNativePlatform()) return

  const server = `${config.server?.hostname}`

  const init = () => {
    if (Capacitor.getPlatform() === 'android') {
      injectCSS(`* {
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }`)
    }
    Keyboard.setScroll({ isDisabled: true })
  }

  const changeTheme = async (style: Style) => {
    await StatusBar.setStyle({ style })
  }

  const verifyBiometric = async () => {
    // const result = await NativeBiometric.isAvailable()
    // const isFaceID = result.biometryType === BiometryType.FACE_ID

    try {
      await NativeBiometric.verifyIdentity({
        reason: 'For easy log in',
        title: 'Log in',
        subtitle: 'Authenticate',
        description: 'Please authenticate to proceed',
        maxAttempts: 2,
        useFallback: true,
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const getCredentials = () => {
    try {
      return NativeBiometric.getCredentials({ server })
    } catch (error) {
      console.error(error)
    }
  }

  const setCredentials = (username: string, password: string) => {
    try {
      NativeBiometric.setCredentials({ username, password, server })
    } catch (error) {
      console.error(error)
    }
  }

  const deleteCredentials = () => {
    try {
      NativeBiometric.deleteCredentials({ server })
    } catch (error) {
      console.error(error)
    }
  }

  return {
    init,
    changeTheme,

    verifyBiometric,
    getCredentials,
    setCredentials,
    deleteCredentials,
  }
}
