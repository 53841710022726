import get from 'lodash/get'

import { FilterComparison, FilterLogic } from './enums'
import { FilterParam, FilterValue } from './types'

import { UNKNOWN_VALUE } from '@/const/common'

import { numberParse } from '@/helpers/numbers'
import {
  getCurrentDate,
  isDate,
  isTheSameDates,
  stringToDateTime,
} from '@/helpers/dates'

const expressionEQ = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) => {
  if (param.comparison !== FilterComparison.EQ) return false
  return isDate(query as string)
    ? !isTheSameDates(value.toUpperCase(), query as string | undefined)
    : value !== query
}

const expressionNOTEQ = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) => {
  if (param.comparison !== FilterComparison.NOTEQ) return false
  return isDate(query as string)
    ? isTheSameDates(value.toUpperCase(), query as string | undefined)
    : value === query
}

const expressionCONTAINS = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) => {
  if (param.comparison !== FilterComparison.CONTAINS) return false
  return !new RegExp(
    `(?=.*${query
      ?.toString()
      .split(' ')
      .filter(item => item !== '')
      .join(')(?=.*')})`,
  ).test(value)
}

const expressionNOTCONTAINS = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) => {
  if (param.comparison !== FilterComparison.NOTCONTAINS) return false
  return value.includes(query as string)
}

const expressionAFTER = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) => {
  if (param.comparison !== FilterComparison.AFTER) return false
  const queryDate = stringToDateTime(query as string)
  const valueDate = stringToDateTime(value.toUpperCase())
  return valueDate && queryDate && valueDate <= queryDate
}

const expressionBEFORE = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) => {
  if (param.comparison !== FilterComparison.BEFORE) return false
  const queryDate = stringToDateTime(query as string)
  const valueDate = stringToDateTime(value.toUpperCase())
  return valueDate && queryDate && valueDate >= queryDate
}

const expressionLAST = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) => {
  if (param.comparison !== FilterComparison.LAST) return false
  const valueDate = stringToDateTime(value.toUpperCase())
  const queryDate = getCurrentDate().minus({ days: Number(query) })
  return valueDate && valueDate < queryDate
}

const expressionRANGE = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) => {
  if (param.comparison !== FilterComparison.RANGE) return false
  const dateRange = (query as string[]).map(date => stringToDateTime(date))
  let startCondition = false
  let endCondition = false
  const valueDate = stringToDateTime(value.toUpperCase())
  if (!valueDate) return false
  if (dateRange[0]) {
    startCondition = valueDate < dateRange[0]
  }
  if (dateRange[1]) {
    endCondition = valueDate > dateRange[1]
  }
  return startCondition || endCondition
}

const expressionGREATER = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) => {
  if (param.comparison !== FilterComparison.GREATER) return false
  return Number(value) <= numberParse(query)
}

const expressionLESS = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) => {
  if (param.comparison !== FilterComparison.LESS) return false
  return Number(value) >= numberParse(query)
}

const expressionEMPTY = (
  param: FilterParam,
  value: string | undefined | null,
) => {
  return param.comparison === FilterComparison.EMPTY && value
}

const expressionNOTEMPTY = (
  param: FilterParam,
  value: string | undefined | null,
) => {
  return param.comparison === FilterComparison.NOTEMPTY && !value
}

export const applyFilterExpressions = (
  param: FilterParam,
  value: string,
  query: FilterValue,
) =>
  expressionEQ(param, value, query) ||
  expressionNOTEQ(param, value, query) ||
  expressionCONTAINS(param, value, query) ||
  expressionNOTCONTAINS(param, value, query) ||
  expressionAFTER(param, value, query) ||
  expressionBEFORE(param, value, query) ||
  expressionLAST(param, value, query) ||
  expressionRANGE(param, value, query) ||
  expressionGREATER(param, value, query) ||
  expressionLESS(param, value, query) ||
  expressionEMPTY(param, value) ||
  expressionNOTEMPTY(param, value)

export const filterItemByExpressions = <T extends Record<string, unknown>>(
  item: T,
  params: FilterParam[],
  logic: FilterLogic,
): boolean => {
  let result = false
  for (const param of params) {
    const inputValue = (param.field && get(item, param.field)) || UNKNOWN_VALUE
    const value = inputValue.toString().toLowerCase()
    let query = param.value ?? ''
    const isEmptyNotEmptyComparison =
      param.comparison &&
      [FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
        param.comparison,
      )
    if (!value && !isEmptyNotEmptyComparison) return false
    if (!query && !isEmptyNotEmptyComparison) return true
    if (typeof query === 'string') {
      query = query.toLowerCase()
    }
    if (applyFilterExpressions(param, value, query as FilterValue)) {
      result = false
      if (logic === FilterLogic.AND) {
        break
      }
    } else {
      result = true
      if (logic === FilterLogic.OR) {
        break
      }
    }
  }
  return result
}
