import { sortBy } from 'lodash'
import { TransactionSettings } from '@types'
import { DELIMETER_KEYWORD } from '@/components/UI/Dropdown/utils/const'

export const getTransactionsTypes = (settings: TransactionSettings[]) => {
  const sortedTypes = sortBy(settings, ['group', 'name'])
  let group = 1
  return sortedTypes.reduce((acc: any[], item: TransactionSettings) => {
    if (item.group <= 0) return acc
    if (item.group !== group) {
      acc.push({ value: DELIMETER_KEYWORD })
      group = item.group
    }
    acc.push({ value: item.name, readonly: item.readonly })
    return acc
  }, [])
}
