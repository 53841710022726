<template>
  <UIInputDate
    ref="inputRef"
    v-model="modelValue"
    v-bind="{ container, size }"
    inline-popover
  />
</template>

<script setup lang="ts">
import { inject, useTemplateRef } from 'vue'

import { InputSize } from '@types'

import { UIInputDate } from '@ui/fields'

const modelValue = defineModel<any>()

defineExpose({
  start() {
    inputRef.value?.select()
  },
})

const container = inject<string>('container')
const size = inject<InputSize>('size')

const inputRef = useTemplateRef('inputRef')
</script>
