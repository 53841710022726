<template>
  <UIInputText
    v-model="modelValue"
    :data-refid="dataRefid"
    :label
    :disabled
    :error
    :silent
    :size
    :readonly
    @ready="onReady"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { AssetClass } from '..'
import { InputSize } from '@types'

import { ASSET_CURRENCY_TYPE, ASSET_FIELD } from '../utils/const'

import { UIInputText } from '@ui/fields'

type Props = {
  instance: AssetClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: InputSize
}

const props = defineProps<Props>()

const modelValue = props.instance.field<string>(ASSET_FIELD.TICKER)

const isSavedAsset = inject('isSavedAsset', ref(false))

const readonly = computed(
  () => props.instance.field(ASSET_FIELD.TYPE).value === ASSET_CURRENCY_TYPE,
)
const error = computed(() => props.instance.errors.ticker)
const silent = computed(() => !isSavedAsset.value)

const onReady = (el: HTMLInputElement) => {
  if (!props.focusOnLoad) return
  el.focus()
}
</script>
