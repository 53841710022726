<template>
  <div class="asset__fields">
    <div v-if="!isSavedAsset">
      <UIInputAutocomplete
        v-model="currency"
        :readonly
        :disabled="loading"
        :data="currenciesList"
        data-refid="currencyFormAvailableCurrencies"
        label="Available currencies"
        placeholder="Find an option"
        @ready="(el: HTMLInputElement) => el.focus()"
      />
    </div>
    <template v-else>
      <component
        :is="instance.getFormName()"
        v-bind="{
          dataRefid: 'currencyFormName',
          focusOnLoad: focusNameOnLoad,
          instance,
          label: ASSET_FIELD_LABEL[ASSET_FIELD.NAME],
          readonly,
        }"
      />
      <component
        :is="instance.getFormTicker()"
        v-bind="{
          dataRefid: 'currencyFormTicker',
          focusOnLoad: focusTickerOnLoad,
          instance,
          label: ASSET_FIELD_LABEL[ASSET_FIELD.TICKER],
          readonly,
        }"
      />
    </template>
    <div class="asset__buttons">
      <UIButtonClose @click="handleClose" />
      <UIButtonSave
        v-if="!readonly"
        ref="buttonSaveRef"
        data-refid="currencyFormSave"
        v-bind="{
          disabled,
          loading,
        }"
        @click="handleSave"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  inject,
  markRaw,
  nextTick,
  onMounted,
  provide,
  ref,
  useTemplateRef,
  watch,
} from 'vue'
import { orderBy } from 'lodash'

import { ReadonlyMode } from '@types'
import { AssetClass } from '..'
import { EntityState } from '@/entities/utils/enums'

import { ASSET_FIELD, ASSET_FIELD_LABEL, ASSET_ID_PREFIX } from '../utils/const'
import { ROUTE_NAME } from '@/const'
import { NOTIFICATION_DELAY, READONLY_MODE } from '@/const/common'

import { handleCatchedError } from '@/helpers/common'
import { SoftDeletedError } from '@/store/utils/errors'

import { useModalsStore } from '@/store/modals'
import { useRepositoriesStore } from '@/store/repositories'
import { NotificationAction, useNotifications } from '@/plugins/notification'

import { UIButtonClose, UIButtonSave } from '@ui/buttons'
import { UIInputAutocomplete } from '@ui/fields'
import CurrenciesTrashPopup from '@/views/Data/Currencies/components/CurrenciesTrashPopup.vue'

type Props = {
  instance: AssetClass
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()
const { error, progress, remove, update } = useNotifications()

const loading = ref(false)
const currency = ref<string>()

const buttonSaveRef = useTemplateRef('buttonSaveRef')
provide('asset-save-focus', async () => {
  await nextTick()
  buttonSaveRef.value?.focus()
})

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const currenciesList = computed(() =>
  orderBy(repositoriesStore.getCurrenciesList, 'name').map(item => ({
    value: item.ticker,
    label: item.name,
  })),
)

const disabled = computed(
  () =>
    !isValidSelectedCurrency.value ||
    ![EntityState.CHANGED, EntityState.NEW].includes(props.instance.state),
)

const isSavedAsset = computed(() =>
  props.instance.id.startsWith(ASSET_ID_PREFIX),
)
provide('isSavedAsset', isSavedAsset)

const selectedCurrency = computed(() => {
  return currenciesList.value.find(item => item.value === currency.value)
})
const isValidSelectedCurrency = computed(
  () => isSavedAsset.value || !!selectedCurrency.value,
)

const focusNameOnLoad = computed(
  () => !isSavedAsset.value && !props.instance.field(ASSET_FIELD.NAME).value,
)
const focusTickerOnLoad = computed(
  () =>
    !focusNameOnLoad.value && !props.instance.field(ASSET_FIELD.TICKER).value,
)

const openDeletedModal = () => {
  const modalInstance = modalsStore.init(
    'deleted-currencies',
    markRaw(CurrenciesTrashPopup),
  )
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClose = () => {
  emit('close')
}

const handleSave = async () => {
  loading.value = true
  const isNew = props.instance.isNew
  const nid = await progress({
    message: `${isNew ? 'Creating' : 'Updating'} currency`,
  })
  try {
    if (isNew) {
      await props.instance.store()
    } else {
      await props.instance.update()
    }
    await update(
      nid,
      {
        type: 'success',
        message: `Currency ${props.instance.field(ASSET_FIELD.NAME).value} ${
          isNew ? 'created' : 'updated'
        }`,
        link: {
          text: 'Open',
          to: {
            name: ROUTE_NAME.CURRENCIES_ITEM,
            params: { id: props.instance.id },
          },
        },
      },
      NOTIFICATION_DELAY,
    )
    emit('close')
  } catch (e) {
    await remove(nid)
    if (e instanceof SoftDeletedError) {
      const actions: NotificationAction[] = [
        {
          label: 'Go to deleted currencies',
          buttonVariant: 'primary',
          onClick: remove => {
            remove && remove()
            openDeletedModal()
          },
        },
        {
          label: 'Close',
          buttonVariant: 'gray',
          buttonFill: 'outlined',
          onClick: remove => {
            remove && remove()
          },
        },
      ]

      await error({ message: e.message, actions })
      return
    }
    handleCatchedError(e as string, props.instance.get())
  } finally {
    loading.value = false
  }
}

watch(currency, value => {
  if (!value) return
  if (selectedCurrency.value) {
    props.instance.set({
      name: selectedCurrency.value.label,
      ticker: selectedCurrency.value.value as string,
    })
  } else {
    props.instance.set({
      name: '',
      ticker: '',
    })
  }
})

onMounted(() => {
  if (isSavedAsset.value) return
  const ticker = props.instance.field<string>(ASSET_FIELD.TICKER).value
  if (ticker) {
    currency.value = ticker
  }
})
</script>

<style scoped>
@import url('./styles/asset.css');
</style>
