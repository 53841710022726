<template>
  <UIInputDynamicList
    v-model="modelValue"
    :data
    editable
    removable
    placeholder="Find or create new"
    @click:item="handleSelectTag"
    @click:new="handleCreateTag"
    @update="handleUpdateTag"
    @delete="handleDeleteTag"
  />
</template>

<script setup lang="ts">
import { computed, nextTick } from 'vue'
import { orderBy } from 'lodash'

import { CommonTag } from '@types'

import { useNotifications } from '@/plugins/notification'
import { useDocumentsTagsStore } from '@/store/documents/tags'

import { NOTIFICATION_DELAY } from '@/const/common'
import { handleCatchedError } from '@/helpers/common'

import { UIInputDynamicList } from '@ui/fields'

type props = {
  selected?: string[]
}

const { selected } = defineProps<props>()

type Emits = {
  select: [data: CommonTag]
  update: [id: string, name: string]
}
const emit = defineEmits<Emits>()

const modelValue = defineModel<string>()

const { progress, remove, update } = useNotifications()
const documentsTagsStore = useDocumentsTagsStore()

const data = computed(() => {
  const tags = documentsTagsStore.getList.filter(
    tag => !selected?.find(id => id === tag.id),
  )
  return orderBy(
    tags.map(tag => ({ value: tag.id, label: tag.name })),
    'label',
  )
})

const handleSelectTag = (value: string) => {
  const data = documentsTagsStore.getList.find(tag => tag.id === value)
  if (!data) return
  emit('select', data)
}

const handleCreateTag = async (tag: string, callback: () => void) => {
  const nid = await progress({
    message: 'Creating tag',
  })
  try {
    const result = await documentsTagsStore.store(tag)
    if (!result?.id) throw Error('Unknown tag')
    nextTick(() => {
      modelValue.value = result.id
    })
    await update(
      nid,
      {
        type: 'success',
        message: `Tag "${result.name}" created`,
      },
      NOTIFICATION_DELAY,
    )
    emit('select', result)
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string, { tag })
  } finally {
    callback()
  }
}

const handleUpdateTag = async (id: string, name: string) => {
  emit('update', id, name)
}

const handleDeleteTag = async (id: string, callback: () => void) => {
  const nid = await progress({
    message: 'Deleting tag',
  })
  try {
    await documentsTagsStore.destroy(id)
    if (nid) {
      await update(
        nid,
        {
          type: 'success',
          message: 'Tag deleted',
        },
        NOTIFICATION_DELAY,
      )
    }
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string, { id })
  } finally {
    callback()
  }
}
</script>
