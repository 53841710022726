import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { AxiosResponse } from 'axios'

import {
  Asset,
  AssetPost,
  AssetPut,
  AssetPrice,
  AssetPricePost,
  AssetPricePut,
  CommonListResponse,
  AssetSecurityMaster,
  AssetCommonPrice,
  AssetSecurityMasterPrice,
} from '@types'

import { NOTIFICATION_DELAY } from '@/const/common'
import { ROUTE_NAME } from '@/const'

import { stringToDateTime, stringToLocalDateString } from '@/helpers/dates'
import { processingSortedArray } from '@/helpers/common'
import { isItCommotType } from './assets/utils/helpers'

import { NotificationInstance } from '@/plugins/notification/'
import { useNotifications } from '@/plugins/notification'

import api from '@/store/api'
import useAnalyticsStore from '@/store/analytics'
import { useLinkedDataStore } from '@/store/linkedData'
import { useRepositoriesStore } from '@/store/repositories'

type AssetsApiResponse = CommonListResponse<Asset[]>
type AssetPricesApiResponse = CommonListResponse<AssetCommonPrice[]>

export const useAssetsStore = defineStore('assets', () => {
  const repositoriesStore = useRepositoriesStore()
  const analyticsStore = useAnalyticsStore()
  const linkedDataStore = useLinkedDataStore()

  const { progress, remove, update } = useNotifications()

  const list = ref<Asset[]>([])
  const listTypes = ref<string[]>([])
  const listPrices = ref<AssetCommonPrice[]>([])

  const listAssetSecurityMaster = ref<AssetSecurityMaster[]>([])
  const listAssetSecurityMasterPrices = ref<AssetSecurityMasterPrice[]>([])

  const initFlag = ref<boolean>(false)
  const initPricesFlag = ref<boolean>(false)
  const initDocumentsFlag = ref<boolean>(false)
  const initContactsAssetId = ref<string>()
  const hasDataFlag = computed<boolean>(
    () => initFlag.value && !!list.value.length,
  )
  const loadingList = ref<boolean>(true)
  const loadingAction = ref<boolean>(false)
  const loadingPrices = ref<boolean>(true)
  const loadingPricesAction = ref<boolean>(false)
  const loadingDocuments = ref<boolean>(true)
  const loadingDocumentsAction = ref<boolean>(false)

  const loadingSecurityMasterPrices = ref<boolean>(false)

  let abortController = new AbortController()

  const getAssetsTypes = computed(() => listTypes.value?.filter(isItCommotType))

  const addAddiotionCreatedCurrency = (currency?: Asset | null) => {
    if (!currency) return
    processingSortedArray<Asset>(
      list.value,
      currency,
      'insert',
      { name: currency.name },
      'name',
    )
  }

  const fetchAssets = async (): Promise<void> => {
    const rid = repositoriesStore.currentRepositoryId
    if (!rid) return
    loadingList.value = true
    try {
      const result = await api.get<AssetsApiResponse>('assets', {
        signal: abortController.signal,
        params: {
          repository_id: rid,
        },
      })
      list.value = result.data.data
      initFlag.value = true
    } finally {
      loadingList.value = false
    }
  }

  const fetchAsset = async (assetId: string): Promise<Asset> => {
    const result = await api.get<Asset>(`assets/${assetId}`, {
      signal: abortController.signal,
    })
    return result.data
  }
  const createAsset = async (
    asset: AssetPost,
    showNotify = true,
    refreshList = true,
    showLink = true,
  ): Promise<Asset | undefined> => {
    loadingAction.value = true
    let nid: NotificationInstance | undefined
    if (showNotify) {
      nid = await progress({
        message: 'Creating asset',
      })
    }
    try {
      const result = await api.post<Asset, AxiosResponse<Asset>, AssetPost>(
        'assets',
        asset,
      )
      if (refreshList) {
        processingSortedArray<Asset>(
          list.value,
          result.data,
          'insert',
          { name: result.data.name },
          'name',
        )
        addAddiotionCreatedCurrency(result.data.currency_asset)
      }
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: `Asset ${result.data.name} created`,
            ...(showLink
              ? {
                  link: {
                    text: 'Open',
                    to: {
                      name: ROUTE_NAME.ASSETS_ITEM,
                      params: { id: result.data.id },
                    },
                  },
                }
              : {}),
          },
          NOTIFICATION_DELAY,
        )
      }
      return result.data
    } catch (e) {
      if (nid) await remove(nid)
      throw e
    } finally {
      loadingAction.value = false
    }
  }
  const updateAsset = async (
    asset: AssetPut,
    showNotify = true,
    refreshList = true,
    showLink = true,
  ): Promise<Asset | undefined> => {
    loadingAction.value = true
    let nid: NotificationInstance | undefined
    if (showNotify) {
      nid = await progress({
        message: 'Updating asset',
      })
    }
    try {
      const result = await api.put<Asset, AxiosResponse<Asset>, AssetPut>(
        `assets/${asset.id}`,
        asset,
      )
      if (refreshList) {
        processingSortedArray<Asset>(
          list.value,
          result.data,
          'update',
          { name: result.data.name },
          'name',
        )
        addAddiotionCreatedCurrency(result.data.currency_asset)
      }
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: `Asset ${result.data.name} updated`,
            ...(showLink
              ? {
                  link: {
                    text: 'Edit',
                    to: {
                      name: ROUTE_NAME.ASSETS_ITEM,
                      params: { id: result.data.id },
                    },
                  },
                }
              : {}),
          },
          NOTIFICATION_DELAY,
        )
      }
      return result.data
    } catch (e) {
      if (nid) await remove(nid)
      throw e
    } finally {
      loadingAction.value = false
    }
  }

  const deleteAsset = async (
    id: string,
    showNotify = true,
    refreshList = true,
  ): Promise<void> => {
    loadingAction.value = true
    let nid: NotificationInstance | undefined
    if (showNotify) {
      nid = await progress({
        message: 'Deleting asset',
      })
    }
    try {
      await api.delete(`assets/${id}`)
      if (refreshList) {
        processingSortedArray<Asset>(list.value, { id }, 'delete')
      }
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: 'Asset deleted',
          },
          NOTIFICATION_DELAY,
        )
      }
    } catch (e) {
      if (nid) await remove(nid)
      throw e
    } finally {
      loadingAction.value = false
    }
    linkedDataStore.fetch()
  }

  const bulkCreateAssets = async (
    assets: AssetPost[],
    showNotify = true,
  ): Promise<Asset[] | undefined> => {
    const rid = repositoriesStore.currentRepositoryId
    if (!rid) return
    loadingAction.value = true
    let nid: NotificationInstance | undefined
    if (showNotify) {
      nid = await progress({
        message: 'Creating assets',
      })
    }
    try {
      const result = await api.post<Asset, AxiosResponse<Asset[]>, AssetPost[]>(
        `assets?repository_id=${rid}`,
        assets,
      )
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: 'Assets created',
          },
          NOTIFICATION_DELAY,
        )
      }
      return result.data
    } catch (e) {
      if (nid) await remove(nid)
      throw e
    } finally {
      loadingAction.value = false
    }
  }

  const bulkDeleteAssets = async (
    ids: string[],
    setAnalyticsFlag = true,
    showNotify = true,
    refreshList = true,
  ): Promise<void> => {
    loadingAction.value = true
    let nid
    if (showNotify) {
      nid = await progress({
        message: 'Deleting assets',
      })
    }
    try {
      await api.delete('assets', { data: ids })
      if (refreshList) {
        ids.forEach(id => {
          processingSortedArray<Asset>(list.value, { id }, 'delete')
        })
      }
      if (setAnalyticsFlag) {
        analyticsStore.markAsDeprecated()
      }
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: 'Assets deleted',
          },
          NOTIFICATION_DELAY,
        )
      }
    } catch (e) {
      if (nid) await remove(nid)
      throw e
    } finally {
      loadingAction.value = false
    }
    linkedDataStore.fetch()
  }

  const fetchAssetsTypes = async (): Promise<void> => {
    const result = await api.get<string[]>('asset_types', {
      signal: abortController.signal,
    })
    listTypes.value = result.data
  }
  const fetchAssetPrices = async (aid: string): Promise<AssetCommonPrice[]> => {
    listPrices.value = []
    loadingPrices.value = true
    try {
      const result = await api.get<AssetPricesApiResponse>(
        `asset_prices?asset_id=${aid}`,
        {
          signal: abortController.signal,
        },
      )
      initPricesFlag.value = true
      listPrices.value = result.data.data
      return listPrices.value
    } finally {
      loadingPrices.value = false
    }
  }
  const addAssetPrice = async (
    price: AssetPricePost,
    showNotify = true,
  ): Promise<AssetPrice | void> => {
    loadingPricesAction.value = true
    let nid: NotificationInstance | undefined
    if (showNotify) {
      nid = await progress({
        message: 'Creating asset price',
      })
    }
    try {
      const result = await api.post<
        AssetPrice,
        AxiosResponse<AssetPrice>,
        AssetPricePost
      >('asset_prices', price)
      processingSortedArray<AssetPrice>(
        listPrices.value,
        result.data,
        'insert',
        { date: result.data.date },
        value => {
          return -(stringToDateTime(value.date)?.toMillis() || 0)
        },
      )
      analyticsStore.markAsDeprecated()
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: `${stringToLocalDateString(
              result.data.date,
            )} asset price created`,
          },
          NOTIFICATION_DELAY,
        )
      }
      return result.data
    } catch (e) {
      if (nid) await remove(nid)
      throw e
    } finally {
      loadingPricesAction.value = false
    }
  }
  const updateAssetPrice = async (
    price: AssetPricePut,
    showNotify = true,
  ): Promise<AssetPrice | void> => {
    loadingPricesAction.value = true
    let nid: NotificationInstance | undefined
    if (showNotify) {
      nid = await progress({
        message: 'Updating asset price',
      })
    }
    try {
      const result = await api.put<
        AssetPrice,
        AxiosResponse<AssetPrice>,
        AssetPricePut
      >(`asset_prices/${price.id}`, price)
      processingSortedArray<AssetPrice>(
        listPrices.value,
        result.data,
        'update',
        { date: result.data.date },
        value => {
          return -(stringToDateTime(value.date)?.toMillis() || 0)
        },
      )
      analyticsStore.markAsDeprecated()
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: `${stringToLocalDateString(
              result.data.date,
            )} asset price updated`,
          },
          NOTIFICATION_DELAY,
        )
      }
      return result.data
    } catch (e) {
      if (nid) await remove(nid)
      throw e
    } finally {
      loadingPricesAction.value = false
    }
  }
  const deleteAssetPrice = async (
    id: number,
    showNotify = true,
  ): Promise<void> => {
    loadingPricesAction.value = true
    let nid: NotificationInstance | undefined
    if (showNotify) {
      nid = await progress({
        message: 'Deleting asset price',
      })
    }
    try {
      await api.delete(`asset_prices/${id}`)
      processingSortedArray<AssetPrice>(listPrices.value, { id }, 'delete')
      analyticsStore.markAsDeprecated()
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: 'Asset price deleted',
          },
          NOTIFICATION_DELAY,
        )
      }
    } catch (e) {
      if (nid) await remove(nid)
      throw e
    } finally {
      loadingPricesAction.value = false
    }
  }

  const fetchAssetSecurityMasterPrices = async (
    linkedSecurityId: string,
  ): Promise<AssetSecurityMasterPrice[]> => {
    listAssetSecurityMasterPrices.value = []
    loadingSecurityMasterPrices.value = true
    try {
      const result = await api.get<AssetSecurityMasterPrice[]>(
        'sec_master/daily_prices',
        {
          params: {
            from_dt: '2010-01-01',
            repo_id: repositoriesStore.currentRepositoryId,
            tickers: linkedSecurityId,
          },
          signal: abortController.signal,
        },
      )
      listAssetSecurityMasterPrices.value = result.data
      return result.data
    } finally {
      loadingSecurityMasterPrices.value = false
    }
  }
  const cancelFetch = () => {
    abortController.abort()
    abortController = new AbortController()
  }
  const clearAsset = () => {
    initPricesFlag.value = false
    initDocumentsFlag.value = false
    listAssetSecurityMaster.value = []
    listAssetSecurityMasterPrices.value = []
  }
  const clearAssets = () => {
    initFlag.value = false
    list.value = []
    clearAsset()
  }

  return {
    list,
    listTypes,
    listAssetSecurityMaster,
    initFlag,
    initPricesFlag,
    initDocumentsFlag,
    initContactsAssetId,
    hasDataFlag,
    loadingList,
    loadingAction,
    loadingPrices,
    loadingPricesAction,
    loadingDocuments,
    loadingDocumentsAction,
    loadingSecurityMasterPrices,
    getAssetsTypes,
    addAddiotionCreatedCurrency,
    fetchAssets,
    fetchAsset,
    createAsset,
    updateAsset,
    deleteAsset,
    bulkCreateAssets,
    bulkDeleteAssets,
    fetchAssetsTypes,
    fetchAssetPrices,
    addAssetPrice,
    deleteAssetPrice,
    updateAssetPrice,
    fetchAssetSecurityMasterPrices,
    cancelFetch,
    clearAsset,
    clearAssets,
  }
})
