<template>
  <UIInputText v-model="modelValue" v-bind="{ size }" @ready="handleReady" />
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'

import { InputSize } from '@types'

import { UIInputText } from '@ui/fields'

const modelValue = defineModel<any>()

const size = inject<InputSize>('size')

defineExpose({
  start() {
    inputRef.value?.select()
  },
})

const inputRef = ref<HTMLInputElement>()

const handleReady = (el: HTMLInputElement) => {
  inputRef.value = el
}
</script>
