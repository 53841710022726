import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import orderBy from 'lodash/orderBy'

import {
  LinkedDataAccount,
  LinkedDataConnectorItemEvents,
  LinkedDataConnectorItemLink,
  LinkedDataManualLoader,
} from '@types'

import { prepareResponseError } from '../utils/helpers'

import api from '@/store/api'
import { useRepositoriesStore } from '@/store/repositories'

export const useLinkedDataStore = defineStore('linked-data', () => {
  // INIT
  const repositoriesStore = useRepositoriesStore()

  const list = ref<LinkedDataAccount[]>([])

  const initFlag = ref(false)
  const loading = ref(true)

  let abortController = new AbortController()

  const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

  // GETTERS
  const getList = computed(() =>
    orderBy(list.value, item => item.account_name.toLowerCase(), 'asc'),
  )

  // SETTERS
  const setList = (data?: LinkedDataAccount[]) => {
    list.value = data || []
  }

  // ACTIONS
  const fetch = async (): Promise<void> => {
    loading.value = true
    try {
      const result = await api.get<LinkedDataAccount[]>(
        'linked_data/accounts',
        {
          params: { repository_id: repositoryId.value, show_deleted: true },
          signal: abortController.signal,
        },
      )
      initFlag.value = true
      setList(result?.data)
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loading.value = false
    }
  }

  const fetchEvents = async (
    id: string,
  ): Promise<LinkedDataConnectorItemEvents> => {
    try {
      const result = await api.get(`linked_data/accounts/${id}/sync_events`, {
        params: { repository_id: repositoryId.value },
        signal: abortController.signal,
      })
      return result.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const getAccount = async (id: string): Promise<LinkedDataAccount> => {
    try {
      const result = await api.get(`linked_data/accounts/${id}`, {
        params: { repository_id: repositoryId.value },
        signal: abortController.signal,
      })
      return result.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const getAccess = async (): Promise<{ token: string; url: string }> => {
    const { data } = await api.get('linked_data/access_token', {
      params: { repository_id: repositoryId.value },
      signal: abortController.signal,
    })
    return {
      token: data.token,
      url: data.url,
    }
  }

  const store = async (file_key: string, loader: LinkedDataManualLoader) => {
    try {
      const response = await api.post('linked_data/connectors/manual', {
        repository_id: repositoryId.value,
        file_key,
        loader,
      })
      return response.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const remap = async (mappings: LinkedDataConnectorItemLink[]) => {
    try {
      const response = await api.put('linked_data/accounts_link', {
        mappings,
        repository_id: repositoryId.value,
      })
      return response.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const callback = async (
    code: string,
    providerName: string,
    connectorName?: string,
  ) => {
    const params = [`code=${code}`]
    if (connectorName) {
      params.push(`connector=${connectorName}`)
    }
    try {
      const query = `${params.join('&')}${params.length > 0 ? '&' : ''}`
      const response = await api.get(
        `linked_data/callback/${providerName}?${query}repository_id=${repositoryId.value}`,
      )
      return response.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const sync = async () => {
    try {
      const response = await api.put('linked_data/accounts', null, {
        params: { repository_id: repositoryId.value },
      })
      return response.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const resync = async (connectorId: string) => {
    try {
      const response = await api.put(
        `linked_data/connectors/${connectorId}`,
        null,
        {
          params: { repository_id: repositoryId.value },
        },
      )
      return response.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const yodlee = async () => {
    try {
      const response = await api.put(
        'linked_data/accounts/repository_link',
        null,
        {
          params: {
            repository_id: repositoryId.value,
            access_method_type: 'yodlee',
          },
        },
      )
      return response.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const plaid = async (token: string) => {
    try {
      const response = await api.get('linked_data/callback/plaid', {
        params: { code: token, repository_id: repositoryId.value },
      })
      return response.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const arch = async (clientId: string, clientSecret: string) => {
    try {
      const response = await api.post('linked_data/arch_sync', {
        repository_id: repositoryId.value,
        client_id: clientId,
        client_secret: clientSecret,
      })
      return response.data?.task_id
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const cancel = () => {
    abortController.abort()
    abortController = new AbortController()
  }

  const clear = () => {
    initFlag.value = false

    loading.value = false

    list.value = []
  }

  return {
    initFlag,
    loading,

    getList,

    fetch,
    fetchEvents,

    getAccount,
    getAccess,

    store,
    remap,
    callback,
    sync,
    resync,
    yodlee,
    plaid,
    arch,

    cancel,
    clear,
  }
})
