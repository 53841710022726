<template>
  <UIInputText v-model="actionValue" :size />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'

import { UIInputText } from '@ui/fields'

const modelValue = defineModel<TransactionRule['data']['actions'][number]>({
  required: true,
})

const actionValue = computed({
  get() {
    return modelValue.value.value || ''
  },
  set(value) {
    modelValue.value.value = value || ''
  },
})

defineOptions({
  name: 'LinkedDataRulesActionCounterparty',
})
</script>
