import { Component, computed } from 'vue'
import * as yup from 'yup'
import { get, isEqual, set } from 'lodash'

import { EntityErrors, EntityEventCallback } from './types'
import { EntityEvent, EntityState } from './enums'

import { Rule } from '@/helpers/validate'
import { addEventListener, callEvent, removeEventListener } from './helpers'

import {
  NotificationInstance,
  NotificationsInstance,
  useNotifications,
} from '@/plugins/notification'

export abstract class Entity<T extends { id: string }> {
  private currentData!: T
  private originalData!: T

  protected cachedData: Record<string, any> = {}

  private validationErrors: EntityErrors = {}

  private created = false
  private deleted = false

  protected events: { [key in EntityEvent]?: EntityEventCallback[] } = {}

  protected notify?: NotificationsInstance = undefined
  protected notifyInstance?: NotificationInstance = undefined

  isDialog = false

  constructor(data: Partial<T>, defaultValue: T, created: boolean) {
    this.created = created
    const result = {
      ...defaultValue,
      ...data,
    }
    this.data = result
    this.original = result

    this.notify = useNotifications()

    this.validateData()
  }

  get() {
    return this.data
  }

  set(data: Partial<T>) {
    Object.keys(data).forEach(value => {
      const key = value as keyof T
      if (!this.data) {
        this.data = {} as T
      }
      this.data[key] = data[key] as T[keyof T]
    })
    this.validateData()
  }

  get data() {
    return this.currentData
  }

  set data(value: T) {
    this.currentData = JSON.parse(JSON.stringify(value))
  }

  get original() {
    return this.originalData
  }

  set original(value: T) {
    this.originalData = JSON.parse(JSON.stringify(value))
  }

  field<K>(path: keyof T) {
    return computed({
      get: () => {
        return get(this.data, path) as K
      },
      set: value => {
        if (!this.data) {
          this.data = {} as T
        }
        set(this.data, path, value)
        this.validateData()
      },
    })
  }

  forceUpdate(path: keyof T, value: any) {
    set(this.data, path, value)
    set(this.original, path, value)
  }

  get isNew() {
    return this.created
  }

  get isChanged() {
    return !isEqual(this.data, this.original)
  }

  get isValid() {
    return !Object.values(this.errors).length
  }

  get id() {
    return this.data.id
  }

  get errors() {
    return this.handleErrors
      ? this.handleErrors(this.validationErrors)
      : this.validationErrors
  }

  get state() {
    if (this.deleted) {
      return EntityState.DELETED
    } else if (!this.isValid) {
      return EntityState.INVALID
    } else if (this.isNew) {
      return EntityState.NEW
    } else if (this.isChanged) {
      return EntityState.CHANGED
    } else {
      return EntityState.SAVED
    }
  }

  protected callEvent(name: EntityEvent) {
    callEvent(this.events, name, this.data)
  }

  addEventListener(name: EntityEvent, callback: EntityEventCallback) {
    addEventListener(this.events, name, callback)
  }

  removeEventListener(name: EntityEvent, callback: EntityEventCallback) {
    removeEventListener(this.events, name, callback)
  }

  cancel() {
    this.data = this.original
    this.unmarkDelete()
    this.validateData()
  }

  remove() {}

  protected reset(data: T) {
    this.data = data
    this.original = data
    this.created = false
    this.unmarkDelete()
  }

  markDelete() {
    this.deleted = true
  }

  unmarkDelete() {
    this.deleted = false
  }

  abstract store(): Promise<void>
  abstract update(): Promise<void>
  abstract destroy(): Promise<void>

  validateData() {
    this.validationErrors = {}
    const rules = this.validate()
    yup
      .object()
      .shape(rules)
      .validate(this.data, { abortEarly: false })
      .catch(result => {
        result.inner.forEach((error: { path: string; message: string }) => {
          this.validationErrors[error.path] = error.message
        })
      })
  }

  get isDisabled() {
    return false
  }

  get isReadonly() {
    return false
  }

  abstract validate(): Record<string, Rule>
  abstract handleErrors(errors: EntityErrors): EntityErrors

  abstract getModal(options?: any): Component
}
