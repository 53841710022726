<template>
  <div v-circular-tab class="asset-drawer__form">
    <UIInputSelect
      v-model="priceSource"
      :data="ASSET_PRICES_LIST"
      size="sm"
      class="asset-drawer__form-input"
    />
    <UIButton
      v-if="isSwitchVisible"
      data-refid="assetPricesSwitchView"
      v-bind="{
        disabled: isLoading,
        leading: switchIcon,
      }"
      :label="switchLabel"
      variant="secondary"
      fill="light"
      size="sm"
      hide-label-to-sm
      @click="handleClickSwitch"
    />
    <UIButton
      v-if="isNewPriceVisible"
      data-refid="assetPricesShowForm"
      v-bind="{
        disabled: isLoading,
        leading: newPriceIcon,
        label: newPriceLabel,
      }"
      variant="secondary"
      size="sm"
      hide-label-to-sm
      @click="handleClickNewPrice"
    />
  </div>
  <AssetPricesForm
    v-if="isFormShown"
    v-bind="{
      disabled: isLoading,
      values: currentPrice,
    }"
    class="asset-drawer__form asset-drawer__form--second"
    @submit="handleAddPrice"
  />
  <AssetPricesTable
    v-if="currentView === 'Table'"
    ref="assetPricesTableRef"
    v-model="currentPrice"
    v-model:creating="isCreatingMode"
    :input-items
    :instance
    :loading
  />
  <template v-else>
    <UIPreloader
      v-if="isLoading"
      message="Loading prices..."
      class="asset-drawer-prices__preloader"
    />
    <AssetPricesChart
      v-else
      :instance
      :price-source
      :items="inputItems"
      class="asset-drawer-prices__chart"
    />
  </template>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeMount, ref, useTemplateRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { orderBy } from 'lodash'

import { AssetClass } from '..'
import { AssetPrice } from '../utils/types'
import { AssetPriceForm } from './utils/types'
import { AssetPriceSource } from './utils/enums'
import { ReadonlyMode } from '@types'

import { ASSET_CURRENCY_DEPARTURE, ASSET_FIELD } from '../utils/const'
import { ASSET_PRICES_LIST } from './utils/const'
import { READONLY_MODE } from '@/const/common'
import { ROUTE_NAME } from '@/const'

import { filterPricesBySource, handlePricesOnMount } from './utils/helpers'
import { handleCatchedError } from '@/helpers/common'
import { stringToDateTime } from '@/helpers/dates'

import { useNotifications } from '@/plugins/notification'

import { useAssetsStore } from '@/store/assets'

import { UIButton } from '@ui/buttons'
import { UIPreloader } from '@ui/core'
import { UIInputSelect } from '@ui/fields'
import AssetPricesChart from './AssetDrawerPricesChart.vue'
import AssetPricesForm from './AssetDrawerPricesForm.vue'
import AssetPricesTable from './AssetDrawerPricesTable.vue'
import {
  XMarkIcon,
  PlusIcon,
  TableCellsIcon,
  ChartBarSquareIcon,
} from '@heroicons/vue/24/outline'

type Props = {
  instance: AssetClass
  currency?: boolean
}

const props = defineProps<Props>()

defineOptions({ inheritAttrs: false })

const assetsStore = useAssetsStore()
const route = useRoute()
const router = useRouter()

const { error } = useNotifications()

const assetPricesTableRef = useTemplateRef('assetPricesTableRef')

const loading = ref(false)

const priceSource = ref(AssetPriceSource.ALL)
const currentPrice = ref<AssetPrice>()

const currentView = ref<'Chart' | 'Table'>('Chart')
const isCreatingMode = ref(false)

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const disallowCreating = computed(
  () =>
    props.instance.field<string>(ASSET_FIELD.TICKER).value ===
    ASSET_CURRENCY_DEPARTURE,
)

const itemRoute = computed(() =>
  props.currency ? ROUTE_NAME.CURRENCIES_ITEM : ROUTE_NAME.ASSETS_ITEM,
)

const isItRoutedAsset = computed(() => {
  return (
    route.matched.some(item => item.name === itemRoute.value) &&
    route.params?.id === props.instance.id
  )
})

const isNewPriceVisible = computed(
  () => !disallowCreating.value && !isReadonly?.value,
)

const isLoading = computed(
  () =>
    loading.value ||
    assetsStore.loadingPrices ||
    assetsStore.loadingSecurityMasterPrices ||
    assetsStore.loadingPricesAction,
)

const isSwitchVisible = computed(
  () =>
    !!inputItems.value.length ||
    props.instance.field<string | null>(ASSET_FIELD.LINKED_SECURITY_ID).value,
)

const switchLabel = computed(() => {
  switch (currentView.value) {
    case 'Table':
      return 'Chart'
    default:
      return 'Table'
  }
})

const switchIcon = computed(() => {
  switch (switchLabel.value) {
    case 'Table':
      return TableCellsIcon
    default:
      return ChartBarSquareIcon
  }
})

const newPriceIcon = computed(() =>
  isCreatingMode.value ? XMarkIcon : PlusIcon,
)

const newPriceLabel = computed(() => (isCreatingMode.value ? '' : 'New price'))

const isFormShown = computed(() => isCreatingMode.value && !isReadonly?.value)

const inputItems = computed(() => {
  const data = props.instance.prices
  if (!data) return []
  const filterFn = filterPricesBySource(priceSource.value)
  const dir = currentView.value === 'Chart' ? 'asc' : 'desc'
  return orderBy(data?.filter(filterFn), 'date', dir)
})

const handleClickNewPrice = () => {
  assetPricesTableRef.value?.clear()
  isCreatingMode.value = !isCreatingMode.value
}

const handleAddPrice = async (formData: AssetPriceForm) => {
  const date = stringToDateTime(formData.date || undefined)
  if (
    props.instance.prices?.find(
      item =>
        date &&
        stringToDateTime(item.date || undefined)?.hasSame(date, 'day') &&
        item.source == AssetPriceSource.MANUAL,
    )
  ) {
    await error({
      message: `A price for date: ${formData.date} already exists. Edit that record if you want to change it.`,
    })
    return
  }
  if (!formData.date || formData.close === null) return
  const data = {
    asset_id: props.instance.id,
    date: formData.date,
    close: formData.close,
  }
  try {
    const result = await assetsStore.addAssetPrice(data, false)
    if (result) {
      isCreatingMode.value = false
      props.instance.updatePrices([result])
    }
  } catch (e) {
    handleCatchedError(e as string, data)
  }
}

const handleClickSwitch = () => {
  currentView.value = currentView.value === 'Chart' ? 'Table' : 'Chart'
  if (!isItRoutedAsset.value) return
  const routePostfix = currentView.value === 'Chart' ? '' : '-table'
  const priceRoute = props.currency
    ? ROUTE_NAME.CURRENCIES_ITEM_PRICES
    : ROUTE_NAME.ASSETS_ITEM_PRICES
  router.push({
    name: `${priceRoute}${routePostfix}`,
    params: { id: props.instance.id },
  })
}

onBeforeMount(async () => {
  if (isItRoutedAsset.value) {
    currentView.value = route.name?.toString().endsWith('-table')
      ? 'Table'
      : 'Chart'
  }
  loading.value = true
  const result = await handlePricesOnMount(props.instance, assetsStore)
  props.instance.updatePrices(result, true)
  loading.value = false
})
</script>

<style>
.asset-drawer-prices {
  &__chart {
    @apply mx-2;
  }
  &__preloader {
    @apply flex-auto;
  }
}
</style>
