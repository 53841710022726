<template>
  <div class="ui-sort__item" :class="mainClasses">
    <div v-if="!disallowDrag" class="ui-sort__item-panel">
      <div v-if="isBarsVisible" class="ui-sort__item-bars">
        <Bars3Icon aria-hidden="true" class="ui-sort__item-bars_icon handle" />
      </div>
      <div class="ui-sort__item-caption">
        <span v-if="index === 0">Sort by</span>
        <span v-else>then by</span>
      </div>
    </div>
    <UIInputAutocomplete
      :model-value="element.field"
      data-refid="sort__field-selector"
      :container
      :data
      :size
      placeholder="Choose a field"
      @update:model-value="handleUpdateField"
      @ready="handleFieldReady"
    />
    <span class="ui-sort__item-separator">order</span>
    <UIInputSelect
      :model-value="element.sortDirection"
      data-refid="sort__direction-selector"
      :container
      :data="directions"
      :size
      class="ui-sort__item-dir"
      @update:model-value="handleUpdateDirection"
      @ready="handleDirectionReady"
    />
    <UIButtonIcon
      v-bind="{ disabled }"
      data-refid="sort__remove-button"
      :size
      class="ui-sort__item-remove"
      variant="danger"
      fill="light"
      :leading="TrashIcon"
      @click="handleClickRemove"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref } from 'vue'

import { DropdownListData, Sort, SortDirection } from '@types'

import { TrashIcon } from '@heroicons/vue/24/outline'
import { UIButtonIcon } from '@ui/buttons'
import { UIInputAutocomplete, UIInputSelect } from '@ui/fields'

const size = 'sm'

type Props = {
  element: Sort
  container?: string
  index: number
  fields: DropdownListData[]
  data: DropdownListData[]
  disallowDrag?: boolean
  total: number
  disabled?: boolean
}

type Emits = {
  update: [data: Sort, key: string | number]
  remove: [key: string | number]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const dirRef = ref<HTMLInputElement>()

const isBarsVisible = computed(() => props.total > 1)

const data = computed(() => props.fields)

const directions = computed(() => [
  {
    value: SortDirection.ASC,
    label: 'Ascending',
  },
  {
    value: SortDirection.DESC,
    label: 'Descending',
  },
])

const mainClasses = computed(() => ({
  'ui-sort__item--undragable': props.disallowDrag,
}))

const handleClickRemove = () => {
  emit('remove', props.element.key || props.index)
}

const handleUpdateField = async (field: any) => {
  emit('update', { ...props.element, field }, props.element.key || props.index)
  await nextTick()
  setTimeout(() => {
    dirRef.value?.focus()
  })
}

const handleUpdateDirection = (sortDirection: any) => {
  emit(
    'update',
    { ...props.element, sortDirection },
    props.element.key || props.index,
  )
}

const handleFieldReady = (el: HTMLInputElement) => {
  el.focus()
}

const handleDirectionReady = (el: HTMLInputElement) => {
  dirRef.value = el
}
</script>

<style scoped>
.ui-sort {
  &__item {
    @apply grid sm:grid-cols-[min-content_1fr_min-content_7rem_min-content] gap-2;

    &--undragable {
      @apply sm:grid-cols-[1fr_min-content_7rem_min-content];
    }

    &-panel {
      @apply flex items-center gap-2;
    }

    &-bars {
      &_icon {
        @apply w-4 h-4 cursor-move;
      }
    }

    &-caption {
      @apply text-gray-500 text-sm;
      @apply whitespace-nowrap;
    }

    &-separator {
      @apply flex items-center;
      @apply text-gray-500;
      @apply text-sm;
    }
  }

  &__item-remove {
    @apply -mx-1;

    :deep() .ui-button__icon {
      @apply h-4 w-4;
    }
  }
}
</style>
