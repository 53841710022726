<template>
  <UIInputAutocomplete
    v-model="modelValue"
    :data-refid="dataRefid"
    :data
    :disabled
    :error
    :label
    :size
    :silent
    placeholder="Find an option"
    @ready="handleReady"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { orderBy } from 'lodash'

import { DocumentClass } from '..'
import { DropdownListData, InputSize } from '@types'

import { ASSET_FIELD } from '@/entities/assets/utils/const'
import { DOCUMENT_FIELD } from '../utils/const'

import { useAssetsBunchStore } from '@/store/assets/bunch'

import { UIInputAutocomplete } from '@ui/fields'

type Props = {
  instance: DocumentClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: InputSize
}

const props = defineProps<Props>()

const assetsBunchStore = useAssetsBunchStore()

const modelValue = props.instance.field<string | undefined>(
  DOCUMENT_FIELD.ASSET_ID,
)

const isSavedDocument = inject('isSavedDocument', ref(false))

const error = computed(() => props.instance.errors.name)
const silent = computed(() => !isSavedDocument.value)

const data = computed(() => {
  const list = Array.from(assetsBunchStore.getList.entries())
  const mappedList = list.reduce((acc, [value, instance]) => {
    if (instance.isNew) return acc
    acc.push({
      value,
      label: instance.field<string>(ASSET_FIELD.NAME).value,
    })
    return acc
  }, [] as DropdownListData[])
  const orderedList = orderBy(mappedList, [item => item.label?.toLowerCase()])
  return orderedList
})

const handleReady = (el: HTMLInputElement) => {
  if (!props.focusOnLoad) return
  el.focus()
}
</script>
