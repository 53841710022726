<template>
  <div class="tags__fields">
    <component
      :is="instance.getFormTagName()"
      v-bind="{
        dataRefid: 'tagFormName',
        focusOnLoad: focusNameOnLoad,
        instance,
        label: TAG_FIELD_LABEL[TAG_FIELD.TAG_NAME],
        readonly,
      }"
    />
    <component
      :is="instance.getFormTagValue()"
      v-bind="{
        dataRefid: 'tagFormValue',
        focusOnLoad: focusValueOnLoad,
        instance,
        label: TAG_FIELD_LABEL[TAG_FIELD.TAG_VALUE],
        readonly,
      }"
    />
    <div class="tags__buttons">
      <UIButtonClose @click="handleClose" />
      <UIButtonSave
        v-if="!readonly"
        ref="buttonSaveRef"
        data-refid="tagFormSave"
        v-bind="{
          disabled,
          loading,
        }"
        @click="handleSave"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, provide, ref, useTemplateRef } from 'vue'

import { ReadonlyMode } from '@types'
import { TagClass } from '..'
import { EntityState } from '@/entities/utils/enums'

import { TAG_FIELD, TAG_FIELD_LABEL, TAG_ID_PREFIX } from '../utils/const'
import { ROUTE_NAME } from '@/const'
import { NOTIFICATION_DELAY, READONLY_MODE } from '@/const/common'

import { useNotifications } from '@/plugins/notification'

import { UIButtonClose, UIButtonSave } from '@ui/buttons'

type Props = {
  instance: TagClass
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { progress, update } = useNotifications()

const loading = ref(false)

const buttonSaveRef = useTemplateRef('buttonSaveRef')
provide('tag-save-focus', async () => {
  await nextTick()
  buttonSaveRef.value?.focus()
})

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const disabled = computed(
  () => ![EntityState.CHANGED, EntityState.NEW].includes(props.instance.state),
)

const isSavedTag = computed(() => props.instance.id.startsWith(TAG_ID_PREFIX))
provide('isSavedTag', isSavedTag)

const focusNameOnLoad = computed(
  () => !isSavedTag.value && !props.instance.field(TAG_FIELD.TAG_NAME).value,
)

const focusValueOnLoad = computed(
  () =>
    !isSavedTag.value &&
    !focusNameOnLoad.value &&
    !props.instance.field(TAG_FIELD.TAG_VALUE).value,
)

const handleClose = () => {
  emit('close')
}

const handleSave = async () => {
  loading.value = true
  const isNew = props.instance.isNew
  const nid = await progress({
    message: `${isNew ? 'Creating' : 'Updating'} tag`,
  })

  if (isNew) {
    await props.instance.store()
  } else {
    await props.instance.update()
  }
  await update(
    nid,
    {
      type: 'success',
      message: `Tag ${props.instance.field(TAG_FIELD.TAG_NAME).value} ${
        isNew ? 'created' : 'updated'
      }`,
      link: {
        text: 'Open',
        to: {
          name: ROUTE_NAME.TAGS_ITEM,
          params: { id: props.instance.id },
        },
      },
    },
    NOTIFICATION_DELAY,
  )
  loading.value = false
  emit('close')
}
</script>

<script lang="ts">
export default {
  name: 'TagForm',
}
</script>

<style scoped>
@import url('../styles/tags.css');
</style>
