<template>
  <slot
    v-if="currentRepository"
    v-bind="{
      items,
      repositoryId,
      repositoryName,
      postfixIcon,
      isActionsAllowed,
      handleClickItem,
      handleExport,
      search,
      updateSearch,
      filter,
      filterFields,
      updateFilter,
    }"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { debouncedRef } from '@vueuse/core'

import { Filter, FilterGroup, FilterLogic, Repository } from '@types'

import { NOTIFICATION_DELAY, SET_CURRENT_REPOSITORY } from '@/const'
import { ACCESS_TYPES } from '@/const/repositories'

import { downloadAsFile } from '@/helpers/common'
import { filterItemByExpressions } from '@/components/UI/Filter/utils/business-logic'

import { useNotifications } from '@/plugins/notification'

import { useRepositoriesStore } from '@/store/repositories'
import { useUserStore } from '@/store/user'
import { useUserSubscriptionsStore } from '@/store/user/subscriptions'

import { UserGroupIcon } from '@heroicons/vue/24/outline'

const repositoriesStore = useRepositoriesStore()
const userStore = useUserStore()
const userSubscriptionsStore = useUserSubscriptionsStore()

const { progress, update } = useNotifications()

const setCurrentRepository = inject<(id?: string) => void>(
  SET_CURRENT_REPOSITORY,
)

const search = ref()
const debouncedSearch = debouncedRef(search)

const filter = ref<Filter | FilterGroup>()

const ownerList = computed(() => [
  ...new Set(repositoriesStore.list.map(item => item.owner)),
])

const filterFields = computed(() => [
  {
    name: 'name',
    caption: 'Name',
    notEmpty: true,
  },
  {
    name: 'owner',
    caption: 'Owner',
    isSimple: true,
    notEmpty: true,
    list: ownerList.value.map(value => ({
      value,
      label: value === userStore.getEmail ? 'me' : value,
    })),
  },
  {
    name: 'user_repo_settings.access_type',
    caption: 'Access type',
    isSimple: true,
    notEmpty: true,
    list: Object.entries(ACCESS_TYPES).map(([value, label]) => ({
      value: `${Number(value) + 1}`,
      label,
    })),
  },
])

const repositoriesList = computed(() => repositoriesStore.list)

const filterLogic = computed(() => filter.value?.logic || FilterLogic.AND)

const items = computed(() => {
  const params = filter.value?.params
  if (debouncedSearch.value === '' && !params?.length)
    return repositoriesList.value
  return repositoriesList.value.filter(item => {
    let isFiltered = true
    if (params?.length) {
      isFiltered = !!filterItemByExpressions(item, params, filterLogic.value)
    }
    const lookup = Object.values(item).join(' ').toLowerCase()
    const isSearched =
      !debouncedSearch.value ||
      (lookup && lookup.includes(debouncedSearch.value))
    return isSearched && isFiltered
  })
})

const currentRepository = computed(() => repositoriesStore.getCurrentRepository)
const repositoryId = computed(() => repositoriesStore.getCurrentRepository?.id)
const repositoryName = computed(() => currentRepository.value?.name)

const postfixIcon = computed(() =>
  currentRepository.value && currentRepository.value.collaborators_count > 1
    ? UserGroupIcon
    : undefined,
)

const isActionsAllowed = computed(
  () => items.value.length < userSubscriptionsStore.getMaxReposNumber,
)

const handleClickItem = (item: Repository) => {
  if (item.id === currentRepository.value?.id) return
  setCurrentRepository && setCurrentRepository(item.id)
}

const handleExport = async (
  id: string,
  name: string,
  resolve?: () => void,
  reject?: () => void,
) => {
  const progressId = await progress({
    message: `${name} repository export`,
    description:
      'Please wait a moment, we are preparing a repository for you. With large repositories it can take several minutes',
  })
  try {
    const data = await repositoriesStore.exportRepository(id)
    if (data) {
      await update(
        progressId,
        {
          type: 'success',
          message: `${name} repository export successfully completed`,
          description: undefined,
        },
        NOTIFICATION_DELAY,
      )
      downloadAsFile(data, name)
    } else {
      throw Error()
    }
    resolve?.()
  } catch {
    await update(progressId, {
      type: 'error',
      message: `${name} repository export failed`,
    })
    reject?.()
  }
}

const updateSearch = (value?: string) => {
  search.value = value
}

const updateFilter = (value?: Filter | FilterGroup) => {
  filter.value = value
}
</script>
