import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { TaskStatus, TaskType } from '@types'

import { handleCatchedError } from '@/helpers/common'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsStore } from '@/store/assets'
import { useAssetsStoreV2 } from './assets/index'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useContactsStore } from '@/store/contacts/'
import { useContactsBunchStore } from '@/store/contacts/bunch'
import { useDashboardsSettingsStore } from '@/store/settings/dashboards'
import { useDocumentsStore } from '@/store/documents/index'
import { useDocumentsBunchStore } from '@/store/documents/bunch'
import { useDocumentsTagsStore } from '@/store/documents/tags'
import { useFilterStore } from './filter'
import { useLinkedDataStore } from '@/store/linkedData'
import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'
import { useLinkedDataMappingRulesStore } from './linkedData/rules'
import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'
import { useModalsStore } from './modals'
import { useRepositoriesStore } from '@/store/repositories'
import { useTagsBunchStore } from './tags/bunch'
import { useTagsStore } from '@/store/tags'
import { useTasksStore } from '@/store/tasks'
import { useTransactionsStore } from '@/store/transactions'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'
import { useTransactionsDeletedStore } from '@/store/transactions/deleted'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'
import { useUserSettingsStore } from '@/store/user/settings'

export const useMainStore = defineStore('main', () => {
  const analyticsStore = useAnalyticsStore()
  const assetsStore = useAssetsStore()
  const assetsStoreV2 = useAssetsStoreV2()
  const assetsBunchStore = useAssetsBunchStore()
  const contactsStore = useContactsStore()
  const contactsBunchStore = useContactsBunchStore()
  const dashboardsSettingsStore = useDashboardsSettingsStore()
  const documentsStore = useDocumentsStore()
  const documentsTagsStore = useDocumentsTagsStore()
  const documentsBunchStore = useDocumentsBunchStore()
  const filterStorage = useFilterStore()
  const linkedDataStore = useLinkedDataStore()
  const linkedDataConnectorsStore = useLinkedDataConnectorsStore()
  const linkedDataMappingRulesStore = useLinkedDataMappingRulesStore()
  const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()
  const modalsStore = useModalsStore()
  const repositoriesStore = useRepositoriesStore()
  const tagsBunchStore = useTagsBunchStore()
  const tagsStore = useTagsStore()
  const tasksStore = useTasksStore()
  const transactionsStore = useTransactionsStore()
  const transactionsBunchStore = useTransactionsBunchStore()
  const transactionsDeletedStore = useTransactionsDeletedStore()
  const transactionsSettingsStore = useTransactionsSettingsStore()
  const userSettingsStore = useUserSettingsStore()

  const slideOverFormDirty = ref(false)
  const isAppInitialized = ref(false)

  const blockers = ref<Map<string, () => Promise<any>>>(new Map())

  // GETTERS
  const getAppInitialized = computed(() => isAppInitialized.value)

  const getBlockers = computed(() => Array.from(blockers.value.values()))

  const getBlocker = (key: string) => {
    return blockers.value.get(key)
  }

  // SETTERS
  const setAppInitialized = (value: boolean) => {
    isAppInitialized.value = value
  }

  // ACTIONS

  const addBlocker = (key: string, action: () => Promise<any>) => {
    blockers.value.set(key, action)
  }

  const removeBlocker = (key: string) => {
    blockers.value.delete(key)
  }

  const clearBlockers = () => {
    blockers.value.clear()
  }

  const fetchRepositoryData = async () => {
    try {
      await Promise.all([
        repositoriesStore.fetchRepositoryInvites(),
        repositoriesStore.fetchRepositories(),
        repositoriesStore.fetchCurrencies(),
        assetsStore.fetchAssetsTypes(),
        userSettingsStore.fetch(),
      ])
      await repositoriesStore.setCurrentRepositoryId()
    } catch (e) {
      handleCatchedError(e as string)
    }
  }

  const fetchCurrentRepositoryData = async () => {
    try {
      await Promise.all([
        assetsStore.fetchAssets(),
        assetsStoreV2.fetchDeleted(),
        contactsStore.fetch(),
        documentsStore.fetch(),
        documentsTagsStore.fetch(),
        linkedDataStore.fetch(),
        linkedDataConnectorsStore.fetch(),
        linkedDataTransactionsTagsStore.fetch(),
        tagsStore.fetch(),
        tasksStore.fetch(),
      ])
    } catch (e) {
      handleCatchedError(e as string)
    }
  }

  const cancelFetch = async () => {
    await analyticsStore.cancel()
    await assetsStore.cancelFetch()
    await contactsStore.cancel()
    await documentsStore.cancel()
    await documentsTagsStore.cancel()
    await linkedDataStore.cancel()
    await linkedDataConnectorsStore.cancel()
    await linkedDataMappingRulesStore.cancel()
    await linkedDataTransactionsTagsStore.cancel()
    await tagsStore.cancel()
    await transactionsStore.cancel()
    await transactionsDeletedStore.cancel()
    await transactionsSettingsStore.cancel()
  }

  const clearState = async () => {
    clearBlockers()

    await analyticsStore.clear()
    await assetsStore.clearAssets()
    await assetsBunchStore.clear()
    await contactsStore.clear()
    await contactsBunchStore.clear()
    await dashboardsSettingsStore.clear()
    await documentsStore.clear()
    await documentsTagsStore.clear()
    await documentsBunchStore.clear()
    await filterStorage.clear()
    await linkedDataStore.clear()
    await linkedDataConnectorsStore.clear()
    await linkedDataMappingRulesStore.clear()
    await linkedDataTransactionsTagsStore.clear()
    await modalsStore.clear()
    await tagsStore.clear()
    await tagsBunchStore.clear()
    await tasksStore.clear()
    await transactionsStore.clear()
    await transactionsBunchStore.clear()
    await transactionsDeletedStore.clear()
  }

  const globalClear = async () => {
    await clearState()
    await repositoriesStore.clearRepositories()
    await transactionsSettingsStore.clear()

    // clear LocalStorage except for color scheme
    Object.keys(localStorage).forEach(key => {
      if (key !== 'vueuse-color-scheme') {
        localStorage.removeItem(key)
      }
    })
    sessionStorage.clear()
  }

  return {
    getAppInitialized,
    getBlockers,
    getBlocker,

    setAppInitialized,

    addBlocker,
    removeBlocker,
    clearBlockers,

    slideOverFormDirty,
    fetchRepositoryData,
    fetchCurrentRepositoryData,
    cancelFetch,
    clearState,
    globalClear,
  }
})
