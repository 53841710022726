<template>
  <UIInputDate
    ref="fieldRef"
    v-model="modelValue"
    :data-refid="dataRefid"
    v-bind="{
      ...$attrs,
      disabled,
      error,
      label,
      lazyFocus: false,
      silent,
      size,
    }"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref, useTemplateRef } from 'vue'

import { InputSize, TransactionClass } from '@types'

import { TRANSACTION_FIELD } from '../utils/const'

import { UIInputDate } from '@ui/fields'

type Props = {
  dataRefid?: string
  disabled?: boolean
  instance: TransactionClass
  label?: string
  size?: InputSize
}

const props = defineProps<Props>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = useTemplateRef('fieldRef')

const modelValue = props.instance.field<string>(TRANSACTION_FIELD.DATE)

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const error = computed(() => props.instance.errors.date)
const silent = computed(() => !isSavedTransaction.value)
</script>
