<template>
  <UIInputSelect v-model="criteriaComparison" :size :data />
  <template v-if="isFieldVisible">
    <UIInputAutocomplete
      v-if="mode === 'autocomplete'"
      v-model="criteriaValue"
      v-bind="{ size }"
      :data="periodsList"
      @ready="el => el.focus()"
    />
    <UIInputDate
      v-if="mode === 'input'"
      v-model="criteriaValue"
      v-bind="{ size }"
      inline-popover
    />
  </template>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeMount, watch } from 'vue'

import { FilterComparison, I18nTranslate, TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'
import { FILTER_LAST_PERIODS } from '@/const'

import { getCurrentDate } from '@/helpers/dates'
import { getComparisonData } from '@/components/UI/Filter/components/utils/helpers'

import { UIInputAutocomplete, UIInputDate, UIInputSelect } from '@ui/fields'

const modelValue = defineModel<TransactionRule['data']['criteria'][number]>({
  required: true,
})

const t = inject<I18nTranslate>('t', (data: string) => data)

const criteriaComparison = computed({
  get() {
    return modelValue.value.comparison
  },
  set(value) {
    modelValue.value.comparison = value
  },
})

const criteriaValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value
  },
})

const data = computed(() =>
  getComparisonData({ isDate: true }).map(value => ({
    value,
    label: value,
  })),
)

const mode = computed(() => {
  if (
    criteriaComparison.value &&
    [FilterComparison.LAST].includes(criteriaComparison.value)
  ) {
    return 'autocomplete'
  } else {
    return 'input'
  }
})

const periodsList = computed(() => {
  return FILTER_LAST_PERIODS.map(value => ({
    value: value.toString(),
    label: `${value} ${t('days')}`,
  }))
})

const isFieldVisible = computed(
  () =>
    criteriaComparison.value &&
    ![FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
      criteriaComparison.value,
    ),
)

onBeforeMount(async () => {
  if (!criteriaComparison.value) {
    criteriaComparison.value = FilterComparison.EQ
  }
})

watch(criteriaComparison, value => {
  switch (value) {
    case FilterComparison.LAST:
      criteriaValue.value = FILTER_LAST_PERIODS[0]
      break
    case FilterComparison.EMPTY:
    case FilterComparison.NOTEMPTY:
      criteriaValue.value = undefined
      break
    default:
      criteriaValue.value = getCurrentDate()
      break
  }
})
</script>
