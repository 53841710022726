import { Component, computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { orderBy } from 'lodash'

import { ModalFactoryInstances } from '@/components/App/ModalFactory/utils/types'
import { ModalClass, ModalEntity, ModalState } from '@types'

import { MODALS_START_ZINDEX } from './utils/const'
import { OVERLAY_DURATION } from '@/const/common'

import { useNotifications } from '@/plugins/notification'

import { Entity } from '@/entities/utils/common'

export const useModalsStore = defineStore('modals', () => {
  // INIT

  const { warn } = useNotifications()

  // STORE

  const list = ref<ModalFactoryInstances>(new Map())
  const zIndex = ref(MODALS_START_ZINDEX)

  // ACTIONS

  const init = (key: string, object: Component | Entity<any>) => {
    if (list.value.has(key)) return list.value.get(key)

    if (!(object instanceof Entity)) {
      const instance = new ModalEntity(object)
      const modalInstance = new ModalClass(instance)
      list.value.set(key, modalInstance)
      return modalInstance
    }

    const modalInstance = new ModalClass(object)
    modalInstance.conditionalClose = async function () {
      if (!object.isChanged && !object.isNew) {
        this.close()
        return
      }

      await warn({
        message: 'Warning',
        description:
          'You have unsaved changes in the form, do you want to continue editing?',
        actionRequired: true,
        actions: [
          {
            label: 'Continue editing',
            buttonVariant: 'primary',
            onClick: remove => {
              remove && remove()
            },
          },
          {
            label: 'Discard form changes',
            buttonVariant: 'secondary',
            onClick: remove => {
              remove && remove()
              if (object.isNew) {
                setTimeout(() => {
                  object.remove()
                }, OVERLAY_DURATION)
              } else if (object.isChanged) {
                setTimeout(() => {
                  object.cancel()
                }, OVERLAY_DURATION)
              }
              this.close()
            },
          },
        ],
      })
    }
    list.value.set(key, modalInstance)

    return modalInstance
  }

  const remove = (key: string) => {
    list.value.delete(key)
  }

  // GETTERS

  const getList = computed(() => list.value)

  const getOpened = computed(() => {
    return Array.from(list.value.values()).filter(
      modal => modal.state === ModalState.OPENED,
    )
  })

  const getZIndex = () => {
    return zIndex.value++
  }

  // SETTERS

  // CLEAR STORE

  const clear = (ignoreModals = ['repositories']) => {
    if (ignoreModals.length) {
      list.value = new Map(
        Array.from(list.value.entries()).filter(([key]) =>
          ignoreModals.includes(key),
        ),
      )
    } else {
      list.value = new Map()
    }
    zIndex.value = MODALS_START_ZINDEX
  }

  const hideLatest = () => {
    const orderedList = orderBy(
      Array.from(list.value.values()),
      ['zIndex'],
      ['desc'],
    )
    const latest = orderedList.find(modal => modal.state === ModalState.OPENED)
    latest?.conditionalClose()
  }

  return {
    init,
    remove,

    getList,
    getOpened,
    getZIndex,

    clear,
    hideLatest,
  }
})
