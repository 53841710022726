<template>
  <div class="ui-yes-no-switch">
    <div v-if="label" class="ui-yes-no-switch__label">
      {{ label }}
    </div>
    <div class="ui-yes-no-switch__group">
      <UIButton
        :label="labelYes"
        :fill="yesButtonFill"
        :size
        :variant
        @click="handleClickYes"
      />
      <UIButton
        :label="labelNo"
        :fill="noButtonFill"
        :size
        :variant
        @click="handleClickNo"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ButtonSize, ButtonVariant } from '@types'

import { UIButton } from '@ui/buttons'
import { computed } from 'vue'

type Props = {
  label?: string

  labelYes?: string
  labelNo?: string

  size?: ButtonSize
  variant?: ButtonVariant

  reverse?: boolean
}

const { labelYes = 'Yes', labelNo = 'No', ...props } = defineProps<Props>()

defineOptions({ name: 'UIYesNoSwitch' })

const modelValue = defineModel<boolean>('modelValue', { required: true })

const yesButtonFill = computed(() => {
  if (props.reverse) {
    return modelValue.value ? 'outlined' : undefined
  }
  return modelValue.value ? undefined : 'outlined'
})

const noButtonFill = computed(() => {
  if (props.reverse) {
    return modelValue.value ? undefined : 'outlined'
  }
  return modelValue.value ? 'outlined' : undefined
})

const handleClickYes = () => {
  modelValue.value = !props.reverse
}

const handleClickNo = () => {
  modelValue.value = !!props.reverse
}
</script>

<style>
.ui-yes-no-switch {
  &__label {
    @apply mb-1;
    @apply flex justify-between;
    @apply text-sm font-medium;
    @apply leading-tight;
    @apply text-gray-700 dark:text-gray-300;
  }

  &__group {
    @apply grid grid-cols-2;

    .ui-button {
      @apply first:rounded-r-none last:rounded-l-none;
      @apply z-10;

      &[class*='-outlined'] {
        @apply z-0;
      }
    }

    .ui-button--secondary-outlined {
      @apply border-[var(--button-secondary)];
    }
  }
}
</style>
