<template>
  <form
    v-circular-tab
    class="repository-collaborators-invite"
    @submit.prevent="onSubmit"
  >
    <UIInputText
      v-model="emailValue"
      :error="emailError"
      placeholder="Email"
      data-refid="RepositoryInviteFormEmail"
      :disabled
    />
    <UIInputSelect
      v-model="accessType"
      :error="accessTypeError"
      data-refid="RepositoryInviteFormAccessType"
      :disabled
      :data="accessTypes"
    />
    <UIButton
      label="Send invite"
      :disabled="disabled || !meta.valid"
      :loading="disabled"
      data-refid="RepositoryInviteFormSave"
    />
  </form>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import { orderBy } from 'lodash'

import {
  RepositoryCollaborator,
  RepositoryInvite,
  RepositoryInvitePost,
  RepositoryStatus,
} from '@types'
import { ACCESS_TYPES, ACCESS_TYPE_OWNER } from '@/const/repositories'

import { rules } from '@/helpers/validate'
import { handleCatchedError } from '@/helpers/common'
import { useNotifications } from '@/plugins/notification'

import { useRepositoriesStore } from '@/store/repositories'

import { UIButton } from '@ui/buttons'
import { UIInputSelect, UIInputText } from '@ui/fields'

type Props = {
  repositoryId: string
  list: (RepositoryInvite | RepositoryCollaborator)[]
  disabled?: boolean
}

const props = defineProps<Props>()

const { error } = useNotifications()

const repositoriesStore = useRepositoriesStore()

const accessTypes = orderBy(
  ACCESS_TYPES.filter((_, index) => index !== ACCESS_TYPE_OWNER - 1).map(
    (label, index) => ({
      value: `${index + 1}`,
      label,
    }),
  ),
  [item => item.value?.toLowerCase()],
)

const { meta, handleSubmit, resetForm } = useForm<RepositoryInvitePost>({
  initialValues: {
    email: '',
    access_type: 1,
    repository_id: props.repositoryId,
  },
  validationSchema: {
    email: rules.required,
    access_type: rules.required,
    repository_id: rules.required,
  },
})

const { value: emailValue, errorMessage: emailError } =
  useField<string>('email')
const { value: accessTypeValue, errorMessage: accessTypeError } =
  useField<number>('access_type')

const accessType = computed({
  get() {
    return accessTypeValue.value.toString()
  },
  set(value) {
    accessTypeValue.value = +value
  },
})

const onSubmit = handleSubmit(async values => {
  resetForm()

  const collaborator = props.list?.find(
    item =>
      item.email === values.email && item.status !== RepositoryStatus.DECLINED,
  )
  if (collaborator) {
    await error({
      message: `User ${values.email} ${
        collaborator.status === RepositoryStatus.PENDING
          ? 'was already invited'
          : 'is already a collaborator'
      }`,
    })
    return
  }
  try {
    await repositoriesStore.addRepositoryInvite(values)
  } catch (e) {
    handleCatchedError(e as string, values)
  }
})
</script>

<style>
.repository-collaborators-invite {
  @apply grid grid-cols-1 sm:grid-cols-[auto_6.5rem_max-content];
  @apply gap-2 mb-4;

  .ui-button__label {
    @apply whitespace-nowrap;
  }
}
</style>
