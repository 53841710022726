import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import {
  UserPlanName,
  UserSwitchPlanResponse,
  UserSubscription,
  UserPlan,
  UserPlanUnit,
} from '@types'

import api from '@/store/api'

import { prepareResponseError } from '../utils/helpers'

import { useRepositoriesStore } from '../repositories'

export const useUserSubscriptionsStore = defineStore(
  'user-subscriptions',
  () => {
    // INIT
    const repositoriesStore = useRepositoriesStore()

    const loading = ref(false)

    const currentSubscription = ref<UserSubscription>()

    const plansList = ref<UserPlan[]>()

    let abortController = new AbortController()

    const repositoryPlan = computed(() => repositoriesStore.getCurrentPlan)

    // GETTERS
    const getPlansList = computed(() => {
      if (!plansList.value) {
        fetchPlansList()
      }
      return plansList.value || []
    })

    const getCurrentSubscription = computed(() => currentSubscription.value)

    const getCurrentPlanSchema = computed(
      () => getCurrentSubscription.value?.plan.plan_schema,
    )

    const getCurrentPlanName = computed(
      () => getCurrentPlanSchema.value?.name || UserPlanName.STANDARD,
    )

    const getCurrentPlan = computed(
      () => repositoryPlan.value || getCurrentPlanSchema.value,
    )

    const getCurrentPlanIndex = computed(() => {
      if (!getCurrentPlanSchema.value) return
      return (
        plansList.value?.findIndex(
          plan => plan.name === getCurrentPlanSchema.value?.name,
        ) || 0
      )
    })

    const getPreviousPlan = computed(() => {
      if (getCurrentPlanIndex.value === undefined) return
      return getPlansList.value?.[getCurrentPlanIndex.value - 1]
    })

    const getNextPlan = computed(() => {
      if (getCurrentPlanIndex.value === undefined) return
      return getPlansList.value?.[getCurrentPlanIndex.value + 1]
    })

    const getAvailableWidgets = computed(
      () => getCurrentPlan.value?.dashboard_widgets || [],
    )

    const getDisabledViews = computed<string[]>(() => [])

    const getMaxReposNumber = computed(
      () => getCurrentPlanSchema.value?.max_repos_number || 1,
    )

    const getAvailableTransactionTypes = computed(
      () => getCurrentPlan.value?.transaction_types || [],
    )

    // ACTIONS
    const fetchCurrentSubscription = async () => {
      loading.value = true
      try {
        const result = await api.get('subscription', {
          signal: abortController.signal,
        })
        currentSubscription.value = result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loading.value = false
      }
    }

    const fetchHostedPageSession = async (
      planId: number,
      periodUnit: UserPlanUnit,
    ) => {
      try {
        const result = await api.get(
          `subscription/hosted_page_session?plan_id=${planId}&period_unit=${periodUnit}`,
        )
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const fetchPlansList = async () => {
      const planLsistUrl = new URL(
        '@/../public/plans-list.json',
        import.meta.url,
      ).href
      const data = await fetch(planLsistUrl).then(res => res.json())
      plansList.value = data
    }

    const changePlan = async (
      plan_id: number,
      period_unit: UserPlanUnit,
      deactivated_repositories?: string[],
    ) => {
      try {
        const result = await api.post<UserSwitchPlanResponse>(
          'subscription/switch_plan',
          {
            plan_id,
            period_unit,
            deactivated_repositories,
          },
        )
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const cancel = () => {
      abortController.abort()
      abortController = new AbortController()
    }

    const clear = () => {
      currentSubscription.value = undefined
    }

    return {
      loading,

      fetchCurrentSubscription,
      fetchHostedPageSession,
      changePlan,

      getPlansList,
      getCurrentSubscription,
      getCurrentPlanName,
      getPreviousPlan,
      getNextPlan,
      getAvailableWidgets,
      getDisabledViews,
      getMaxReposNumber,
      getAvailableTransactionTypes,

      cancel,
      clear,
    }
  },
)
