import * as yup from 'yup'

import {
  Transaction,
  TransactionEntry,
  TransactionSettings,
} from './utils/types'
import { Entity } from '../utils/common'
import { EntityEvent } from '../utils/enums'
import { EntityErrors } from '../utils/types'

import { TRANSACTION_DEFAULT_DATA, TRANSACTION_FIELD } from './utils/const'

import {
  Rule,
  makeRequired,
  rules,
  NEGATIVE_RULES,
  POSITIVE_RULES,
} from '@/helpers/validate'
import { displayAsset, displayAmount } from './utils/helpers'
import { numberParse } from '@/helpers/numbers'
import { convertDateToISO, stringToLocalDateString } from '@/helpers/dates'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useModalsStore } from '@/store/modals'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'
import { useTransactionsStore } from '@/store/transactions'

import TransactionFormAccount1 from './components/TransactionFormAccount1.vue'
import TransactionFormAccount2 from './components/TransactionFormAccount2.vue'
import TransactionFormAccount3 from './components/TransactionFormAccount3.vue'
import TransactionFormAccount4 from './components/TransactionFormAccount4.vue'
import TransactionFormAmount1 from './components/TransactionFormAmount1.vue'
import TransactionFormAmount2 from './components/TransactionFormAmount2.vue'
import TransactionFormAmount3 from './components/TransactionFormAmount3.vue'
import TransactionFormAmount4 from './components/TransactionFormAmount4.vue'
import TransactionFormAsset1 from './components/TransactionFormAsset1.vue'
import TransactionFormAsset2 from './components/TransactionFormAsset2.vue'
import TransactionFormAsset3 from './components/TransactionFormAsset3.vue'
import TransactionFormAsset4 from './components/TransactionFormAsset4.vue'
import TransactionFormDate from './components/TransactionFormDate.vue'
import TransactionFormDescription from './components/TransactionFormDescription.vue'
import TransactionFormType from './components/TransactionFormType.vue'
import TransactionSlideover from './components/TransactionSlideover.vue'

export class TransactionClass extends Entity<Transaction> {
  private assetsBunchStore
  private bunchStore
  private transactionsStore
  private transactionsSettingsStore
  private modalsStore

  constructor(data: Partial<Transaction>, created = false) {
    if (data.description === null) {
      data.description = ''
    }
    super(data, TRANSACTION_DEFAULT_DATA, created)
    this.assetsBunchStore = useAssetsBunchStore()
    this.bunchStore = useTransactionsBunchStore()
    this.modalsStore = useModalsStore()
    this.transactionsSettingsStore = useTransactionsSettingsStore()
    this.transactionsStore = useTransactionsStore()
    this.cachedData = { settings: undefined }
  }

  get settings(): TransactionSettings | undefined {
    this.prepareSettings()
    return this.cachedData?.settings
  }

  get emptyAccountId1() {
    return !this.settings?.entries[0]?.account
  }
  get emptyAccountId2() {
    return !this.settings?.entries[1]?.account
  }
  get emptyAccountId3() {
    return !this.settings?.entries[2]?.account
  }
  get emptyAccountId4() {
    return !this.settings?.entries[3]?.account
  }

  get emptyAmount1() {
    return !this.settings?.entries[0]?.amount
  }
  get emptyAmount2() {
    return !this.settings?.entries[1]?.amount
  }
  get emptyAmount3() {
    return !this.settings?.entries[2]?.amount
  }
  get emptyAmount4() {
    return !this.settings?.entries[3]?.amount
  }

  get emptyAssetId1() {
    return !this.settings?.entries[0]?.asset
  }
  get emptyAssetId2() {
    return !this.settings?.entries[1]?.asset
  }
  get emptyAssetId3() {
    return !this.settings?.entries[2]?.asset
  }
  get emptyAssetId4() {
    return !this.settings?.entries[3]?.asset
  }

  get entries() {
    return this.field<TransactionEntry[]>(TRANSACTION_FIELD.ENTRIES).value
  }

  get displayDate() {
    return stringToLocalDateString(
      this.field<string>(TRANSACTION_FIELD.DATE).value,
    )
  }

  get displayAccountId1() {
    if (this.emptyAccountId1) return ' '
    return displayAsset(
      this.entries[0]?.account_id,
      this.assetsBunchStore.getList,
    )
  }
  get displayAccountId2() {
    if (this.emptyAccountId2) return ' '
    return displayAsset(
      this.entries[1]?.account_id,
      this.assetsBunchStore.getList,
    )
  }
  get displayAccountId3() {
    if (this.emptyAccountId3) return ' '
    return displayAsset(
      this.entries[2]?.account_id,
      this.assetsBunchStore.getList,
    )
  }
  get displayAccountId4() {
    if (this.emptyAccountId4) return ' '
    return displayAsset(
      this.entries[3]?.account_id,
      this.assetsBunchStore.getList,
    )
  }

  get displayAmount1() {
    if (this.emptyAmount1) return ' '
    return displayAmount(
      this.entries[0]?.amount,
      this.settings?.entries[0]?.amount?.rule === 'percent',
    )
  }
  get displayAmount2() {
    if (this.emptyAmount2) return ' '
    return displayAmount(
      this.entries[1]?.amount,
      this.settings?.entries[1]?.amount?.rule === 'percent',
    )
  }
  get displayAmount3() {
    if (this.emptyAmount3) return ' '
    return displayAmount(
      this.entries[2]?.amount,
      this.settings?.entries[2]?.amount?.rule === 'percent',
    )
  }
  get displayAmount4() {
    if (this.emptyAmount4) return ' '
    return displayAmount(
      this.entries[3]?.amount,
      this.settings?.entries[3]?.amount?.rule === 'percent',
    )
  }

  get displayAssetId1() {
    if (this.emptyAssetId1) return ' '
    return displayAsset(
      this.entries[0]?.asset_id,
      this.assetsBunchStore.getList,
    )
  }
  get displayAssetId2() {
    if (this.emptyAssetId2) return ' '
    return displayAsset(
      this.entries[1]?.asset_id,
      this.assetsBunchStore.getList,
    )
  }
  get displayAssetId3() {
    if (this.emptyAssetId3) return ' '
    return displayAsset(
      this.entries[2]?.asset_id,
      this.assetsBunchStore.getList,
    )
  }
  get displayAssetId4() {
    if (this.emptyAssetId4) return ' '
    return displayAsset(
      this.entries[3]?.asset_id,
      this.assetsBunchStore.getList,
    )
  }

  get isReadonly() {
    return !!this.settings?.readonly
  }

  getEntryAsset(index: number, value: string) {
    const asset = this.assetsBunchStore.getElementByName(value)
    if (!asset) return
    if (!this.entries?.[index]) {
      this.entries[index] = {
        account_id: '',
        amount: null,
        asset_id: '',
      }
    }
    return asset
  }

  pasteType(value: string) {
    if (
      !this.transactionsSettingsStore.getList.find(item => item.name === value)
    ) {
      return false
    }
    this.field(TRANSACTION_FIELD.TYPE).value = value
    this.validateData()
    return true
  }

  pasteDate(value: string) {
    const date = convertDateToISO(value)
    if (date) {
      this.field(TRANSACTION_FIELD.DATE).value = date
      return true
    } else {
      return false
    }
  }

  pasteAccountId(index: number, value: string, check: boolean) {
    if (value.trim() === '') {
      this.entries[index].account_id = ''
      return true
    }
    const emptyKey = `emptyAccount${index + 1}` as keyof TransactionClass
    if (this[emptyKey]) return false
    if (check) {
      const asset = this.getEntryAsset(index, value)
      if (!asset) return false
      const assetType = asset.get().type
      if (
        !this.settings?.entries[index].account?.includes.includes(assetType)
      ) {
        return false
      }
      this.entries[index].account_id = asset.id
    } else {
      this.entries[index].account_id = value
    }
    this.validateData()
    return true
  }

  pasteAccountId1(value: string, check = true) {
    return this.pasteAccountId(0, value, check)
  }

  pasteAccountId2(value: string, check = true) {
    return this.pasteAccountId(1, value, check)
  }

  pasteAccountId3(value: string, check = true) {
    return this.pasteAccountId(2, value, check)
  }

  pasteAmount(index: number, value: string) {
    if (value.trim() === '') {
      this.entries[index].amount = null
      return true
    }
    const emptyKey = `emptyAmount${index + 1}` as keyof TransactionClass
    if (this[emptyKey]) return false
    let amount = numberParse(value)
    const rule = this.settings?.entries[index].amount?.rule
    if (rule === 'percent') {
      amount = amount / 100
    }
    if (rule && NEGATIVE_RULES.includes(rule)) {
      amount = -Math.abs(amount)
    }
    if (rule && POSITIVE_RULES.includes(rule)) {
      amount = Math.abs(amount)
    }
    this.entries[index].amount = amount
    this.validateData()
    return true
  }

  pasteAmount1(value: string) {
    return this.pasteAmount(0, value)
  }

  pasteAmount2(value: string) {
    return this.pasteAmount(1, value)
  }

  pasteAmount3(value: string) {
    return this.pasteAmount(2, value)
  }

  pasteAssetId(index: number, value: string, check: boolean) {
    if (value.trim() === '') {
      this.entries[index].asset_id = ''
      return true
    }
    const emptyKey = `emptyAsset${index + 1}` as keyof TransactionClass
    if (this[emptyKey]) return false
    if (check) {
      const asset = this.getEntryAsset(index, value)
      if (!asset) return false
      const assetType = asset.get().type
      if (!this.settings?.entries[index].asset?.includes.includes(assetType)) {
        return false
      }
      this.entries[index].asset_id = asset.id
    } else {
      this.entries[index].asset_id = value
    }
    this.validateData()
    return true
  }

  pasteAssetId1(value: string, check = true) {
    return this.pasteAssetId(0, value, check)
  }

  pasteAssetId2(value: string, check = true) {
    return this.pasteAssetId(1, value, check)
  }

  pasteAssetId3(value: string, check = true) {
    return this.pasteAssetId(2, value, check)
  }

  getModal() {
    return TransactionSlideover
  }

  getFormType() {
    return TransactionFormType
  }

  getFormDate() {
    return TransactionFormDate
  }

  getFormAccountId1() {
    if (this.emptyAccountId1) return
    return TransactionFormAccount1
  }
  getFormAccountId2() {
    if (this.emptyAccountId2) return
    return TransactionFormAccount2
  }
  getFormAccountId3() {
    if (this.emptyAccountId3) return
    return TransactionFormAccount3
  }
  getFormAccountId4() {
    if (this.emptyAccountId4) return
    return TransactionFormAccount4
  }

  getFormAmount1() {
    if (this.emptyAmount1) return
    return TransactionFormAmount1
  }
  getFormAmount2() {
    if (this.emptyAmount2) return
    return TransactionFormAmount2
  }
  getFormAmount3() {
    if (this.emptyAmount3) return
    return TransactionFormAmount3
  }
  getFormAmount4() {
    if (this.emptyAmount4) return
    return TransactionFormAmount4
  }

  getFormAssetId1() {
    if (this.emptyAssetId1) return
    return TransactionFormAsset1
  }
  getFormAssetId2() {
    if (this.emptyAssetId2) return
    return TransactionFormAsset2
  }
  getFormAssetId3() {
    if (this.emptyAssetId3) return
    return TransactionFormAsset3
  }
  getFormAssetId4() {
    if (this.emptyAssetId4) return
    return TransactionFormAsset4
  }

  getFormDescription() {
    return TransactionFormDescription
  }

  private validateEntries() {
    const settings = this.settings?.entries
    return (_: TransactionEntry, options: any) => {
      const entrySettings = settings?.[options.index]
      const result: Record<string, Rule> = {}
      if (entrySettings?.account && !entrySettings.account.optional) {
        result.account_id = rules.required.nullable()
      }
      if (entrySettings?.amount) {
        result.amount = makeRequired(rules[entrySettings.amount.rule])
      }
      if (entrySettings?.asset) {
        result.asset_id = rules.required.nullable()
      }
      return yup.object(result)
    }
  }

  validate() {
    const entriesRules = this.validateEntries()
    const result: Record<string, Rule> = {
      type: rules.required,
      date: makeRequired(rules.date),
      entries: yup.array().of(yup.lazy(entriesRules) as any),
      description: rules.limited,
    }
    return result
  }

  handleErrors(errors: EntityErrors) {
    return Object.fromEntries(
      Object.entries(errors).map(([key, value]) => {
        if (key.includes('entries[')) {
          const matches = key.match(/(\d+)\]\.(.+)/)
          if (matches?.[1] && matches[2]) {
            const index = +matches[1]
            const entryTitle = this.settings?.entries[index]?.title
            const field = matches[2].replace('_id', '')
            const newKey = `"${entryTitle}" ${field}`
            return [newKey, value]
          }
        }
        return [key, value]
      }),
    )
  }

  private removeFromBunch() {
    this.bunchStore.getList.delete(this.id)
  }

  private removeFromForms() {
    this.modalsStore.getList.delete(this.id)
  }

  remove() {
    this.removeFromBunch()
    this.removeFromForms()
  }

  private prepareSettings() {
    if (!this.transactionsSettingsStore?.getList.length) return
    const type = this.field<string>(TRANSACTION_FIELD.TYPE).value
    if (this.cachedData.settings?.name !== type) {
      const settings = this.transactionsSettingsStore?.getList.find(
        item => item.name === type,
      )
      this.cachedData.settings = settings
    }
  }

  resetSettings() {
    this.cachedData.settings = undefined
    this.prepareSettings()
  }

  async store() {
    try {
      const data = this.get()
      const result = await this.transactionsStore.store(data)
      this.remove()
      if (result !== undefined) {
        this.reset(result)
        this.bunchStore.unshiftElement(this)
        this.callEvent(EntityEvent.STORED)
      }
    } catch {
      throw Error()
    }
  }

  async update() {
    try {
      const result = await this.transactionsStore.update(this.get())
      if (result === undefined) {
        this.cancel()
      } else {
        this.reset(result)
        this.callEvent(EntityEvent.UPDATED)
      }
    } catch {
      throw Error()
    }
  }

  async destroy() {
    try {
      if (!this.isNew) {
        await this.transactionsStore.bulkDestroy([this.id])
        this.callEvent(EntityEvent.DESTROYED)
      }
      this.remove()
    } catch {
      throw Error()
    }
  }
}
