export enum UserPlanName {
  PROFESSIONAL = 'professional',
  STANDARD = 'standard',
  COLLABORATOR = 'collaborator',
}

export enum UserPlanType {
  PLAN = 'plan',
  ADD_ON = 'add_on',
}

export enum UserPlanUnit {
  MONTH = 'month',
  YEAR = 'year',
}

export enum UserPlanStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export enum UserSubscriptionStatus {
  FUTURE = 'future',
  IN_TRIAL = 'in_trial',
  ACTIVE = 'active',
  NON_RENEWING = 'non_renewing',
  PAUSED = 'paused',
  CANCELLED = 'cancelled',
  TRANSFERED = 'transferred',
  INACTIVE = 'inactive',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
}
