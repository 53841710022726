<template>
  <component
    :is="inputComponent"
    v-model="modelValue"
    :always-negative
    :always-positive
    :silent
  >
    <template v-if="trailCurrency" #trailing>
      {{ currency }}
    </template>
  </component>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, ref } from 'vue'

import { TransactionSettings } from '@types'

import { NEGATIVE_RULES, POSITIVE_RULES } from '@/helpers/validate'

import { UIInputNumber, UIInputPercent } from '@ui/fields'

type Props = {
  settings?: TransactionSettings['entries'][number]['amount']
  trailCurrency?: boolean
}

const props = defineProps<Props>()

const modelValue = defineModel<number | null>()

const currency = inject<ComputedRef<string>>('currency')
const isSavedTransaction = inject('isSavedTransaction', ref(false))

const silent = computed(() => !isSavedTransaction.value)

const inputComponent = computed(() =>
  props.settings?.rule === 'percent' ? UIInputPercent : UIInputNumber,
)

const alwaysNegative = computed(() => {
  const rule = props.settings?.rule
  return !!rule && NEGATIVE_RULES.includes(rule)
})

const alwaysPositive = computed(() => {
  const rule = props.settings?.rule
  return !!rule && POSITIVE_RULES.includes(rule)
})
</script>
