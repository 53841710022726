<template>
  <UIInputSelect
    v-if="isSelectField"
    v-bind="{ container, size }"
    v-model="modelValue"
    :data="dateData"
    :placeholder="t('Select value')"
    @ready="handleReady"
  >
    <template v-if="loading" #trailing>
      <UIPreloader />
    </template>
  </UIInputSelect>
  <UIInputAutocomplete
    v-else
    v-bind="{ container, size }"
    v-model="modelValue"
    :data="listData"
    :placeholder="t('Select value')"
    placement="bottom-end"
    @ready="handleReady"
  >
    <template v-if="loading" #trailing>
      <UIPreloader />
    </template>
  </UIInputAutocomplete>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeMount, ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'

import { ButtonSize, DropdownListData, I18nTranslate } from '@types'
import { FilterComparison } from '../utils/enums'
import { FilterField } from '../utils/types'

import { FILTER_LAST_PERIODS } from './utils/const'

import { monthDateToName } from '@/helpers/dates'

import { UIInputAutocomplete, UIInputSelect } from '@ui/fields'
import { UIPreloader } from '@ui/core'

type Props = {
  fieldSettings?: FilterField
  comparison?: FilterComparison
}

const props = defineProps<Props>()

const modelValue = defineModel<any>()

defineExpose({
  start,
  init() {
    initData()
  },
})

const container = inject<string>('container')
const size = inject<ButtonSize>('size')
const t = inject<I18nTranslate>('t', (data: string) => data)

const inputRef = ref<HTMLInputElement>()
const internalListData = ref<DropdownListData[]>()

const loading = ref(false)

const isSelectField = computed(
  () =>
    props.comparison === FilterComparison.LAST || props.fieldSettings?.isMonth,
)

const dateData = computed(
  () =>
    (props.fieldSettings?.isMonth
      ? listData.value?.map(item => ({
          ...item,
          label: monthDateToName(`1999-${item.value}-01`),
        }))
      : FILTER_LAST_PERIODS.map(value => ({
          value: value.toString(),
          label: `${value} ${t('days')}`,
        }))) || [],
)

const listData = computed(
  () => props.fieldSettings?.list || internalListData.value || [],
)

const initData = useDebounceFn(async () => {
  internalListData.value = undefined
  if (!props.fieldSettings?.getter) return
  loading.value = true
  internalListData.value = await props.fieldSettings.getter()
  loading.value = false
}, 200)

async function start() {
  await initData()
  inputRef.value?.focus()
}

const handleReady = (el: HTMLInputElement) => {
  inputRef.value = el
}

onBeforeMount(initData)
</script>
