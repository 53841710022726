<template>
  <div class="asset-drawer__form">
    <UIPanel
      v-model="clearedSearch"
      v-model:storage="searchStorage"
      placeholder="Search transactions..."
    >
      <template #default="{ ButtonNew }">
        <UIDropdownList
          v-if="transactionTypes"
          :data
          placement="bottom-end"
          @click:item="handleSelectType"
        >
          <template #default="{ toggle }">
            <component :is="ButtonNew" size="sm" @click="toggle" />
          </template>
        </UIDropdownList>
        <UIButton
          label="Go to transactions"
          :leading="CircleStackIcon"
          variant="secondary"
          fill="light"
          size="sm"
          hide-label-to-sm
          @click="handleGoToTransactions"
        />
      </template>
    </UIPanel>
  </div>
  <UIGridSkeleton
    v-if="disabled"
    v-bind="{ columns, sm }"
    message="Loading transactions..."
  />
  <UIGrid v-else v-bind="{ columns, items, sm }" v-model:sort="sort" scrollable>
    <template #actions="{ item, size }">
      <UIButton
        v-if="item"
        :label="editButtonLabel"
        :leading="editButtonIcon"
        variant="secondary"
        fill="light"
        :size
        @click="handleClickEditTransaction(item)"
      />
    </template>
    <template #cellEntries0Amount="{ displayValue }">
      <span :class="getAmountCellClass(displayValue)">{{ displayValue }}</span>
    </template>
    <template #cellEntries1Amount="{ displayValue }">
      <span :class="getAmountCellClass(displayValue)">{{ displayValue }}</span>
    </template>
  </UIGrid>
  <UIPagination
    v-bind="{ disabled, pageNo }"
    :page-size="pagination?.page_size"
    :total="pagination?.total"
    class="asset-drawer__pagination"
    @click:page="handleClickPage"
  />
</template>

<script setup lang="ts">
import { computed, inject, onMounted, onWatcherCleanup, ref, watch } from 'vue'
import { get, isEqual } from 'lodash'
import { debouncedWatch } from '@vueuse/core'
import { useRouter } from 'vue-router'

import type {
  Transaction,
  PaginatedMeta,
  Sort,
  ReadonlyMode,
  AssetClass,
} from '@types'
import { TransactionClass } from '@/entities/transactions'

import {
  ASSET_TRANSACTIONS_PAGE_SIZE,
  DEBOUNCE_DELAY,
  READONLY_MODE,
} from '@/const/common'
import { INVESTMENT_TYPES, TRANSACTION_TYPES } from './utils/const'
import { ASSET_FIELD } from '../utils/const'
import {
  ROUTE_NAME,
  TRANSACTION_ENTRY_FIELD,
  TRANSACTION_ENTRY_FIELD_LABEL,
  TRANSACTION_FIELD,
  TRANSACTION_FIELD_LABEL,
  TRANSACTIONS_FILTER_KEY,
  TRANSACTIONS_SEARCH_KEY,
  TRANSACTIONS_SORT_KEY,
} from '@/const'

import { handleCatchedError } from '@/helpers/common'
import { getAccountCurrency } from './utils/helpers'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useModalsStore } from '@/store/modals'
import { useRepositoriesStore } from '@/store/repositories'
import { useTransactionsStore } from '@/store/transactions'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'

import { UIButton } from '@ui/buttons'
import { UIDropdownList } from '@ui/dropdowns'
import { UIPagination, UIPanel } from '@ui/core'
import { UIGrid, UIGridSkeleton } from '@ui/grid'
import { CircleStackIcon, EyeIcon, PencilIcon } from '@heroicons/vue/24/outline'

type Props = {
  instance: AssetClass
  accountId?: string
  amount?: number
  path?: string[]
}

type Emits = {
  (e: 'close'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({ inheritAttrs: false })

const router = useRouter()

const assetsBunchStore = useAssetsBunchStore()
const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()
const transactionsStore = useTransactionsStore()
const transactionsBunchStore = useTransactionsBunchStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

const items = ref<Transaction[]>([])
const pagination = ref<PaginatedMeta>()

const searchStorage = ref<string>()
const clearedSearch = ref<string>()
const sort = ref<Sort[]>()
const pageNo = ref(0)

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const editButtonLabel = computed(() =>
  isReadonly?.value ? 'View transaction' : 'Edit transaction',
)
const editButtonIcon = computed(() =>
  isReadonly?.value ? EyeIcon : PencilIcon,
)
const disabled = computed(() => !transactionsSettingsStore.initFlag)

const transactionTypes = computed<string[]>(() => {
  const key = props.instance.field<keyof typeof TRANSACTION_TYPES>(
    TRANSACTION_FIELD.TYPE,
  ).value
  return TRANSACTION_TYPES[key]
})

const data = computed(() =>
  transactionTypes.value?.map(value => ({
    value,
    label: value,
  })),
)

const sm = '1fr'
const columns = computed(() => [
  {
    name: 'type',
    caption: 'Type',
  },
  {
    name: TRANSACTION_FIELD.DATE,
    caption: TRANSACTION_FIELD_LABEL[TRANSACTION_FIELD.DATE],
    filter: {
      isDate: true,
    },
  },
  {
    name: 'entries.0.account_id',
    caption: TRANSACTION_ENTRY_FIELD_LABEL[TRANSACTION_ENTRY_FIELD.ACCOUNT_ID],
    formatter: (id: string) => assetsBunchStore.getNameById(id),
  },
  {
    name: `entries.0.amount`,
    caption: TRANSACTION_ENTRY_FIELD_LABEL[TRANSACTION_ENTRY_FIELD.AMOUNT],
    cellValueClasses: 'ui-grid__cell--right blurable-number',
    formatter: (_: any, data: Transaction) => {
      const transaction = new TransactionClass(data)
      return transaction.displayAmount1
    },
  },
  {
    name: 'entries.0.asset_id',
    caption: TRANSACTION_ENTRY_FIELD_LABEL[TRANSACTION_ENTRY_FIELD.ASSET_ID],
    formatter: (id: string) => assetsBunchStore.getNameById(id),
  },
  {
    name: 'entries.1.account_id',
    caption: TRANSACTION_ENTRY_FIELD_LABEL[TRANSACTION_ENTRY_FIELD.ACCOUNT_ID],
    formatter: (id: string) => assetsBunchStore.getNameById(id),
  },
  {
    name: `entries.1.amount`,
    caption: TRANSACTION_ENTRY_FIELD_LABEL[TRANSACTION_ENTRY_FIELD.AMOUNT],
    cellValueClasses: 'ui-grid__cell--right blurable-number',
    formatter: (_: any, data: Transaction) => {
      const transaction = new TransactionClass(data)
      return transaction.displayAmount2
    },
  },
  {
    name: 'entries.1.asset_id',
    caption: TRANSACTION_ENTRY_FIELD_LABEL[TRANSACTION_ENTRY_FIELD.ASSET_ID],
    formatter: (id: string) => assetsBunchStore.getNameById(id),
  },
  {
    name: TRANSACTION_FIELD.DESCRIPTION,
    caption: TRANSACTION_FIELD_LABEL[TRANSACTION_FIELD.DESCRIPTION],
  },
])

const fetchOptions = computed(() => ({
  search: clearedSearch.value,
  sort: sort.value?.map((item, index) => ({ ...item, index })),
  asset_id: props.instance.id,
}))

const params = computed(() => {
  return !props.path || props.path?.length === 2
    ? {
        account_id: props.instance.id,
      }
    : {
        account_id: props.accountId,
        amount: props.amount,
        asset_id: props.instance.id,
      }
})

const getAmountCellClass = (value: string) => ({
  'grid-cell__amount--positive': !value.startsWith('-'),
  'grid-cell__amount--negative': value.startsWith('-'),
})

const fetch = async (signal?: AbortSignal) => {
  try {
    const response = await transactionsStore.fetch(
      {
        ...fetchOptions.value,
        page_no: pageNo.value,
        page_size: ASSET_TRANSACTIONS_PAGE_SIZE,
      },
      false,
      signal,
    )
    items.value = response.data
    pagination.value = response.meta
  } catch (e) {
    handleCatchedError(e as string, fetchOptions.value)
  }
}

const handleClickPage = (page: number) => {
  pageNo.value = page
}

const handleClickEditTransaction = (transaction: Transaction) => {
  let instance = transactionsBunchStore.getElementById(transaction.id)
  if (!instance) {
    instance = new TransactionClass(transaction as any)
  }
  const modalInstance = modalsStore.init(instance.id, instance)
  modalInstance?.open(modalsStore.getZIndex())
}

const handleGoToTransactions = () => {
  emit('close')
  const uiSettings =
    repositoriesStore.getCurrentRepository?.user_repo_settings.ui_settings

  const currentSearch = get(uiSettings, TRANSACTIONS_SEARCH_KEY)
  const currentFilter = get(uiSettings, TRANSACTIONS_FILTER_KEY)
  const currentSort = get(uiSettings, TRANSACTIONS_SORT_KEY)

  const name = props.instance.field<string>(ASSET_FIELD.NAME).value

  if (
    currentSearch === name &&
    !currentFilter &&
    currentSort.length === 0 &&
    router.currentRoute.value.name === ROUTE_NAME.TRANSACTIONS
  )
    return

  repositoriesStore.updateUISettings(TRANSACTIONS_SEARCH_KEY, name)
  repositoriesStore.updateUISettings(TRANSACTIONS_FILTER_KEY, undefined)
  repositoriesStore.updateUISettings(TRANSACTIONS_SORT_KEY, [])
  transactionsStore.clear()
  router.push({ name: ROUTE_NAME.TRANSACTIONS })
}

const handleSelectType = (type: string) => {
  const instance = transactionsBunchStore.createElement()
  const account1 = params.value?.account_id || ''
  let amount1 = params.value?.amount || null
  let asset1 = params.value?.asset_id || ''
  if (type === 'sell' && amount1) {
    amount1 = amount1 > 0 ? -amount1 : amount1
  }
  let account2 = ''
  const asset2 = getAccountCurrency(account1, assetsBunchStore)
  if (INVESTMENT_TYPES.includes(type)) {
    account2 = asset1
    asset1 = ''
  }
  const entries = [
    {
      account_id: account1,
      amount: amount1,
      asset_id: asset1,
    },
    {
      account_id: account2,
      amount: null,
      asset_id: asset2,
    },
  ]
  instance.set({
    type,
    entries,
  })
  const account = assetsBunchStore.getElementById(account1)
  const accountName = account?.field(ASSET_FIELD.NAME).value
  const asset = assetsBunchStore.getElementById(params.value?.asset_id || '')
  const assetName = asset?.field(ASSET_FIELD.NAME).value
  const title = `${type} for: ${accountName}${
    amount1 ? ` ${amount1} ${assetName}` : ''
  }`
  const modalInstance = modalsStore.init(instance.id, instance)
  modalInstance?.open(modalsStore.getZIndex(), {
    title,
    shortened: true,
    restrictions: transactionTypes.value,
  })
}

watch(pageNo, () => {
  const controller = new AbortController()
  fetch(controller.signal)
  onWatcherCleanup(() => {
    controller.abort()
  })
})

debouncedWatch(
  fetchOptions,
  (value, prev) => {
    if (isEqual(value, prev)) return
    fetch()
  },
  {
    deep: true,
    debounce: DEBOUNCE_DELAY,
  },
)

onMounted(() => {
  fetch()
  if (!transactionsSettingsStore.initFlag) {
    transactionsSettingsStore.fetch()
  }
})
</script>

<style scoped>
.grid-cell__amount {
  &--positive {
    @apply text-slate-950 dark:text-green-400;
  }

  &--negative {
    @apply text-red-600 dark:text-red-400;
  }
}
</style>
