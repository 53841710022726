<template>
  <div class="asset-drawer__form">
    <UIPanel
      v-model="clearedSearch"
      v-model:storage="searchStorage"
      placeholder="Search positions..."
    />
  </div>
  <UIGridSkeleton
    v-if="isLoading"
    v-bind="{ columns, footer, sm }"
    message="Loading positions..."
  />
  <UIGrid
    v-else
    v-bind="{ columns, items, footer, sm }"
    v-model:sort="sort"
    scrollable
  />
</template>

<script setup lang="ts">
import { ComputedRef, computed, inject, onMounted, ref } from 'vue'
import { sumBy } from 'lodash'

import {
  AssetClass,
  FilterComparison,
  FilterGroup,
  FilterLogic,
  Sort,
} from '@types'
import { AnalyticsPosition } from '@/store/analytics/position/utils/types'

import { amountFormatter, numberFormat } from '@/helpers/numbers'
import { getAssetName, getAssetTicker } from '@/views/Analytics/utils/helpers'

import useGridSort from '@/components/hooks/gridSort'
import useGridFilter from '@/components/hooks/gridFilter'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'

import { UIPanel } from '@ui/core'
import { UIGrid, UIGridSkeleton } from '@ui/grid'

type Props = {
  instance: AssetClass
}

const props = defineProps<Props>()

defineOptions({ inheritAttrs: false })

const analyticsStore = useAnalyticsStore()
const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()

const currency = inject<ComputedRef<string>>('currency')

const isLoading = ref(true)
const positions = ref<AnalyticsPosition[]>([])

const searchStorage = ref<string>()
const clearedSearch = ref<string>()
const sort = ref<Sort[]>()

const assetsBunch = computed(() => assetsBunchStore.getList)

const endOfDate = computed(() => repositoriesStore.getTimelineEnd)

const sm = '1fr 1fr'
const columns = computed(() => [
  {
    name: 'asset_id',
    caption: 'Asset',
    formatter: (assetId: string) => getAssetTicker(assetsBunch.value)(assetId),
    tooltip: (field: string, item?: AnalyticsPosition) => {
      const key = field as keyof AnalyticsPosition
      return item?.[key]
        ? getAssetName(assetsBunch.value, '')(item[key].toString())
        : undefined
    },
  },
  {
    name: 'amount',
    caption: 'Amount',
    formatter: amountFormatter,
    headerValueClasses: 'ui-grid__header-value--right',
    cellValueClasses: 'ui-grid__cell--right blurable-number',
    footerValueClasses: 'ui-grid__footer-value--right blurable-number',
  },
  {
    name: 'position_close',
    caption: 'Value',
    formatter: (value: number) =>
      numberFormat(value, { currency: currency?.value }),
    headerValueClasses: 'ui-grid__header-value--right',
    cellValueClasses: 'ui-grid__cell--right blurable-number',
    footerValueClasses: 'ui-grid__footer-value--right blurable-number',
  },
])

const footer = computed(() => ({
  asset_id: 'Total',
  position_close: numberFormat(sumBy(inputItems.value, 'position_close'), {
    currency: currency?.value,
  }),
}))

const amountFilter = ref<FilterGroup>({
  logic: FilterLogic.AND,
  params: [
    { comparison: FilterComparison.GREATER, field: 'amount', value: '0' },
  ],
})

const inputItems = computed(() => positions.value)
const filteredItems = useGridFilter(
  columns,
  inputItems,
  clearedSearch,
  amountFilter,
)
const items = useGridSort(sort, columns, filteredItems, ['position_close'])

const fetchPositions = async () => {
  try {
    const result = await analyticsStore.fetch({
      date: endOfDate.value,
      accounts: JSON.stringify([props.instance.id]),
      data: 'position',
    })
    positions.value = result.position_view
  } catch (e) {
    console.log(e)
  } finally {
    isLoading.value = false
  }
}

onMounted(fetchPositions)
</script>
