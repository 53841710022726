import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useActiveElement, useMagicKeys } from '@vueuse/core'

import {
  HotKeysCallback,
  HotKeysCallbackObj,
  HotKeysWatcher,
} from './utils/types'

import { generateUniqueId, prepareCallbacks } from './utils/helpers'

const { escape, meta, k, q, slash } = useMagicKeys({
  onEventFired(e) {
    currentEvent.value = e
  },
})

const activeElement = useActiveElement()

const callbacks: HotKeysCallbackObj = {}

const currentEvent = ref<KeyboardEvent>()

const isNotInput = computed(
  () =>
    activeElement.value?.tagName !== 'INPUT' &&
    activeElement.value?.tagName !== 'TEXTAREA' &&
    activeElement.value?.tagName !== 'SELECT',
)

const handleCallbacks = (callbacks?: HotKeysCallback[]) => {
  if (!callbacks) return
  for (const cb of callbacks) {
    const stop = !!cb.fn(currentEvent.value)
    if (stop) break
  }
}

watch(escape, value => {
  if (!value) return
  handleCallbacks(prepareCallbacks(callbacks.escape))
})

watch(
  () => meta.value && k.value,
  value => {
    if (!value || !isNotInput.value) return
    handleCallbacks(prepareCallbacks(callbacks.search))
  },
)

watch(slash, value => {
  if (!value || !isNotInput.value) return
  handleCallbacks(prepareCallbacks(callbacks.tree))
})

watch(q, value => {
  if (!value || !isNotInput.value) return
  handleCallbacks(prepareCallbacks(callbacks.navbar))
})

export const useHotKeys: HotKeysWatcher = (action, fn, params) => {
  const id = generateUniqueId()

  onMounted(() => {
    if (!callbacks[action]) callbacks[action] = []
    callbacks[action].push({
      id,
      fn,
      priority: params?.priority ?? 0,
      flag: params?.flag,
    })
  })

  onUnmounted(() => {
    const index = callbacks[action]?.findIndex(cb => cb.id === id)
    if (index !== undefined && index !== -1) callbacks[action]?.splice(index, 1)
  })
}
