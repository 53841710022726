<template>
  <UIInputSelect v-model="criteriaComparison" :size :data />
  <UIInputAutocomplete
    v-if="isFieldVisible"
    v-model="criteriaValue"
    :size
    :data="statusesList"
    @ready="el => el.focus()"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue'

import {
  FilterComparison,
  FilterField,
  DropdownListData,
  TransactionRule,
} from '@types'

import { CONTROL_SIZE as size } from '../utils/const'

import useFiltersService from '@/services/filters'
import { getComparisonData } from '@/components/UI/Filter/components/utils/helpers'

import { UIInputAutocomplete, UIInputSelect } from '@ui/fields'

const modelValue = defineModel<TransactionRule['data']['criteria'][number]>({
  required: true,
})

const { fetchOptions } = useFiltersService()

const statusesList = ref<DropdownListData[]>([])

const criteriaComparison = computed({
  get() {
    return modelValue.value.comparison
  },
  set(value) {
    modelValue.value.comparison = value
  },
})

const criteriaValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value
  },
})

const data = computed(() =>
  getComparisonData({ isSimple: true } as FilterField).map(value => ({
    value,
    label: value,
  })),
)

const isFieldVisible = computed(
  () =>
    criteriaComparison.value &&
    ![FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
      criteriaComparison.value,
    ),
)

watch(isFieldVisible, value => {
  criteriaValue.value = value ? '' : undefined
})

onBeforeMount(async () => {
  if (!criteriaComparison.value) {
    criteriaComparison.value = FilterComparison.EQ
  }
  statusesList.value = await fetchOptions('LinkedDataTransactions', 'status')
})
</script>
