<template>
  <UIInputDynamicList
    v-model="modelValue"
    :data-refid="dataRefid"
    :error
    :disabled
    :data
    :label
    :readonly
    :size
    :silent
    @click:new="handleCreate"
    @ready="handleReady"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { orderBy } from 'lodash'

import { useTagsBunchStore } from '@/store/tags/bunch'

import { TagClass } from '../'
import { InputSize } from '@types'

import { TAG_FIELD } from '../utils/const'

import { UIInputDynamicList } from '@ui/fields'

type Props = {
  instance: TagClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: InputSize
}

const props = defineProps<Props>()

const modelValue = props.instance.field<string>(TAG_FIELD.TAG_NAME)

const tagsBunchStore = useTagsBunchStore()

const isSavedTag = inject('isSavedTag', ref(false))

const internalData = ref<string[]>([])

const readonly = computed(() => !props.instance.isCommon)
const error = computed(() => props.instance.errors.name)
const silent = computed(() => !isSavedTag.value)

const tagsList = computed(() => tagsBunchStore.getCommonList)

const data = computed(() => {
  const list = Array.from(tagsList.value)
  const mappedList = [
    ...internalData.value,
    ...list.map(
      ([, instance]) => instance.field<string>(TAG_FIELD.TAG_NAME).value,
    ),
  ]
  const names = [...new Set(mappedList)].filter(name => !!name)
  return orderBy(names).map(value => ({
    value,
    label: value,
  }))
})

const handleCreate = (name: string, callback?: () => void) => {
  internalData.value.push(name)
  modelValue.value = name
  callback?.()
}

const handleReady = (e: HTMLInputElement) => {
  if (!props.focusOnLoad) return
  e.focus()
}
</script>
