<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog v-bind="{ zIndex, size: 'sm', title }" @hide="handleClose">
      <ContactForm v-bind="{ instance: modal.entity }" @close="handleClose" />
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { ContactClass } from '../'
import { ModalClass, ModalState } from '@types'

import { CONTACT_MODAL_TITLE, CONTACT_MODAL_TITLE_NEW } from './utils/const'

import { UIDialog } from '@ui/modals'
import ContactForm from './ContactForm.vue'

type Props = {
  modal: ModalClass<ContactClass>
}
const props = defineProps<Props>()

const title = computed(() =>
  props.modal.entity.isNew ? CONTACT_MODAL_TITLE_NEW : CONTACT_MODAL_TITLE,
)

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const zIndex = computed(() => props.modal.zIndex)

const handleClose = () => {
  props.modal.conditionalClose()
}
</script>

<script lang="ts">
export default {
  name: 'ContactDialog',
}
</script>
