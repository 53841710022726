import { initDropboxPicker, useGooglePicker, initOneDrivePicker } from './utils'

export const useDocumentService = (
  callback: (data: { name: string; path: string }) => void,
) => {
  const googlePicker = useGooglePicker()

  const onSelectService = (value: string) => {
    switch (value) {
      case 'Google Drive':
        googlePicker.open(result => {
          const { name, url: path } = result
          callback({ name, path })
        })
        break
      case 'Dropbox':
        initDropboxPicker(result => {
          const { name, link: path } = result
          callback({ name, path })
        })
        break
      case 'OneDrive':
        initOneDrivePicker(result => {
          const { name, webUrl: path } = result
          callback({ name, path })
        })
        break
      default:
        callback({ name: '', path: '' })
        break
    }
  }

  return {
    onSelectService,
  }
}
