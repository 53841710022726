import { computed, nextTick, Ref, ref, watch } from 'vue'
import { get, cloneDeep } from 'lodash'

import { useRepositoriesStore } from '@/store/repositories'

export const rebaseSettings = (key: string | string[]) => {
  const repositoriesStore = useRepositoriesStore()
  const repoUISettings =
    repositoriesStore.getCurrentRepository?.user_repo_settings.ui_settings
  const oldValue = get(repoUISettings, key)
  if (oldValue && !oldValue.used) {
    repositoriesStore.updateUISettings(key, {
      ...oldValue,
      used: true,
    })
    return cloneDeep(oldValue)
  } else {
    return null
  }
}

export const useUISettings = <T>(key: string | string[], defaultValue: T) => {
  const repositoriesStore = useRepositoriesStore()

  const internalValue = ref(defaultValue) as Ref<T>
  const isInit = ref(false)

  const repoUiSettings = computed(
    () =>
      repositoriesStore.getCurrentRepository?.user_repo_settings.ui_settings,
  )
  const currentValue = computed(() => get(repoUiSettings.value, key))

  const data = computed({
    get() {
      return internalValue.value
    },
    set(value) {
      if (!isInit.value) return
      internalValue.value = value || defaultValue
      repositoriesStore.updateUISettings(key, value)
    },
  })

  watch(
    () => repositoriesStore.currentRepositoryId,
    async rid => {
      if (!rid) return
      isInit.value = false
      const value = get(repoUiSettings.value, key)
      internalValue.value = value || defaultValue
      await nextTick()
      isInit.value = true
    },
    { immediate: true },
  )

  watch(currentValue, value => {
    if (value === internalValue.value) return
    internalValue.value = value
  })

  return data
}
