<template>
  <UIInputDynamicList
    v-model="actionValue"
    :data
    :size
    @click:new="handleCreate"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { orderBy } from 'lodash'

import { DropdownListData, TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'

import { prepareTagsList } from './utils/helpers'

import { useTagsBunchStore } from '@/store/tags/bunch'

import useFiltersService from '@/services/filters'

import { UIInputDynamicList } from '@ui/fields'

const modelValue = defineModel<TransactionRule['data']['actions'][number]>({
  required: true,
})

const tagsBunchStore = useTagsBunchStore()

const { fetchOptions } = useFiltersService()

const existingList = ref<DropdownListData[]>([])

const tagsList = computed(() =>
  prepareTagsList(tagsBunchStore.getCategoriesList).map(value => ({
    value,
    label: value,
  })),
)

const data = computed(() =>
  orderBy([...new Set([...existingList.value, ...tagsList.value])], 'label'),
)

const actionValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value || ''
  },
})

const handleCreate = (value: string, callback: () => void) => {
  existingList.value.push({
    value,
    label: value,
  })
  actionValue.value = value
  callback()
}

onBeforeMount(async () => {
  existingList.value = await fetchOptions('LinkedDataTransactions', 'category')
  if (actionValue.value !== undefined) {
    existingList.value.push({
      value: actionValue.value,
      label: actionValue.value,
    })
    return
  }
  actionValue.value = ''
})
</script>
