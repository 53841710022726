import { AssetClass } from '@/entities/assets'

import { LOADING_TEXT, UNKNOWN_VALUE } from '@/const/common'
import { ASSET_FIELD } from '@/entities/assets/utils/const'
import { ANALYTICS_REPO_ROOT } from '@/store/analytics/utils/const'

export const getAssetById = (
  id: string,
  assetsBunch: Map<string, AssetClass>,
) => {
  const asset = assetsBunch.get(id)
  return asset
}

const getAssetNameById = (id: string, assetsBunch: Map<string, AssetClass>) => {
  const asset = getAssetById(id, assetsBunch)
  return asset?.field<string>(ASSET_FIELD.NAME).value
}

export const getAssetName =
  (
    assetsBunch: Map<string, AssetClass>,
    repositoryName = '',
    defaultText = UNKNOWN_VALUE,
  ) =>
  (id: string) => {
    if (id === ANALYTICS_REPO_ROOT) {
      return repositoryName
    }
    if (assetsBunch.size === 0) {
      return LOADING_TEXT
    }
    return getAssetNameById(id, assetsBunch) || defaultText
  }

const getAssetTickerById = (
  id: string,
  assetsBunch: Map<string, AssetClass>,
) => {
  const asset = getAssetById(id, assetsBunch)
  return asset?.field<string>(ASSET_FIELD.TICKER).value
}

export const getAssetTicker =
  (assetsBunch: Map<string, AssetClass>) => (id: string) => {
    if (id === ANALYTICS_REPO_ROOT) {
      return ''
    }
    if (assetsBunch.size === 0) {
      return LOADING_TEXT
    }
    return getAssetTickerById(id, assetsBunch) || UNKNOWN_VALUE
  }

const getAssetTypeById = (id: string, assetsBunch: Map<string, AssetClass>) => {
  const asset = getAssetById(id, assetsBunch)
  return asset?.field<string>(ASSET_FIELD.TYPE).value
}

export const getAssetType =
  (assetsBunch: Map<string, AssetClass>) => (id: string) => {
    if (id === ANALYTICS_REPO_ROOT) {
      return ''
    }
    if (assetsBunch.size === 0) {
      return LOADING_TEXT
    }
    return getAssetTypeById(id, assetsBunch) || UNKNOWN_VALUE
  }

export const tagFormatingFn = (
  name: string,
  accountId: string,
  assetId: string,
  assetsBunch: Map<string, AssetClass>,
) => {
  const account = assetsBunch.get(accountId)
  const asset = assetsBunch.get(assetId)
  const accountTags = account?.tags
  const assetTags = asset?.tags
  return accountTags?.[name] || assetTags?.[name] || UNKNOWN_VALUE
}
