<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog
      :title
      :z-index
      size="2md"
      wrapper-class="repository-popup"
      @hide="handleClose"
    >
      <template v-if="returnable" #title>
        <UIButtonBack :label="title" @click="handleClickBack" />
      </template>
      <UITabs v-model="tab" :tabs bottom-border />
      <template v-if="tab === RepositoryTabs.DATA">
        <RepositoryDataForm
          v-model="repository"
          ref="dataFormRef"
          :loading
          @save="handleUpdate"
          @close="handleClose"
        >
          <RepositoryPricePrecedence
            :repository
            :disabled="loading"
            @change="handleUpdatePricePrecedence"
          />
          <template #buttons>
            <RepositoryAnalytics
              :repository-id
              :disabled="loading"
              class="mr-auto"
            />
          </template>
        </RepositoryDataForm>
      </template>
      <template v-if="tab === RepositoryTabs.COLLABORATORS">
        <RepositoryCollaborators :repository />
      </template>
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  computed,
  markRaw,
  onWatcherCleanup,
  ref,
  useTemplateRef,
  watch,
  watchEffect,
} from 'vue'

import { ModalClass, ModalState, Repository } from '@types'
import { RepositoryTabs } from './utils/enums'

import { useHotKeys } from '@/plugins/hotKeys'

import { handleCatchedError } from '@/helpers/common'
import { NotificationInstance, useNotifications } from '@/plugins/notification'

import { useModalsStore } from '@/store/modals'
import { useRepositoriesStore } from '@/store/repositories'

import Repositories from '../List/Repositories.vue'
import RepositoryAnalytics from './components/Analytics.vue'
import RepositoryCollaborators from './components/Collaborators.vue'
import RepositoryDataForm from './components/DataForm.vue'
import RepositoryPricePrecedence from './components/PricePrecedence.vue'

import { TransitionRoot } from '@headlessui/vue'
import { UIDialog } from '@ui/modals'
import { UITabs } from '@ui/core'
import { UIButtonBack } from '@ui/buttons'
import { NOTIFICATION_DELAY } from '@/const'

type Props = {
  modal: ModalClass<any>
  repository: Repository
  returnable?: boolean
}
const props = defineProps<Props>()

const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()

const { progress, remove, update } = useNotifications()

const dataFormRef = useTemplateRef('dataFormRef')

const tab = ref<RepositoryTabs>(RepositoryTabs.DATA)
const repository = ref<Repository>(props.repository)
const loading = ref(false)

const isCollaboratorsInited = ref(false)

const repositoryId = computed(() => repository.value.id)

const tabs = computed(() =>
  Object.values(RepositoryTabs).map(name => ({
    name,
  })),
)

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const title = computed(() => `${props.repository.name} repository settings`)

const handleUpdate = async (values: Partial<Repository>) => {
  let nid: NotificationInstance | undefined
  loading.value = true
  try {
    nid = await progress({
      message: 'Updating repository',
    })
    if (
      repositoriesStore.list.find(
        repo =>
          repo.name.toLowerCase() === values.name?.toLowerCase() &&
          repo.id !== repositoryId.value,
      )
    ) {
      await update(nid, {
        type: 'error',
        message:
          'This name of the repository already exists. Try something else',
      })
      loading.value = false
      return
    }
    const createdRepo = await repositoriesStore.updateRepository(
      values as Repository,
    )
    dataFormRef.value?.resetForm(values as any)
    nid &&
      (await update(
        nid,
        {
          type: 'success',
          message: `Repository ${createdRepo?.name} updated`,
        },
        NOTIFICATION_DELAY,
      ))
  } catch (e) {
    nid && (await remove(nid))
    handleCatchedError(e as string, values)
  } finally {
    loading.value = false
  }
}

const handleUpdatePricePrecedence = async (values: string[]) => {
  dataFormRef.value?.updatePriceSourcePrecedence(values)
}

const handleClickBack = () => {
  const modalInstance = modalsStore.init('repositories', markRaw(Repositories))
  modalInstance?.open(modalsStore.getZIndex(), {})
  handleClose()
}

const handleClose = () => {
  props.modal.close()
}

watch(isOpen, value => {
  if (!value) return
  tab.value = RepositoryTabs.DATA
  isCollaboratorsInited.value = false
})

watchEffect(async () => {
  if (
    !repositoryId.value ||
    tab.value !== RepositoryTabs.COLLABORATORS ||
    isCollaboratorsInited.value
  )
    return
  const controller = new AbortController()
  try {
    await repositoriesStore.fetchRepositoryCollaborators(
      repositoryId.value,
      controller.signal,
    )
    isCollaboratorsInited.value = true
  } catch (e) {
    handleCatchedError(e as string, { repositoryId: repositoryId.value })
  }
  onWatcherCleanup(() => {
    controller.abort()
  })
})

useHotKeys('escape', handleClose, { flag: isOpen })
</script>

<style>
.repository-popup {
  @apply min-h-80;
  @apply flex flex-col;
  @apply gap-4 !pt-0;
}
</style>
