<template>
  <UIInputText
    v-model="modelValue"
    :data-refid="dataRefid"
    :label
    :disabled
    :error
    :silent
    :size
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { TransactionClass } from '..'
import { InputSize } from '@types'

import { TRANSACTION_FIELD } from '../utils/const'

import { UIInputText } from '@ui/fields'

type Props = {
  dataRefid?: string
  disabled?: boolean
  instance: TransactionClass
  label?: string
  size?: InputSize
}

const props = defineProps<Props>()

const modelValue = props.instance.field<string>(TRANSACTION_FIELD.DESCRIPTION)

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const error = computed(() => props.instance.errors.description)
const silent = computed(() => !isSavedTransaction.value)
</script>
