import { orderBy } from 'lodash'

import { AssetClass } from '../..'
import { AssetPriceSource } from './enums'
import { AssetPrice, AssetSecurityMasterPrice } from '../../utils/types'
import { TagClass } from '@/entities/tags'

import { ASSET_FIELD } from '../../utils/const'
import { TAG_FIELD } from '@/entities/tags/utils/const'
import { ASSET_PRICES_SECURITY } from './const'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useAssetsStore } from '@/store/assets'

export const filterPricesBySource = (source?: AssetPriceSource) => {
  return (item: AssetPrice) => {
    if (!source || source === AssetPriceSource.ALL) return true
    const isManualPrice = item.source === AssetPriceSource.MANUAL
    const isSMPrice = item.source === AssetPriceSource.SECURITY_MASTER
    if (source === AssetPriceSource.MANUAL && isManualPrice) return true
    if (ASSET_PRICES_SECURITY.includes(source) && isSMPrice) return true
    if (source === AssetPriceSource.CONNECTOR && !isManualPrice && !isSMPrice)
      return true
    return false
  }
}

export const prepareSecurityMasterPrice = (
  item: AssetSecurityMasterPrice,
  index: number,
) => {
  return {
    ...item,
    id: index,
    source: AssetPriceSource.SECURITY_MASTER,
  }
}

export const prepareAccountList = (data: Map<string, TagClass>) => {
  const array = Array.from<TagClass>(data.values())
  const list = array.map(instance => ({
    value: instance.id,
    label: instance.field<string>(TAG_FIELD.TAG_VALUE).value,
  }))
  return orderBy(list, 'label')
}

export const handlePricesOnMount = (
  instance: AssetClass,
  assetsStore: ReturnType<typeof useAssetsStore>,
) =>
  new Promise<AssetPrice[]>(resolve => {
    if (instance.prices) {
      resolve(instance.prices)
      return
    }
    const assetLinkedSecurityId = instance.field<string | null>(
      ASSET_FIELD.LINKED_SECURITY_ID,
    ).value
    const promises: Promise<AssetPrice[]>[] = []
    promises.push(assetsStore.fetchAssetPrices(instance.id))
    if (assetLinkedSecurityId) {
      const promise = new Promise<AssetPrice[]>(resolve => {
        assetLinkedSecurityId &&
          assetsStore
            .fetchAssetSecurityMasterPrices(assetLinkedSecurityId)
            .then((result: AssetSecurityMasterPrice[]) => {
              resolve(result.map(prepareSecurityMasterPrice))
            })
      })
      promises.push(promise)
    }
    Promise.all(promises).then(result => {
      resolve(result.flat())
    })
  })

export const getAccountCurrency = (
  id: string,
  assetsBunchStore: ReturnType<typeof useAssetsBunchStore>,
) => {
  const account = assetsBunchStore.getList.get(id)
  const name = account?.field<string>(ASSET_FIELD.CURRENCY).value
  if (name) {
    const currency =
      assetsBunchStore.getElementByName(name) ||
      assetsBunchStore.getElementByTicker(name)
    return currency?.id || ''
  }
  return ''
}
