<template>
  <UIInputSelect v-model="actionSign" v-bind="{ size, data }" />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'

import { TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'

import { UIInputSelect } from '@ui/fields'

const modelValue = defineModel<TransactionRule['data']['actions'][number]>({
  required: true,
})

const data = [
  { value: 'positive', label: 'Positive' },
  { value: 'negative', label: 'Negative' },
  { value: 'flip sign', label: 'Flip Sign' },
]

const actionSign = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value || ''
  },
})

onBeforeMount(() => {
  if (actionSign.value !== undefined) return
  actionSign.value = ''
})

defineOptions({
  name: 'LinkedDataRulesActionSign',
})
</script>
