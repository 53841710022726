<template>
  <UIInputAutocomplete
    v-model="modelValue"
    :dataRefid
    :data
    :disabled
    :error
    :label
    :silent
    :size
    popper-class="transaction-form-type__popper"
    placeholder="Find an option"
  >
    <template v-if="hasPremiumTypes" #after>
      <div class="transaction-form-type__pro">
        <div class="transaction-form-type__pro-text">
          Some transaction types are exclusive to the PRO plan. Upgrade to
          access more options.
        </div>
        <RouterLink
          :to="{ name: ROUTE_NAME.SUBSCRIPTION_PLANS }"
          class="transaction-form-type__pro-button"
          @click.stop
        >
          Upgrade to PRO
        </RouterLink>
      </div>
    </template>
  </UIInputAutocomplete>
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'

import { TransactionClass } from '..'
import { InputSize } from '@types'

import { TRANSACTION_FIELD } from '../utils/const'

import { compareEntries } from './utils/helpers'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'

import { ROUTE_NAME } from '@/const'
import { UIInputAutocomplete } from '@ui/fields'

type Props = {
  dataRefid?: string
  instance: TransactionClass
  label?: string
  size?: InputSize
  disabled?: boolean
  restrictions?: string[]
}

const props = defineProps<Props>()

const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

const modelValue = props.instance.field<string>(TRANSACTION_FIELD.TYPE)

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const error = computed(() => props.instance.errors.type)
const silent = computed(() => !isSavedTransaction.value)

const data = computed(() => {
  let list = transactionsSettingsStore.getTypes
  if (props.restrictions) {
    list = list.filter(item => props.restrictions?.includes(item.value))
  }
  return list
    .filter(item => !item.readonly)
    .map(item => ({
      value: item.value,
      label: item.value,
    }))
})

const hasPremiumTypes = computed(() => {
  return transactionsSettingsStore.getTypes.some(item => item.readonly)
})

const currencyID = computed(() => {
  const asset =
    assetsBunchStore.getElementByName(
      repositoriesStore.getCurrentRepositoryCurrency,
    ) ||
    assetsBunchStore.getElementByTicker(
      repositoriesStore.getCurrentRepositoryCurrency,
    )
  return asset?.id
})

watch(modelValue, (value, oldValue) => {
  if (value === oldValue) return
  compareEntries(props.instance, assetsBunchStore, currencyID.value)
})
</script>

<style>
.transaction-form-type {
  &__popper {
    .v-popper__inner {
      @apply min-w-44;
    }
  }

  &__pro {
    @apply px-4 py-2;
    @apply flex items-center gap-2;
  }

  &__pro-text {
    @apply flex-auto;
    @apply text-xs;
    @apply text-gray-700 dark:text-gray-300;
  }

  &__pro-button {
    @apply bg-indigo-500;
    @apply py-0.5 px-2;
    @apply rounded-full;
    @apply text-xs;
    @apply text-white;
    @apply text-nowrap;
    @apply cursor-pointer;
  }
}
</style>
