<template>
  <UIInputAutocomplete
    v-model="modelValue"
    :dataRefid
    :data
    :disabled
    :error
    :label
    :silent
    :size
    placeholder="Find an option"
    @ready="handleReady"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { orderBy } from 'lodash'

import { AssetClass } from '..'
import { InputSize } from '@types'

import { ASSET_FIELD } from '../utils/const'

import { useAssetsStore } from '@/store/assets'

import { UIInputAutocomplete } from '@ui/fields'

type Props = {
  instance: AssetClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: InputSize
  restrictions?: string[]
}

const props = defineProps<Props>()

const assetsStore = useAssetsStore()

const modelValue = props.instance.field<string>(ASSET_FIELD.TYPE)

const isSavedAsset = inject('isSavedAsset', ref(false))
const injectedRestrictions = inject<string[]>('restrictions', [])

const error = computed(() => props.instance.errors.type)
const silent = computed(() => !isSavedAsset.value)

const data = computed(() =>
  orderBy(assetsStore.getAssetsTypes.filter(filterTypesByRestrictions)).map(
    value => ({
      value,
      label: value,
    }),
  ),
)

const filterTypesByRestrictions = (type: string) =>
  (!injectedRestrictions?.length || injectedRestrictions.includes(type)) &&
  (!props.restrictions || props.restrictions.includes(type))

const handleReady = (el: HTMLInputElement) => {
  if (!props.focusOnLoad) return
  setTimeout(() => {
    el.focus()
  }, 0)
}
</script>
