import { sortedIndexBy } from 'lodash'
import { Capacitor } from '@capacitor/core'

import { ASSET_CURRENCY_TYPE } from '@/entities/assets/utils/const'

import { getCurrentDate } from './dates'

import faro from '@/services/faro'
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

export const getAppMode = (appMode: string) => {
  return Capacitor.isNativePlatform() ? `${appMode}-capacitor` : appMode
}

export const handleCatchedError = (errorMessage: string, options?: any) => {
  if (!faro) {
    console.log(errorMessage)
  } else {
    const args = [errorMessage]
    if (options) {
      args.push(JSON.stringify(options))
    }
    faro.log.error(args)
  }
}

export const handleCatchedRequestError = (
  req: InternalAxiosRequestConfig,
  resp: AxiosResponse<any, any>,
) => {
  const url = `${req.baseURL}/${req.url!}`
  if (!faro) {
    console.log(req, resp)
  } else {
    if (resp) {
      faro.log.error([`Request failed. url=${url} status=${resp.status}`], {
        url: url,
        params: JSON.stringify(req.params!),
        method: req.method!,
        response_status: String(resp.status),
        data: JSON.stringify(req.data),
        response_data: JSON.stringify(resp.data),
        correlation_id: req.headers['x-correlation-id'],
      })
    } else {
      faro.log.error([`Request failed without response. url=${url}`], {
        url: url,
        params: JSON.stringify(req.params!),
        method: req.method!,
        data: JSON.stringify(req.data),
        correlation_id: req.headers['x-correlation-id'],
      })
    }
  }
}

export const downloadAsFile = (
  content: BlobPart,
  name: string,
  ext = 'json',
): void => {
  const file = new Blob([content], { type: 'application/json' })
  const a = document.createElement('a')
  const url = URL.createObjectURL(file)
  a.href = url
  const fileName = `${name}_${getCurrentDate({ withTime: true })}`
  a.download = fileName.replace(/[^a-z0-9]/gi, '_').toLowerCase() + `.${ext}`
  document.body.appendChild(a)
  a.click()
  setTimeout(function () {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 0)
}

export const getCurrencySymbol = (currencyName?: string): string => {
  try {
    const symbol =
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyName,
      })
        .formatToParts()
        .find(item => item.type === ASSET_CURRENCY_TYPE)?.value || ''
    return symbol === currencyName ? `${symbol} ` : symbol
  } catch {
    return ''
  }
}

export const waitForNode = (selector: string): Promise<Element> => {
  return new Promise(resolve => {
    const elm = document.querySelector(selector)
    if (elm) {
      return resolve(elm)
    }

    const observer = new MutationObserver(() => {
      const elm = document.querySelector(selector)
      if (elm) {
        resolve(elm)
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  })
}

export function processingSortedArray<T extends { id?: string | number }>(
  list: T[],
  processingItem: Partial<T>,
  method: 'delete',
): void
export function processingSortedArray<T extends { id?: string | number }>(
  list: T[],
  processingItem: T,
  method: 'insert' | 'update',
  sortedValue: Partial<T>,
  iterateBy: ((value: T) => string | number) | keyof T,
): void
export function processingSortedArray<T extends { id?: string }>(
  list: T[],
  processingItem: T,
  method: 'insert' | 'update' | 'delete',
  sortedValue?: T,
  iterateBy?: ((value: T) => string | number) | keyof T,
): void {
  const currentItemIndex = list.findIndex(item => item.id === processingItem.id)
  if (method === 'delete') {
    list.splice(currentItemIndex, 1)
  } else if (sortedValue && iterateBy) {
    const insertItemToIndex = sortedIndexBy(list, sortedValue, iterateBy)
    if (method === 'update') list.splice(currentItemIndex, 1)
    list.splice(
      insertItemToIndex +
        (currentItemIndex === -1 || currentItemIndex >= insertItemToIndex
          ? 0
          : -1),
      0,
      processingItem,
    )
  }
}

export const appendScript = async (
  src: string,
  id?: string,
  attrs?: Record<string, string>,
  refresh = false,
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    if (id && document.getElementById(id)) {
      if (!refresh) {
        resolve(true)
        return
      }
      document.getElementById(id)?.remove()
    }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = src
    id && (script.id = id)
    attrs &&
      Object.entries(attrs).map(([key, value]) => {
        script.setAttribute(key, value)
      })
    script.onload = resolve
    script.onerror = reject

    document.body.appendChild(script)
  })
}

export const injectCSS = async (
  css: string,
  id?: string,
  attrs?: Record<string, string>,
  refresh = false,
): Promise<unknown> => {
  return new Promise(resolve => {
    if (id && document.getElementById(id)) {
      if (!refresh) {
        resolve(true)
        return
      }
      document.getElementById(id)?.remove()
    }
    const style = document.createElement('style')
    style.innerText = css
    document.head.appendChild(style)
    id && (style.id = id)
    attrs &&
      Object.entries(attrs).map(([key, value]) => {
        style.setAttribute(key, value)
      })
    document.body.appendChild(style)
  })
}
