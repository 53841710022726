<template>
  <RepositoryWrapper>
    <template
      #default="{
        items,
        isActionsAllowed,
        handleClickItem,
        handleExport,
        repositoryId,
        search,
        updateSearch,
        filter,
        filterFields,
        updateFilter,
      }"
    >
      <TransitionRoot :show="isOpen" appear as="template">
        <UIDialog
          v-bind="{ zIndex }"
          size="2md"
          title="Repositories"
          wrapper-class="modal-repositories"
          @hide="handleClose"
        >
          <template #title>
            <div class="modal-repositories__title">
              <span>Repositories</span>
              <RepositoriesActions
                :disabled="!isActionsAllowed"
                size="xs"
                @hide="handleClose"
              >
                <slot name="actions" />
              </RepositoriesActions>
            </div>
          </template>
          <div class="modal-repositories__search">
            <div class="modal-repositories__search-input">
              <UIPanelSearch
                :model-value="search"
                placeholder="Search repositories..."
                size="sm"
                @update:model-value="updateSearch"
              />
            </div>
            <UIFilter
              :model-value="filter"
              size="sm"
              label=""
              :fields="filterFields"
              flat
              @update:model-value="updateFilter"
            />
          </div>
          <UILayoutNoData
            v-if="!items.length"
            inline
            hide-question-icon
            class="modal-repositories__no-data"
          />
          <div v-else class="modal-repositories__items">
            <RepositoriesItem
              v-for="item in items"
              :key="item.id"
              :item
              :active="item.id === repositoryId"
              @delete="handleDelete"
              @click="(handleClickItem(item), handleClose())"
              @export="handleExport"
              @close="handleClose"
            />
          </div>
          <RepositoriesLimit v-if="!isActionsAllowed" />
          <RepositoriesDelete
            v-if="deleteRepository"
            :repository="deleteRepository"
            @cancel="handleCancelDelete"
            @export="handleExport"
            @deleted="handleDeleted"
          />
        </UIDialog>
      </TransitionRoot>
    </template>
  </RepositoryWrapper>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { ModalClass, ModalState, Repository } from '@types'

import useAnalyticsStore from '@/store/analytics'
import { useMainStore } from '@/store/main'
import { useRepositoriesStore } from '@/store/repositories'

import RepositoryWrapper from '../RepositoryWrapper.vue'
import RepositoriesActions from './RepositoriesActions.vue'
import RepositoriesDelete from './RepositoriesDelete.vue'
import RepositoriesItem from './RepositoriesItem.vue'
import RepositoriesLimit from './RepositoriesLimit.vue'
import { UILayoutNoData, UIPanelSearch } from '@ui/core'
import { UIFilter } from '@ui/controllers'
import { UIDialog } from '@ui/modals'

type Props = {
  modal: ModalClass<any>
}

const props = defineProps<Props>()

const analyticsStore = useAnalyticsStore()
const repositoriesStore = useRepositoriesStore()
const mainStore = useMainStore()

const deleteRepository = ref<Repository>()

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)
const zIndex = computed(() => props.modal.zIndex)

const handleClose = () => {
  props.modal.close()
}

const handleDelete = (item: Repository) => {
  deleteRepository.value = JSON.parse(JSON.stringify(item))
}

const handleCancelDelete = () => {
  deleteRepository.value = undefined
}

const handleDeleted = async (id: string) => {
  await repositoriesStore.fetchRepositories()
  // if the deleted repository is the current repository, clear the state
  if (id === repositoriesStore.currentRepositoryId) {
    await Promise.all([
      analyticsStore.clear(false),
      mainStore.clearState(),
      repositoriesStore.setCurrentRepositoryId(),
    ])
  }
  handleCancelDelete()
}
</script>

<style>
.modal-repositories {
  @apply min-h-80;
  @apply !pt-4 !pb-5 !px-2;

  &__title {
    @apply flex items-center;
    @apply gap-4;
  }

  &__search {
    @apply flex items-center;
    @apply gap-1 mx-2 mb-2;
  }

  &__search-input {
    @apply flex-auto;
  }

  &__items {
    @apply flex flex-col flex-auto;
    @apply gap-2 py-2 px-4 -mb-4 -mx-2;
    @apply overflow-y-auto;
  }

  &__no-data {
    @apply flex-auto;
  }
}
</style>
