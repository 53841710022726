<template>
  <UIModal
    v-bind="{ isOpen }"
    :type="ModalType.DIALOG"
    title="Assign tag"
    @hide="handleHide"
  >
    <TagsListDropdown v-model="selectedTag" :disabled="loading" />
    <div class="flex items-center justify-end mt-4 space-x-2">
      <UIButtonClose @click="handleHide" />
      <UIButtonSave
        label="Add"
        v-bind="{ loading }"
        :disabled="!selectedTag"
        @click="handleClickAdd"
      />
    </div>
  </UIModal>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import {
  LinkedDataTransaction,
  LinkedDataTransactionUpdate,
  ModalType,
} from '@types'

import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'

import { UIModal } from '@ui/modals'
import { UIButtonClose, UIButtonSave } from '@ui/buttons'

import TagsListDropdown from './TagsListDropdown.vue'

type Props = {
  items: LinkedDataTransaction[]
}

type Emits = {
  process: [data: LinkedDataTransactionUpdate[], callback: () => void]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const exposeObj = {
  init(value: string[]) {
    selectedTag.value = undefined
    selectedItems.value = value
    return exposeObj
  },
  toggle(flag: boolean) {
    isOpen.value = flag
    return exposeObj
  },
}

defineExpose(exposeObj)

const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()

const isOpen = ref(false)
const loading = ref(false)

const selectedTag = ref<string>()
const selectedItems = ref<string[]>()

const handleHide = () => {
  isOpen.value = false
}

const handleClickAdd = async () => {
  loading.value = true
  const tag = linkedDataTransactionsTagsStore.getList.find(
    item => item.id === selectedTag.value,
  )
  if (!tag) return
  const result = selectedItems.value?.reduce((acc, id) => {
    const transaction = props.items.find(item => item.id === id)
    const existingTags = transaction?.tags || []
    if (
      selectedTag.value &&
      existingTags?.every(tag => tag.id !== selectedTag.value)
    ) {
      const tags = [...existingTags, tag].map(item => `${item.id}`)
      acc.push({ id, tags })
    }
    return acc
  }, [] as LinkedDataTransactionUpdate[])
  if (!result) {
    loading.value = false
    return
  }
  emit('process', result, () => {
    loading.value = false
  })
  handleHide()
}
</script>

<script lang="ts">
export default {
  name: 'ListTagAssign',
}
</script>

<style lang="postcss"></style>
