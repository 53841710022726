<template>
  <UIInputText
    v-model="modelValue"
    :data-refid="dataRefid"
    :label
    :disabled
    :error
    :silent
    :size
    autocomplete="off"
    @ready="onReady"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { ContactClass } from '../'
import { InputSize } from '@types'

import { CONTACT_FIELD } from '../utils/const'

import { UIInputText } from '@ui/fields'

type Props = {
  instance: ContactClass
  disabled?: boolean
  focusOnLoad?: boolean
  label?: string
  dataRefid?: string
  size?: InputSize
}

const props = defineProps<Props>()

const modelValue = props.instance.field<string>(CONTACT_FIELD.EMAIL)

const isSavedContact = inject('isSavedContact', ref(false))

const error = computed(() => props.instance.errors.email)
const silent = computed(() => !isSavedContact.value)

const onReady = (el: HTMLInputElement) => {
  if (!props.focusOnLoad) return
  el.focus()
}
</script>
