import { TagClass } from '@/entities/tags'
import { TAG_FIELD } from '@/entities/tags/utils/const'

export const prepareTagsList = (data: Map<string, TagClass>) => {
  const array = Array.from<TagClass>(data.values())
  const list = array.map(
    instance => instance.field<string>(TAG_FIELD.TAG_VALUE).value,
  )
  return list
}
