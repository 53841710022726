<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog v-bind="{ zIndex, title }" size="md" @hide="handleClose">
      <AssetForm
        v-bind="{ instance: modal.entity, toggledEOD: true }"
        @close="handleClose"
      />
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

import { AssetClass } from '..'
import { ModalClass, ModalState } from '@types'

import { ASSET_MODAL_TITLE, ASSET_MODAL_TITLE_NEW } from './utils/const'

import { UIDialog } from '@ui/modals'
import AssetForm from './AssetForm.vue'

type Props = {
  modal: ModalClass<AssetClass>
  restrictions?: string[]
}
const props = defineProps<Props>()

const title = computed(() =>
  props.modal.entity.isNew ? ASSET_MODAL_TITLE_NEW : ASSET_MODAL_TITLE,
)

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const zIndex = computed(() => props.modal.zIndex)

provide('restrictions', props.restrictions)

const handleClose = () => {
  props.modal.conditionalClose()
}
</script>

<script lang="ts">
export default {
  name: 'AssetDialog',
}
</script>

<style scoped>
@import url('./styles/asset.css');
</style>
