<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog title="New repository" size="md" :z-index @hide="handleClose">
      <RepositoryDataForm
        v-model="repository"
        :loading
        @close="handleClose"
        @save="handleCreate"
      >
        <UICheckbox
          v-model="initDefaultTags"
          :disabled="loading"
          label="Create default Asset Class and Investment Strategy tags"
          class="repository-form__checkbox"
        />
      </RepositoryDataForm>
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { ModalClass, ModalState, Repository } from '@types'

import { NOTIFICATION_DELAY, SET_CURRENT_REPOSITORY } from '@/const'

import { useHotKeys } from '@/plugins/hotKeys'

import { useRepositoriesStore } from '@/store/repositories'

import { handleCatchedError } from '@/helpers/common'
import { NotificationInstance, useNotifications } from '@/plugins/notification'

import RepositoryDataForm from './components/DataForm.vue'
import { TransitionRoot } from '@headlessui/vue'
import { UIDialog } from '@ui/modals'
import { UICheckbox } from '@ui/controllers'

type Props = {
  modal: ModalClass<any>
}

type Emits = {
  hide: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const repositoriesStore = useRepositoriesStore()

const { progress, remove, update } = useNotifications()

const setCurrentRepository = inject<(id?: string) => void>(
  SET_CURRENT_REPOSITORY,
)

const repository = ref<Repository>()
const initDefaultTags = ref(true)
const loading = ref(false)

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const handleClose = () => {
  props.modal.conditionalClose()
}

const handleCreate = async (values: Partial<Repository>) => {
  let nid: NotificationInstance | undefined
  loading.value = true
  try {
    nid = await progress({
      message: 'Creating repository',
    })
    if (
      repositoriesStore.list.find(
        repo => repo.name.toLowerCase() === values.name?.toLowerCase(),
      )
    ) {
      await update(nid, {
        type: 'error',
        message:
          'This name of the repository already exists. Try something else',
      })
      loading.value = false
      return
    }
    const createdRepo = await repositoriesStore.createRepository({
      ...values,
      settings: {
        init_default_tags: initDefaultTags.value,
      },
    } as Repository)
    nid &&
      (await update(
        nid,
        {
          type: 'success',
          message: `Repository ${createdRepo?.name} created`,
          actions: [
            {
              label: 'Go To Repo',
              attrs: {
                variant: 'secondary',
                'data-refid': 'repositoryFormGoToRepo',
              },
              onClick: remove => {
                remove && remove()
                createdRepo && setCurrentRepository?.(createdRepo.id)
                emit('hide')
              },
            },
            {
              label: 'Close',
              attrs: {
                variant: 'gray',
                fill: 'outlined',
              },
              onClick: remove => {
                remove && remove()
              },
            },
          ],
        },
        NOTIFICATION_DELAY,
      ))
    handleClose()
    await repositoriesStore.fetchRepositories()
  } catch (e) {
    nid && (await remove(nid))
    handleCatchedError(e as string, values)
  } finally {
    loading.value = false
  }
}

useHotKeys('escape', handleClose, { flag: isOpen })
</script>
