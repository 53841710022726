<template>
  <div v-if="loading" class="mapping-rules-changes__loading">
    <UIPreloader message="Loading changes..." />
  </div>
  <template v-else-if="isNothingToChange">
    <div class="mapping-rules__no-data">
      <UILayoutNoData inline hide-question-icon>
        <template #text-description>Nothing to change</template>
      </UILayoutNoData>
    </div>
    <div class="mapping-rules__buttons">
      <slot />
    </div>
  </template>
  <template v-else>
    <div
      v-for="(list, date) in scope"
      :key="date"
      class="mapping-rules-changes"
    >
      <div class="mapping-rules-changes__date">{{ date }}</div>
      <Change
        v-for="item in list"
        :key="item.id"
        v-bind="{ actions, item }"
        class="mapping-rules-changes__item"
      />
    </div>
    <div class="mapping-rules__buttons">
      <UIPagination
        v-bind="{ pageNo }"
        :disabled="loading"
        :page-size="pagination?.page_size"
        :total="pagination?.total"
        class="mapping-rules-changes__pagination"
        @click:page="handleClickPage"
      />
      <slot />
    </div>
  </template>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onWatcherCleanup, ref, watch } from 'vue'
import { groupBy, orderBy } from 'lodash'

import { LinkedDataTransaction, PaginatedMeta, TransactionRule } from '@types'

import { PAGE_SIZE } from '@/const'

import { useLinkedDataMappingRulesStore } from '@/store/linkedData/rules'

import Change from './components/Change.vue'
import { UILayoutNoData, UIPreloader, UIPagination } from '@ui/core'

type Props = {
  data?: TransactionRule['data']
}

const props = defineProps<Props>()

const linkedDataMappingRulesStore = useLinkedDataMappingRulesStore()

const transactions = ref<LinkedDataTransaction[]>()
const pagination = ref<PaginatedMeta>()

const loading = ref(false)

const pageNo = ref(0)

const scope = computed(() =>
  groupBy(orderBy(transactions.value, 'date', 'desc'), 'date'),
)

const isNothingToChange = computed(() => !Object.keys(scope.value).length)

const actions = computed(() => props.data?.actions || [])

const fetch = async (signal?: AbortSignal) => {
  if (!props.data) return
  loading.value = true
  const { data, meta } = await linkedDataMappingRulesStore.fetchTransactions(
    props.data,
    pageNo.value,
    PAGE_SIZE,
    signal,
  )
  transactions.value = data
  pagination.value = meta
  loading.value = false
}

const handleClickPage = (page: number) => {
  pageNo.value = page
}

watch(pageNo, () => {
  const controller = new AbortController()
  fetch(controller.signal)
  onWatcherCleanup(() => {
    controller.abort()
  })
})

onBeforeMount(fetch)

defineOptions({
  name: 'LinkedDataRulesChanges',
})
</script>

<style lang="postcss">
.mapping-rules-changes {
  @apply mb-2;

  &__loading {
    @apply h-[15rem];
    @apply flex items-center justify-center;
  }

  &__date {
    @apply sticky top-0;
    @apply mb-2;
    @apply py-2 px-4 md:px-6;
    @apply bg-gray-100 dark:bg-gray-800;
    @apply text-xs;
  }

  &__item,
  &__date {
    @apply py-2 px-4 md:px-6;
  }

  &__item {
    @apply border-b border-gray-100;
    @apply last:border-none;
  }

  &__pagination {
    @apply flex-auto;
  }
}
</style>
