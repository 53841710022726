<template>
  <form class="repository-data-form" @submit.prevent="onSubmit">
    <UIInputText
      v-model="nameValue"
      :error="nameError"
      label="Name"
      data-refid="repositoryFormName"
      :disabled
      :readonly
      @ready="onReady"
    />
    <UIInputAutocomplete
      v-model="currencyValue"
      :error="currencyError"
      label="Currency"
      data-refid="repositoryFormCurrency"
      :disabled
      :data="currencies"
      :readonly
    />
    <slot />
    <div class="repository-data-form__buttons">
      <slot name="buttons" />
      <UIButtonClose label="Close" @click="handleClose" />
      <UIButtonSave
        v-if="isSaveButtonVisible"
        :disabled="isSaveButtonDisabled"
        :loading
        data-refid="repositoryFormSave"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'
import { useField, useForm } from 'vee-validate'
import { omit, orderBy } from 'lodash'

import { ReadonlyMode, Repository } from '@types'

import { READONLY_MODE } from '@/const'
import { ACCESS_TYPE_OWNER } from '@/const/repositories'

import { rules } from '@/helpers/validate'

import { useRepositoriesStore } from '@/store/repositories'

import { UIButtonClose, UIButtonSave } from '@ui/buttons'
import { UIInputAutocomplete, UIInputText } from '@ui/fields'

type Props = {
  loading?: boolean
}

type Emits = {
  close: []
  save: [Partial<Repository>]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<Repository>()

const repositoriesStore = useRepositoriesStore()

const { meta, handleSubmit, resetForm } = useForm({
  initialValues: {
    name: modelValue.value?.name || '',
    currency: modelValue.value?.currency || 'USD',
    price_source_precedence:
      modelValue.value?.settings?.price_source_precedence,
  },
  validationSchema: {
    name: rules.required,
    currency: rules.required,
    price_source_precedence: true,
  },
})

defineExpose({
  resetForm(values: any) {
    resetForm({ values })
  },
  updatePriceSourcePrecedence(values: string[]) {
    priceSourcePrecedenceValue.value = values
  },
})

const { value: nameValue, errorMessage: nameError } = useField<string>('name')
const { value: currencyValue, errorMessage: currencyError } =
  useField<string>('currency')
const { value: priceSourcePrecedenceValue } = useField<string[]>(
  'price_source_precedence',
)

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const currencies = computed(() =>
  orderBy(repositoriesStore.getCurrenciesList, 'name').map(item => ({
    value: item.ticker,
    label: item.name,
  })),
)

const isOwner = computed(
  () =>
    !modelValue.value?.user_repo_settings ||
    modelValue.value?.user_repo_settings?.access_type === ACCESS_TYPE_OWNER,
)

const disabled = computed(() => props.loading || !isOwner.value)

const isSaveButtonVisible = computed(() => !readonly?.value)

const isSaveButtonDisabled = computed(
  () => disabled.value || !(meta.value.dirty && meta.value.valid),
)

const onReady = (el: HTMLInputElement) => {
  if (nameValue.value) return
  el.focus()
}

const onSubmit = handleSubmit(values => {
  const data = {
    ...modelValue.value,
    ...omit(values, 'price_source_precedence'),
    ...(values.price_source_precedence
      ? {
          settings: {
            ...modelValue.value?.settings,
            price_source_precedence: values.price_source_precedence,
          },
        }
      : {}),
  }
  emit('save', data)
})

const handleClose = () => {
  emit('close')
}
</script>

<style>
.repository-data-form {
  @apply flex flex-col gap-4;

  &__buttons {
    @apply flex justify-end items-center;
    @apply gap-2;
  }
}
</style>
