<template>
  <div class="asset-prices-form">
    <div class="asset-prices-form__input">
      <UIInputDate
        v-model="formData.date"
        v-bind="{
          disabled,
          placeholder: 'Date',
        }"
        :size
      />
    </div>
    <div class="asset-prices-form__input">
      <UIInputNumber
        v-model="formData.close"
        data-refid="assetPricesFormClose"
        :disabled
        :placeholder
        :size
        @click:enter="handleSubmit"
        @ready="onReady"
      />
    </div>
    <UIButton
      data-refid="assetPricesFormAdd"
      v-bind="buttonProps"
      label="Price"
      :size
      class="asset-prices-form__icon-button"
      @click="handleSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'

import { ButtonSize } from '@types'
import { AssetPriceForm } from './utils/types'

import { getCurrentDate } from '@/helpers/dates'

import { PlusIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui/buttons'
import { UIInputDate, UIInputNumber } from '@ui/fields'

type Props = {
  disabled?: boolean
  placeholder?: string
  label?: string
  defaultDate?: string
  size?: ButtonSize
}

type Emits = {
  submit: [data: AssetPriceForm]
}

const {
  placeholder = 'Close',
  disabled,
  label,
  defaultDate = getCurrentDate({}),
  size = 'sm',
} = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  getCloseValue: () => {
    return formData.close
  },
})

const closeFieldRef = ref<HTMLInputElement>()

const formData = reactive<AssetPriceForm>({
  date: defaultDate,
  close: null,
})

const isButtonDisabled = computed(
  () => disabled || !formData.date || formData.close === null,
)

const buttonProps = computed(() => ({
  disabled: isButtonDisabled.value,
  leading: label ? undefined : PlusIcon,
  label,
}))

const handleSubmit = () => {
  emit('submit', formData)
  setTimeout(() => {
    formData.close = null
    closeFieldRef.value?.focus()
  }, 100)
}

const onReady = (e: any) => {
  closeFieldRef.value = e?.$el
  closeFieldRef.value?.focus()
}
</script>

<style scoped>
.asset-prices-form {
  @apply flex;
  @apply gap-x-2 sm:gap-x-3;

  &__input {
    @apply flex-auto;
  }

  &__icon-button {
    :deep() .ui-button__icon {
      @apply !w-4;
    }
  }
}
</style>
