<template>
  <UITagsList
    v-bind="{ readonly, dataRefid }"
    :model-value="instanceTags"
    :list="tagsList"
    @mousedown.stop
    @mouseup.stop
    @update:model-value="handleUpdateDocumentTags"
    @plus:click="dialogAddTag = true"
    @item:rename="handleRenameTag"
  />
  <UIModal
    v-bind="{
      isOpen: dialogAddTag,
      size: 'sm',
      title: `Add tag to the document ${tagsEditDocumentName}`,
      type: ModalType.DIALOG,
    }"
    @hide="dialogAddTag = false"
  >
    <TagsListDropdown
      v-model="selectedTagId"
      v-bind="{ selected }"
      data-refid="tagsListDropdown"
      @update="handleRenameTag"
    />
    <div class="flex items-center justify-end mt-4 space-x-2">
      <UIButtonClose @click="handleHideAddTagDialog" />
      <UIButtonSave
        label="Add"
        :disabled="!selectedTagId"
        @click="handleClickAddTag"
      />
    </div>
  </UIModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { DocumentClass } from '..'
import { CommonTag, ModalType } from '@types'

import { handleCatchedError } from '@/helpers/common'
import { NOTIFICATION_DELAY } from '@/const/common'
import { DOCUMENT_FIELD } from '../utils/const'

import { useNotifications } from '@/plugins/notification'
import { useDocumentsTagsStore } from '@/store/documents/tags'

import { UIButtonClose, UIButtonSave } from '@ui/buttons'
import { UITagsList } from '@ui/core'
import { UIModal } from '@ui/modals'
import TagsListDropdown from './TagsListDropdown.vue'

type Props = {
  instance: DocumentClass
  readonly?: boolean
  dataRefid?: string
}

const props = defineProps<Props>()

const { progress, remove, update } = useNotifications()
const documentsTagsStore = useDocumentsTagsStore()

const dialogAddTag = ref(false)
const selectedTagId = ref<string>()
const tagsList = computed(() => documentsTagsStore.getList)
const tagsEditDocumentName = computed(
  () => props.instance.field(DOCUMENT_FIELD.NAME).value,
)

const instanceTags = computed(() => {
  const tags = props.instance.field<string[]>(DOCUMENT_FIELD.TAGS).value
  if (!tags) return []
  return tagsList.value.filter(item => tags.includes(item.id as string))
})

const handleUpdateDocumentTags = (newTagsList: CommonTag[]) => {
  props.instance.field(DOCUMENT_FIELD.TAGS).value = newTagsList.map(
    tag => tag.id as string,
  )
}

const handleHideAddTagDialog = () => {
  dialogAddTag.value = false
  selectedTagId.value = undefined
}

const handleRenameTag = async (id: string, name: string) => {
  const nid = await progress({
    message: 'Updating tag',
  })
  try {
    const result = await documentsTagsStore.update({
      id,
      name,
    })
    if (nid) {
      await update(
        nid,
        {
          type: 'success',
          message: `Tag ${result?.name} updated`,
        },
        NOTIFICATION_DELAY,
      )
    }
  } catch (e) {
    if (nid) await remove(nid)
    handleCatchedError(e as string, { id, name })
  }
}

const selected = computed({
  get() {
    return props.instance.field<string[]>(DOCUMENT_FIELD.TAGS).value
  },
  set(value) {
    props.instance.field<string[]>(DOCUMENT_FIELD.TAGS).value = [...value]
  },
})

const handleClickAddTag = async () => {
  if (!selectedTagId.value || selected.value.includes(selectedTagId.value))
    return

  selected.value = [...selected.value, selectedTagId.value]
  selectedTagId.value = undefined
}

defineOptions({
  name: 'DocumentFormTags',
})
</script>
