<template>
  <UIModal
    v-bind="{ isOpen }"
    :type="ModalType.DIALOG"
    title="Remove tag"
    @hide="handleHide"
  >
    <UIInputAutocomplete
      v-model="selectedTag"
      :data
      :disabled="loading"
      placeholder="Select tag to remove"
    />
    <div class="flex items-center justify-end mt-4 space-x-2">
      <UIButtonClose @click="handleHide" />
      <UIButtonSave
        label="Remove"
        v-bind="{ loading }"
        :disabled="!selectedTag"
        @click="handleClickRemove"
      />
    </div>
  </UIModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { orderBy } from 'lodash'

import {
  LinkedDataTransaction,
  LinkedDataTransactionUpdate,
  ModalType,
} from '@types'

import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'

import { UIModal } from '@ui/modals'
import { UIButtonClose, UIButtonSave } from '@ui/buttons'
import { UIInputAutocomplete } from '@ui/fields'

type Props = {
  items: LinkedDataTransaction[]
}

type Emits = {
  process: [data: LinkedDataTransactionUpdate[], callback: () => void]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const exposeObj = {
  init(value: string[]) {
    selectedTag.value = undefined
    selectedItems.value = value
    return exposeObj
  },
  toggle(flag: boolean) {
    isOpen.value = flag
    return exposeObj
  },
}

defineExpose(exposeObj)

const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()

const isOpen = ref(false)
const loading = ref(false)

const selectedTag = ref<string>()
const selectedItems = ref<string[]>()

const data = computed(() =>
  orderBy(
    linkedDataTransactionsTagsStore.getList.filter(item => {
      const selectedTransactionsTags: string[] = []
      selectedItems.value?.forEach(id => {
        const transaction = props.items.find(item => item.id === id)
        if (transaction) {
          selectedTransactionsTags.push(
            ...transaction.tags.map(tag => tag.id as string),
          )
        }
      })
      return item.id && selectedTransactionsTags?.includes(item.id)
    }),
    'name',
  ).map(item => ({
    value: item.id?.toString() || '',
    label: item.name,
  })),
)

const handleHide = () => {
  isOpen.value = false
}

const handleClickRemove = async () => {
  loading.value = true
  const result = selectedItems.value?.reduce((acc, id) => {
    const transaction = props.items.find(item => item.id === id)
    const existingTags = transaction?.tags
    if (
      selectedTag.value &&
      existingTags?.some(tag => tag.id === selectedTag.value)
    ) {
      const tags = existingTags
        .filter(tag => tag.id !== selectedTag.value)
        .map(item => `${item.id}`)
      acc.push({ id, tags })
    }
    return acc
  }, [] as LinkedDataTransactionUpdate[])
  if (!result) {
    loading.value = false
    return
  }
  emit('process', result, () => {
    loading.value = false
  })
  handleHide()
}
</script>
