<template>
  <UIInputText
    v-model="modelValue"
    :data-refid="dataRefid"
    :label
    :disabled
    :error
    :silent
    :size
    @ready="onReady"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { DocumentClass } from '..'
import { InputSize } from '@types'

import { DOCUMENT_FIELD } from '../utils/const'

import { UIInputText } from '@ui/fields'

type Props = {
  instance: DocumentClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: InputSize
}

const props = defineProps<Props>()

const modelValue = props.instance.field<string>(DOCUMENT_FIELD.NAME)

const isSavedDocument = inject('isSavedDocument', ref(false))

const error = computed(() => props.instance.errors.name)
const silent = computed(() => !isSavedDocument.value)

const onReady = (el: HTMLInputElement) => {
  if (!props.focusOnLoad) return
  el.focus()
}
</script>
