import { createWebHistory, createRouter } from 'vue-router'
import { cloneDeep } from 'lodash'

import { ROUTE_NAME } from '@/const'

import routes from '@/router/routes'

import { useMainStore } from '@/store/main'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0, behavior: 'smooth' }
  },
})

router.beforeEach((to, from, next) => {
  if (to.path === '/linked-accounts') {
    next({ path: '/connectors', query: to.query })
  } else if (!from.name && to.name === ROUTE_NAME.DASHBOARDS && to.query.code) {
    const route = cloneDeep(to)
    delete route.query.code
    delete route.query.state
    next(route)
  } else {
    next()
  }
})

router.afterEach(() => {
  const mainStore = useMainStore()
  mainStore.setAppInitialized(true)
})

export default router
