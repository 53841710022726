<template>
  <AssetsDeleted v-bind="{ columns, items, modal, plural: 'accounts' }" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { AssetAccountTag, ModalClass } from '@types'

import { ASSET_FIELD, ASSET_FIELD_LABEL } from '@/entities/assets/utils/const'

import { useAssetsStoreV2 } from '@/store/assets/index'
import { useTagsBunchStore } from '@/store/tags/bunch'

import AssetsDeleted from '@/modules/modals/AssetsDeleted.vue'

type Props = {
  modal: ModalClass<any>
}
defineProps<Props>()

const assetsStoreV2 = useAssetsStoreV2()
const tagsBunchStore = useTagsBunchStore()

const columns = computed(() => [
  {
    name: ASSET_FIELD.NAME,
    caption: ASSET_FIELD_LABEL[ASSET_FIELD.NAME],
  },
  // type
  {
    name: ASSET_FIELD.TYPE,
    caption: ASSET_FIELD_LABEL[ASSET_FIELD.TYPE],
  },
  {
    name: AssetAccountTag.CATEGORY,
    caption: ASSET_FIELD_LABEL[AssetAccountTag.CATEGORY],
  },
  {
    name: AssetAccountTag.SUBCATEGORY,
    caption: ASSET_FIELD_LABEL[AssetAccountTag.SUBCATEGORY],
  },
])

const data = computed(() => assetsStoreV2.getAccountsDeleted)

const items = computed(() =>
  data.value.map(item => {
    const tags = Object.fromEntries(
      (item.tags || []).map(
        id => tagsBunchStore.getList.get(id)?.pair || ['', ''],
      ),
    )
    return {
      ...item,
      ...tags,
    }
  }),
)
</script>

<script lang="ts">
export default {
  name: 'AccountsTrashPopup',
}
</script>
