<template>
  <component
    :is="controllerComponent"
    v-model="modelValue"
    :data
    :silent
    :readonly
    v-bind="$attrs"
    placeholder="Find or create new"
    popper-class="transaction-asset-field__popper"
    @click:new="handleCreate"
  >
    <template #item="{ item, itemClasses, onClick }">
      <div class="ui-dropdown-list-item" :class="itemClasses" @click="onClick">
        <div class="asset-dropdown-item">
          <span>{{ item.label }}</span>
          <span
            v-if="item.label !== item.meta?.ticker"
            class="asset-dropdown-item__ticker"
          >
            {{ item.meta?.ticker }}
          </span>
        </div>
      </div>
    </template>
  </component>
  <UIButtonMenu
    v-if="isSummaryButtonVisible"
    type="button"
    variant="gray"
    fill="outlined"
    size="default"
    class="transaction-asset-field__summary-button"
    @click="handleClickMenu"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { DropdownListData, TransactionSettings } from '@types'

import { useAssetsBunchStore } from '@/store/assets/bunch'

import { UIButtonMenu } from '@ui/buttons'
import { UIInputAutocomplete, UIInputDynamicList } from '@ui/fields'

type Props = {
  data: DropdownListData[]
  showSummaryButton?: boolean
  settings?: TransactionSettings['entries'][number]['account']
  readonly?: boolean
  disallowCreate?: boolean
}

type Emits = {
  create: [data: string]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<string>()

const assetsBunchStore = useAssetsBunchStore()

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const controllerComponent = computed(() =>
  props.disallowCreate ? UIInputAutocomplete : UIInputDynamicList,
)

const readonly = computed(() => !!props.settings?.value || props.readonly)
const silent = computed(() => !isSavedTransaction.value)

const isSummaryButtonVisible = computed(
  () => props.showSummaryButton && !!modelValue.value,
)

const handleClickMenu = () => {
  if (!modelValue.value) return
  const asset = assetsBunchStore.getList.get(modelValue.value)
  asset?.openDrawer()
}

const handleCreate = (data: string, callback: () => void) => {
  emit('create', data)
  callback()
}
</script>

<style>
.asset-dropdown-item {
  @apply w-full;
  @apply flex items-center gap-2 justify-between;

  &__ticker {
    @apply text-gray-400;
  }
}
.transaction-asset-field {
  &__popper {
    .v-popper__inner {
      @apply min-w-44;
    }
  }
  &__summary-button {
    @apply px-0;

    .ui-button__icon {
      @apply !w-4;
    }
  }
}
</style>
